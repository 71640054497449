import React from 'react'
import ConfirmationDialog from '../../shared/ConfirmationDialog'

function cancelMove(state, isbillencoordinatesToAddress, coordinatesToAddress, updatePointCallback) {
  const { marker } = state
  const { coordinates } = state.currentPoint.point || null
  const position = coordinates ? { lat: coordinates[1], lng: coordinates[0] } : [0, 0]
  marker.setLatLng(position, { draggable: 'true' })
}

function closeDialogAndMove(state) {
  const { marker, currentPoint, point } = state
  const position = { lat: point.coordinates[1], lng: point.coordinates[0] }
  currentPoint.point = point
  marker.setLatLng(position, { draggable: 'true' })
  if (isIsbilen) {
    isbillencoordinatesToAddress(point, position, updatePointCallback, currentPoint)
  } else {
    coordinatesToAddress(point, position, updatePointCallback, currentPoint)
  }
}

function PointMovingConfirmationModal(props) {
  return (
    <ConfirmationDialog
      open={props.state.dialogOpen}
      close={() => cancelMove(props.state)}
      leave={() =>
        closeDialogAndMove(
          props.state,
          props.isbillencoordinatesToAddress,
          props.coordinatesToAddress,
          props.updatePointCallback
        )
      }
      confirmAndCloseText='Confirm'
      cancelText='Cancel'
      title='Custom address detected!'
      content='The point you are moving has a custom address, do you want to move it?'
    />
  )
}

export default PointMovingConfirmationModal
