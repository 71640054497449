import React from 'react'
import omit from 'lodash/omit'
import snakeCase from 'lodash/snakeCase'
import startCase from 'lodash/startCase'
import TextField from '@material-ui/core/TextField'

import withoutTrailingId from '../support/withoutTrailingId'

const FunTextField = (props) => {
  const { state, handleChange, field, label, children } = props

  const snakeCaseField = snakeCase(field)
  const additionalProps = omit(props, ['state', 'handleChange', 'label', 'field', 'children'])

  const errorText = state.errors[snakeCaseField] || state.errors[withoutTrailingId(snakeCaseField)]
  const value = state[field] || state[field] == 0 ? state[field] : ''

  return (
    <TextField
      id={field}
      label={label !== undefined ? startCase(label) : startCase(field)}
      value={value}
      onChange={(e) => handleChange(e, snakeCaseField)}
      fullWidth
      margin='normal'
      error={!!errorText}
      helperText={errorText && errorText.join ? errorText.join(', ') : errorText}
      {...additionalProps}
    >
      {children}
    </TextField>
  )
}

export default FunTextField
