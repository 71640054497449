import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import SmsTemplatesForm from './SmsTemplatesForm'
import SmsTemplates from './SmsTemplates'

const AdminSmsTemplatesIndex = () => {
  return (
    <div className='content-container'>
      <BrowserRouter>
        <Switch>
          <Route path='/:tenant/admin/sms_templates/:id/edit' component={SmsTemplatesForm} />
          <Route path='/:tenant/admin/sms_templates/new' component={SmsTemplatesForm} />
          <Route path='/:tenant/admin/sms_templates' component={SmsTemplates} />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default AdminSmsTemplatesIndex
