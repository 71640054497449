import React from 'react'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import axios from 'axios'
import More from '@material-ui/icons/More'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

class Transfers extends React.Component {
  state = {
    transfers: [],
    loading: false,
    paginationDisabled: false,
    currentPage: 1
  }

  componentDidMount() {
    this.loadTransfers()
  }

  loadTransfers() {
    this.startLoading()
    axios.get(this.transferUrl()).then((res) => {
      this.setState({
        transfers: res.data,
        loading: false,
        paginationDisabled: false
      })
    })
  }

  transferUrl() {
    return Routes.inventory_warehouse_stock_transfers_path(window.tenant, window.depoId, this.props.warehouseId, {
      format: 'json',
      page: this.state.currentPage
    })
  }

  startLoading() {
    this.setState({ loading: true })
  }

  stopLoading() {
    this.setState({ loading: false })
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading} />
  }

  renderTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell>From</TableCell>
          <TableCell>To</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Kind</TableCell>
          <TableCell>Transfered at</TableCell>
          <TableCell>Created by</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
    )
  }

  renderTablePagination() {
    const { paginationDisabled, currentPage } = this.state
    return (
      <div className='pagination float-right'>
        <strong>Page: {currentPage}</strong>
        &nbsp;
        <IconButton onClick={this.changePage.bind(this, -1)} disabled={paginationDisabled}>
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton onClick={this.changePage.bind(this, 1)} disabled={paginationDisabled}>
          <KeyboardArrowRight />
        </IconButton>
      </div>
    )
  }

  changePage(pageIncrement) {
    const { transfers, currentPage } = this.state
    if (transfers.length < 25 && pageIncrement == 1) return

    const newPage = Math.min(Math.max(1, currentPage + pageIncrement))
    if (newPage === currentPage) return
    this.setState({ currentPage: newPage, paginationDisabled: true }, this.loadTransfers)
  }

  renderTableBody() {
    return (
      <TableBody>
        {this.state.transfers.map((transfer) => {
          return (
            <TableRow key={transfer.id}>
              <TableCell>{transfer.from_warehouse}</TableCell>
              <TableCell>{transfer.to_warehouse}</TableCell>
              <TableCell>{transfer.description}</TableCell>
              <TableCell>{transfer.kind}</TableCell>
              <TableCell>{transfer.transfered_at}</TableCell>
              <TableCell>{transfer.created_by}</TableCell>
              <TableCell>
                <Link
                  to={Routes.show_inventory_warehouse_stock_transfers_path(
                    window.tenant,
                    window.depoId,
                    this.props.warehouseId,
                    transfer.id
                  )}
                >
                  <IconButton>
                    <More />
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    )
  }

  render() {
    return (
      <div>
        {this.renderTablePagination()}
        <Table>
          {this.renderTableHead()}
          {this.renderTableBody()}
        </Table>
        {this.renderLoading()}
      </div>
    )
  }
}

export default Transfers
