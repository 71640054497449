import React from 'react'
import IconButton from '@material-ui/core/IconButton'

const handleClick = (handleParentClick, message) => {
  if (!confirm(message)) return
  handleParentClick()
}

const ConfirmButton = (props) => {
  const { style, handleParentClick, message, classes, children, disabled } = props

  return (
    <IconButton
      style={style}
      disabled={disabled}
      className={classes}
      onClick={() => handleClick(handleParentClick, message)}
    >
      {children}
    </IconButton>
  )
}

export default ConfirmButton
