import React from 'react'
import SaveButton from '../../shared/SaveButton'

const Cell = ({ children }) => <div className='mdl-cell mdl-cell--2-col'>{children}</div>
const CellStrong = ({ children }) => (
  <Cell>
    <strong>{children}</strong>
  </Cell>
)
const GridData = ({ children }) => <div className='mdl-grid data'>{children}</div>

class OrderLinePaper extends React.Component {
  renderMainTitleRow(title, totalQuantity, totalDiscount, totalAmount) {
    return (
      <div className='mdl-grid main-title'>
        <div className='mdl-cell mdl-cell--4-col'>
          <h6>{title}</h6>
        </div>
        <div className='mdl-cell mdl-cell--2-col amount'>{totalQuantity}</div>
        <div className='mdl-cell mdl-cell--2-col amount'>{totalDiscount}</div>
        <div className='mdl-cell mdl-cell--2-col amount'>{totalAmount}</div>
      </div>
    )
  }

  renderDataHeadRow(first, second, third, fourth, fifth) {
    return (
      <div className='mdl-grid data-head'>
        <CellStrong>{first}</CellStrong>
        <CellStrong>{second}</CellStrong>
        <CellStrong>{third}</CellStrong>
        <CellStrong>{fourth}</CellStrong>
        <CellStrong>{fifth}</CellStrong>
      </div>
    )
  }

  renderDataRow({ category_name, product_name, quantity, discount, total_line_amount }, index) {
    return (
      <GridData key={index}>
        <Cell>{category_name}</Cell>
        <Cell>{product_name}</Cell>
        <Cell>{quantity}</Cell>
        <Cell>{discount}</Cell>
        <Cell>{total_line_amount}</Cell>
      </GridData>
    )
  }

  renderOrderLineItems(order_line_items) {
    return order_line_items.map((orderLineItem, index) => {
      return this.renderDataRow(orderLineItem, index)
    })
  }

  render() {
    const { order } = this.props
    return (
      <div className='content-containser end-of-day-report-container'>
        {this.renderDataHeadRow(
          'Product category',
          'Product name',
          'Quantity',
          `Discount (${window.currency})`,
          `Amount (${window.currency})`
        )}
        {this.renderOrderLineItems(order.order_line_items)}
        {this.renderMainTitleRow('Totals', order.total_quantity, order.total_discount, order.total_revenue)}
      </div>
    )
  }
}

export default OrderLinePaper
