import React, { useState, useRef, useEffect } from 'react'
import Modal from '../../shared/Modal'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import HomePointModalTableRow from './HomePointModalTableRow'
import axios from 'axios'
import HomePointDeleteConfirmationModal from './HomePointDeleteConfirmationModal'
import { Checkbox } from '@material-ui/core'

const handleCheckboxCheck = (checked, point, customer_id, setCheckedCustomers) => {
  if (checked.find((element) => element.customer_id == customer_id)) {
    setCheckedCustomers(checked.filter((value) => value.customer_id != customer_id))
  } else setCheckedCustomers([...checked, { point, customer_id }])
}

const handleTrashClick = (setModalOpen) => {
  setModalOpen(true)
}

const handleCheckAll = (setCheckedCustomers, points) => {
  const allHomeCustomers = []
  points.forEach((point) => {
    point.home_customers.forEach((customer) =>{
      allHomeCustomers.push({point: point, customer_id: customer.id})
    })})
  setCheckedCustomers(allHomeCustomers)
}

const handleDelete = (checked, fetchCustomers, setCheckedCustomers, setModalOpen, props) => {
  checked.forEach((value) => {
    const allCustomerIDs = value.point.home_customers.map((e) => {
      return e.id
    })
    const selectedCustomerIDs = checked.map((e) => {
      return e.customer_id
    })
    const undeletedCustomerIDs = allCustomerIDs.filter((x) => !selectedCustomerIDs.includes(x))

    if (value.point.home_customers.length <= 1 || undeletedCustomerIDs.length == 0) {
      axios
        .delete(
          Routes.remove_home_point_route_point_path(window.tenant, window.depoId, props.routeId, value.point.id, {
            format: 'json'
          })
        )
        .then((res) => {
          fetchCustomers()
          props.updateData()
          setModalOpen(false)
          setCheckedCustomers([])
        }).catch((err) => alert("Point not deleted"))
    } else {
      axios
        .delete(
          Routes.remove_home_customer_route_point_path(window.tenant, window.depoId, props.routeId, value.point.id, {
            attached_home_customer_ids: undeletedCustomerIDs,
            format: 'json'
          })
        )
        .then((res) => {
          fetchCustomers()
          props.updateData()
          setModalOpen(false)
          setCheckedCustomers([])
        }).catch((err) => alert("Point not deleted"))
    }
  })
}

function TableData(props) {
  return props.data.map((point) => {
    if (point.home_customers.length > 0) {
      return (
        <React.Fragment key={`row_${point.id}`}>
          <TableRow>
            <TableCell>
              <b>{point.address}</b>
            </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
          <HomePointModalTableRow
            key={`point_${point.id}`}
            checkedCustomers={props.checkedCustomers}
            setCheckedCustomers={props.setCheckedCustomers}
            handleCheckboxCheck={handleCheckboxCheck}
            point={point}
          />
        </React.Fragment>
      )
    }
  })
}

function HomePointModal(props) {
  const [checkedCustomers, setCheckedCustomers] = useState([])
  const [data, setData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [routeId, setRouteId] = useState(props.routeId)

  useEffect(() => {
    if (props.routeId) {
      setRouteId(props.routeId || 0)
      fetchCustomers(props)
    }
  }, [props])

  const fetchCustomers = (props) => {
    axios
      .get(Routes.home_customers_route_path(window.tenant, window.depoId, routeId, { format: 'json' }))
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => alert(err))
  }

  return (
    <div>
      <Modal
        classes='home-point-modal'
        open={props.open}
        close={() => {
          setCheckedCustomers([])
          props.close()
        }}
        title='Home Points'
      >
        <div style={{ height: '53vh', overflow: 'scroll' }}>
          <Table size='small' style={{ position: 'relative' }}>
            <TableHead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
              <TableRow>
                <TableCell>Home Point Adress</TableCell>
                <TableCell>Customers</TableCell>
                <TableCell>
                  {checkedCustomers.length > 0 ? (
                    <IconButton size='small' onClick={() => handleTrashClick(setModalOpen)}>
                      <DeleteIcon />
                    </IconButton>
                  ) : (
                    <IconButton size='small' onClick={() => handleCheckAll(setCheckedCustomers, data)}>
                      <Checkbox/>
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableData data={data} setCheckedCustomers={setCheckedCustomers} checkedCustomers={checkedCustomers} />
            </TableBody>
          </Table>
        </div>
      </Modal>
      <HomePointDeleteConfirmationModal
        open={modalOpen}
        close={() => {
          setModalOpen(false)
        }}
        handleDelete={() => {
          handleDelete(checkedCustomers, fetchCustomers, setCheckedCustomers, setModalOpen, props)
        }}
        userCount={checkedCustomers.length}
      />
    </div>
  )
}

export default HomePointModal
