import React from 'react'
import Grid from '@material-ui/core/Grid'

const GridWrapper = ({ children }) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={8} md={6} lg={4}>
        {children}
      </Grid>
    </Grid>
  )
}

export default GridWrapper
