import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import ModeEdit from '@material-ui/icons/Edit'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import DeleteIcon from '@material-ui/icons/Delete'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import {
  startLoading,
  stopLoading,
  deleteResource,
  updateResource,
  copyResource,
  placeRoutePointsOnMap
} from '../../files/actions/index'
import ConfirmButton from '../shared/ConfirmButton'
import RouteCopyDialog from '../shared/RouteCopyDialog'
import HomeIcon from '@material-ui/icons/Home'

const styles = {
  editButton: {
    float: 'right',
    position: 'absolute',
    marginTop: 6,
    right: 10
  },
  listStyle: {
    display: 'inline-block',
    width: '100%'
  },
  copyButton: {
    float: 'right',
    color: 'black',
    position: 'absolute',
    right: 70,
    marginTop: 6,
    width: 32,
    height: 32
  },
  actionButton: {
    float: 'right',
    position: 'absolute',
    color: 'black',
    right: 40,
    marginTop: 6,
    width: 32,
    height: 32
  },
  homeIcon: {
    float: 'right',
    color: 'black',
    position: 'absolute',
    right: 110,
    marginTop: 6,
    width: 32,
    height: 32
  },
  rowStyle: {
    display: 'flex'
  }
}

class RoutesRowClass extends React.Component {
  state = { checked: false, routeCopyDialogOpen: false }

  componentWillUnmount() {
    this.props.placeRoutePointsOnMap([], this.props.route.id)
  }

  deleteRoute(e, route) {
    this.props.startLoading()
    axios.delete(window.Routes.route_path(window.tenant, window.depoId, route.id)).then((res) => {
      this.props.deleteRoute(res.data)
      this.props.stopLoading()
    })
  }

  undoDeleteRoute(e, route) {
    this.props.startLoading()
    axios.patch(window.Routes.undo_delete_route_path(window.tenant, window.depoId, route.id)).then((res) => {
      this.props.updateRoute(res.data)
      this.props.stopLoading()
    })
  }

  copyRoute(route, depo_id) {
    this.props.startLoading()
    axios
      .post(
        window.Routes.copy_route_route_path(window.tenant, window.depoId, route.id, {
          format: 'json',
          new_depo_id: depo_id
        })
      )
      .then((res) => {
        if (window.depoId == depo_id) this.props.copyRoute(res.data, route)
        this.toggleRouteCopyDialog()
        this.props.stopLoading()
      })
  }

  reverseRoute(depo_id, e) {
    this.props.startLoading()
    axios
      .post(
        window.Routes.copy_and_reverse_route_route_path(window.tenant, window.depoId, this.props.route.id, {
          format: 'json',
          new_depo_id: depo_id
        })
      )
      .then((res) => {
        if (window.depoId == depo_id) this.props.copyRoute(res.data, this.props.route)
        this.toggleRouteCopyDialog()
        this.props.stopLoading()
      })
  }

  fetchNeededPoints() {
    const { route } = this.props
    axios
      .get(
        Routes.route_points_path(window.tenant, window.depoId, route.id, {
          format: 'json'
        })
      )
      .then((res) => {
        this.props.placeRoutePointsOnMap(res.data, route.id)
      })
  }

  handeCheckboxChange() {
    this.setState({ checked: !this.state.checked })
    if (this.state.checked == false) {
      this.fetchNeededPoints()
    } else {
      this.props.placeRoutePointsOnMap([], this.props.route.id)
    }
  }

  toggleRouteCopyDialog() {
    this.setState({ routeCopyDialogOpen: !this.state.routeCopyDialogOpen })
  }

  renderCopyButton() {
    return (
      <IconButton
        className={`mdl-button mdl-js-button mdl-button--icon ${this.props.classes.copyButton} route-copy-button`}
        onClick={this.toggleRouteCopyDialog.bind(this)}
      >
        <i className='material-icons'>content_copy</i>
      </IconButton>
    )
  }

  renderMakeActive(route) {
    return (
      <ConfirmButton
        classes={`mdl-button mdl-js-button mdl-button--icon ${this.props.classes.actionButton}`}
        handleParentClick={this.undoDeleteRoute.bind(this, null, route)}
        message='Are you sure you want to activate this route?'
      >
        <i className='material-icons'>undo</i>
      </ConfirmButton>
    )
  }

  renderDelete(route) {
    return (
      <ConfirmButton
        disabled={!route.is_deactivatable}
        classes={`mdl-button mdl-js-button mdl-button--icon ${this.props.classes.actionButton}`}
        handleParentClick={this.deleteRoute.bind(this, null, route)}
        message='Are you sure you want to deactivate this route?'
      >
        <DeleteIcon />
      </ConfirmButton>
    )
  }

  routeStatusStyle(route) {
    if (route.approval_status == 'approved') {
      return route.invalid ? 'route-partially-approved' : 'route-approved'
    }
    if (route.invalid) {
      return 'route-denied'
    }
    if (route.edited_after_approval) {
      return 'route-updated-after-approval'
    }
  }

  renderRouteCopyDialog(route) {
    const { routeCopyDialogOpen } = this.state
    return (
      <RouteCopyDialog
        open={routeCopyDialogOpen}
        close={this.toggleRouteCopyDialog.bind(this)}
        copyRoute={this.copyRoute.bind(this, this.props.route)}
        reverseRoute={this.reverseRoute.bind(this)}
        depos={this.props.depos}
        route={this.props.route}
      />
    )
  }

  renderHomeReceiversInRoute() {
    const { route } = this.props

    if (route.home_receivers_in_routes > 0) {
      return (
        <span className={`tag-label customer-home-label ${this.props.classes.homeIcon}`}>
          <b className='number'>{route.home_receivers_in_routes}</b>
          <HomeIcon />
        </span>
      )
    }
  }

  render() {
    const { route, filter } = this.props
    const { checked } = this.state

    return (
      <div>
        <div id={`route-${route.id}`} className={`${this.routeStatusStyle(route)} route-row`} style={styles.rowStyle}>
          <Checkbox className='route-row-checkbox' checked={checked} onChange={this.handeCheckboxChange.bind(this)} />
          <Link
            className={`mdl-navigation__link ${this.props.classes.listStyle}`}
            to={{
              pathname: window.Routes.route_points_path(window.tenant, window.depoId, route.id),
              state: { filter: filter }
            }}
          >
            <ListItem className='route-name-heading'>
              <ListItemText primary={route.name} />
            </ListItem>
          </Link>
          <Link
            className={`mdl-button mdl-js-button mdl-button--icon ${this.props.classes.editButton}`}
            to={window.Routes.edit_routes_path(window.tenant, window.depoId, route.id)}
          >
            <ModeEdit />
          </Link>
          {route.deleted_at ? this.renderMakeActive(route) : this.renderDelete(route)}
          {route.deleted_at ? null : this.renderCopyButton()}
          {this.renderRouteCopyDialog()}
          {this.renderHomeReceiversInRoute()}
        </div>
        <Divider />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    routes: state.resources
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    deleteRoute: (route) => dispatch(deleteResource(route)),
    updateRoute: (route) => dispatch(updateResource(route)),
    copyRoute: (route, copiedRoute) => dispatch(copyResource(route, copiedRoute)),
    placeRoutePointsOnMap: (points, routeId) => dispatch(placeRoutePointsOnMap(points, routeId))
  }
}

const RoutesRow = withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(RoutesRowClass))

export default RoutesRow
