import React from 'react'
import { Marker } from 'react-map-gl'
import RoutePointIcon from './../icons/RoutePointIcon.js'
import cloneDeep from 'lodash/cloneDeep'

function updatePointFromMap(
  point,
  e,
  roadPoints,
  updatePointCallback,
  isbillencoordinatesToAddress,
  coordinatesToAddress,
  setState
) {
  const clonedPoints = cloneDeep(roadPoints)
  const id = point.position
  const marker = e.target
  const currentPoint = clonedPoints.filter((filteredpoint, index) =>
    filteredpoint.position ? filteredpoint.position === id : index === id - 1
  )
  const originalPoint = cloneDeep(clonedPoints)
  const position = { lat: e.lngLat.lat, lng: e.lngLat.lng }
  const newpoint = {
    type: 'Point',
    coordinates: [position.lng, position.lat]
  }
  if (!currentPoint[0]) {
    currentPoint[0] = point
  }
  if (currentPoint[0].use_custom_address) {
    setState(currentPoint[0], marker, newpoint)
  } else {
    currentPoint[0].point = newpoint
    if (isIsbilen) {
      isbillencoordinatesToAddress(newpoint, position, updatePointCallback, currentPoint[0], originalPoint)
    } else {
      coordinatesToAddress(newpoint, position, updatePointCallback, currentPoint[0], originalPoint)
    }
  }
}

function RouteMapMarkers(props) {
  const { draggable } = props.state
  const opacity = props.state.customerView ? 0.3 : 1
  return props.roadPoints.map((point, index) => {
    let color = point.hasOwnProperty('classAndColor') ? point.classAndColor.color : point.point.color || 'red'

    if (point.home_customers.length > 0) {
      color = '#097DC7'
    }

    if (point.point != null) {
      return (
        <Marker
          className={'pointMarker'}
          key={index}
          latitude={point.point.coordinates[1]}
          longitude={point.point.coordinates[0]}
          anchor='bottom'
          draggable={draggable}
          onClick={() => {
            props.openMovingModal(point)
          }}
          onDragEnd={(event) =>
            updatePointFromMap(
              point,
              event,
              props.roadPoints,
              props.updatePointCallback,
              props.isbillencoordinatesToAddress,
              props.coordinatesToAddress,
              props.setState
            )
          }
        >
          <RoutePointIcon
            index={index + 1}
            color={color}
            width={40}
            height={40}
            textColor={'white'}
            opacity={opacity}
          ></RoutePointIcon>
        </Marker>
      )
    }
  })
}

export default RouteMapMarkers
