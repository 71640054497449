import React from 'react'
import TextField from '@material-ui/core/TextField'
import TimeField from 'react-simple-timefield'

class FunTimeInput extends React.Component {
  emptyHandler() {
    return
  }

  render() {
    const { id, label, containerStyles, settings, inputStyle, ...other } = this.props
    const { onChange, inputProps, ...otherSettings } = settings

    return (
      <TimeField
        style={containerStyles}
        onChange={onChange || this.emptyHandler}
        {...otherSettings}
        {...other}
        input={<TextField id={id} label={label} {...inputProps} inputProps={{ style: inputStyle }} />}
        colon=':'
        showSeconds
      />
    )
  }
}

export default FunTimeInput
