import React from 'react'

import DeposComponent from './DeposComponent'
import TitleContainer from '../../shared/TitleContainer'

const AdminDeposIndex = (props) => {
  return (
    <TitleContainer>
      <DeposComponent access_token={props.access_token} />
    </TitleContainer>
  )
}

export default AdminDeposIndex
