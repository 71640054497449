import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { withStyles } from '@material-ui/core/styles'

const styles = {}

const SmsCampaignsTableHead = ({ classes }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>#</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Text</TableCell>
        <TableCell>Include subscribe info</TableCell>
        <TableCell>Start date</TableCell>
        <TableCell>End date</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default withStyles(styles)(SmsCampaignsTableHead)
