import React, { useEffect, useState } from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'

function CheckBox(props) {
  const checkedCustomerId = props.checkedCustomers.map((e) => e.customer_id || null)

  return (
    <Checkbox
      checked={checkedCustomerId.includes(props.customerId)}
      onChange={() => {
        props.handleCheckboxCheck(props.checkedCustomers, props.point, props.customerId, props.setCheckedCustomers)
      }}
    />
  )
}

function HomePointModalTableRow(props) {
  return props.point.home_customers.map((customer) => {
    return (
      <TableRow key={`customer_${customer.id}`}>
        <TableCell></TableCell>
        <TableCell>
          <a href={Routes.edit_admin_customers_path(window.tenant, customer.id)} target='_blank'>
            {customer.phone}
          </a>
        </TableCell>
        <TableCell><CheckBox handleCheckboxCheck={props.handleCheckboxCheck} checkedCustomers={props.checkedCustomers} point={props.point} customerId={customer.id} setCheckedCustomers={props.setCheckedCustomers}/></TableCell>
      </TableRow>
    )
  })
}

export default HomePointModalTableRow
