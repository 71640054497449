import React from 'react'
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import { setMainTitle, stopLoading, setFlash } from '../../../files/actions/index'
import axios from 'axios'
import NewButton from '../../shared/NewButton'
import ModeEdit from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'

class StopTypesTableClass extends React.Component {
  state = { stopTypes: [] }

  componentDidMount() {
    this.props.setMainTitle('Stop types')
    axios.get(Routes.admin_stop_types_path(window.tenant, { format: 'json' })).then((res) => {
      this.setState({ stopTypes: res.data })
      this.props.stopLoading()
    })
  }

  renderStopTypes() {
    const { stopTypes } = this.state

    return stopTypes.map((stopType) => {
      return (
        <TableRow key={stopType.id}>
          <TableCell>{stopType.id}</TableCell>
          <TableCell>{stopType.stop_type}</TableCell>
          <TableCell>{stopType.wait_time}</TableCell>
          <TableCell>
            <Link
              className='mdl-button mdl-js-button mdl-button--icon'
              to={Routes.edit_admin_stop_types_path(window.tenant, stopType.id)}
            >
              <ModeEdit />
            </Link>
          </TableCell>
        </TableRow>
      )
    })
  }

  render() {
    return (
      <div>
        <NewButton to={Routes.new_admin_stop_types_path(window.tenant)} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Stop type</TableCell>
              <TableCell>Wait time</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderStopTypes()}</TableBody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMainTitle: (title) => dispatch(setMainTitle(title)),
    setFlash: (flash) => dispatch(setFlash(flash)),
    stopLoading: () => dispatch(stopLoading())
  }
}

const StopTypesTable = connect(mapStateToProps, mapDispatchToProps)(StopTypesTableClass)

export default StopTypesTable
