import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { setMainTitle, startLoading, stopLoading, loadResources, setFlash } from '../../../files/actions/index'

import SmsCampaignsTableHead from './SmsCampaignsTableHead'
import SmsCampaignsTableRow from './SmsCampaignsTableRow'
import NewButton from '../../shared/NewButton'

class SmsCampaignsTableClass extends React.Component {
  state = {}

  componentDidMount() {
    this.props.setMainTitle('SMS Campaigns')
    this.loadPage()
  }

  loadPage() {
    this.props.startLoading()

    axios
      .get(this.url())
      .then((res) => {
        this.props.loadSmsCampaigns(res.data)
      })
      .catch((error) => {
        this.props.setFlash(`${error} (${this.url()})`)
      })
      .then(this.props.stopLoading())
  }

  url() {
    return Routes.admin_sms_campaigns_path(window.tenant, { format: 'json' })
  }

  renderTableBody() {
    return (
      <TableBody>
        {this.props.smsCampaigns.map((smsCampaign, index) => {
          return <SmsCampaignsTableRow smsCampaign={smsCampaign} key={index} />
        })}
      </TableBody>
    )
  }

  render() {
    return (
      <div>
        <NewButton id='new-sms-campaign' to={Routes.new_admin_sms_campaigns_path(window.tenant)} />
        <Table className='resources-table'>
          <SmsCampaignsTableHead />
          {this.renderTableBody()}
        </Table>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    smsCampaigns: state.resources
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMainTitle: (title) => dispatch(setMainTitle(title)),
    setFlash: (flash) => dispatch(setFlash(flash)),
    loadSmsCampaigns: (smsCampaigns) => dispatch(loadResources(smsCampaigns)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const SmsCampaignsTable = connect(mapStateToProps, mapDispatchToProps)(SmsCampaignsTableClass)

export default SmsCampaignsTable
