const setGA = () => {
  if (typeof window == 'undefined') return

  const metaTag = document.querySelector('meta[name=ga_id]')
  if (!metaTag) return

  const gaId = metaTag.getAttribute('content')

  window.dataLayer = window.dataLayer || []
  function gtag() {
    dataLayer.push(arguments)
  }

  window.gtag = gtag

  gtag('js', new Date())
  gtag('config', gaId)
}

export default setGA
