import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import WarehousesForm from './WarehousesForm'
import WarehousesTable from './WarehousesTable'
import WarehouseShow from './WarehouseShow'

import StockOrderForm from '../stock_orders/StockOrderForm'
import StockOrder from '../stock_orders/StockOrder'

import StockTransferForm from '../stock_transfers/StockTransferForm'
import StockTransfer from '../stock_transfers/StockTransfer'

import ManualCountingDocument from '../manual_countings/ManualCountingDocument'
import ManualCountingDocumentForm from '../manual_countings/ManualCountingDocumentForm'

const WarehousesComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path='/:tenant/inventory/depo/:depoId/warehouses/:warehouseId/manual_counting_documents/:manualCountingDocumentId(\d+)/edit'
          component={ManualCountingDocumentForm}
        />
        <Route
          path='/:tenant/inventory/depo/:depoId/warehouses/:warehouseId/manual_counting_documents/:manualCountingDocumentId(\d+)'
          component={ManualCountingDocument}
        />
        <Route
          path='/:tenant/inventory/depo/:depoId/warehouses/:warehouseId/stock_orders/:stockOrderId(\d+)/edit'
          component={StockOrderForm}
        />
        <Route
          path='/:tenant/inventory/depo/:depoId/warehouses/:warehouseId/stock_orders/:stockOrderId(\d+)'
          component={StockOrder}
        />
        <Route
          path='/:tenant/inventory/depo/:depoId/warehouses/:warehouseId/stock_orders/new'
          component={StockOrderForm}
        />
        <Route
          path='/:tenant/inventory/depo/:depoId/warehouses/:warehouseId/stock_transfers/new'
          component={StockTransferForm}
        />
        <Route
          exact
          path='/:tenant/inventory/depo/:depoId/warehouses/:warehouseId/stock_transfers/:stockOrderId'
          component={StockTransfer}
        />
        <Route exact path='/:tenant/inventory/depo/:depoId/warehouses/:warehouseId/edit' component={WarehousesForm} />
        <Route path='/:tenant/inventory/depo/:depoId/warehouses/:warehouseId/:filter' component={WarehouseShow} />
        <Route path='/:tenant/inventory/depo/:depoId/warehouses/new' component={WarehousesForm} />
        <Route path='/:tenant/inventory/depo/:depoId/warehouses' component={WarehousesTable} />
      </Switch>
    </BrowserRouter>
  )
}

export default WarehousesComponent
