const Car = () => {
  return {
    internal_number: '',
    mobile_number: '',
    plate_number: '',
    axapta_code: '',
    depo_id: '',
    mapon_car_id: '',
    latest_location: '',
    latest_location_updated_at: '',
    last_synced_at: '',
    depos: [],
    status: 'active',
    franchise_car: false,
    send_to_lakrids: false,
    errors: {}
  }
}

export default Car
