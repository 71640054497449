const PlanEvent = () => {
  return {
    id: '',
    route_id: '',
    car_id: '',
    user_id: '',
    date: '',
    converted_at: '',
    converted_by: '',
    errors: {}
  }
}

export default PlanEvent
