import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import DeposForm from './DeposForm'
import DeposTable from './DeposTable'

const DeposComponent = (componentProps) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path='/:tenant/admin/depos/:depoId/edit'
          render={(props) => <DeposForm {...props} access_token={componentProps.access_token} />}
        />
        <Route
          path='/:tenant/admin/depos/new'
          render={(props) => <DeposForm {...props} access_token={componentProps.access_token} />}
        />
        <Route path='/:tenant/admin/depos' component={DeposTable} />
      </Switch>
    </BrowserRouter>
  )
}

export default DeposComponent
