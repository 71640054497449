import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import ModeEdit from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

const styles = {}

const CustomersTableRow = ({ customer }) => {
  return (
    <TableRow hover>
      <TableCell>{customer.id}</TableCell>
      <TableCell>{customer.email}</TableCell>
      <TableCell>{customer.location_address}</TableCell>
      <TableCell>{customer.username}</TableCell>
      <TableCell>{customer.name}</TableCell>
      <TableCell>{customer.first_name}</TableCell>
      <TableCell>{customer.last_name}</TableCell>
      <TableCell>{customer.phone}</TableCell>
      <TableCell>{customer.gender}</TableCell>
      <TableCell>{customer.sms_permission ? 'Yes' : 'No'}</TableCell>
      <TableCell>
        <Link
          className='mdl-button mdl-js-button mdl-button--icon'
          to={Routes.edit_admin_customers_path(tenant, customer.id)}
        >
          <ModeEdit />
        </Link>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(CustomersTableRow)
