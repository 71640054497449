import React from 'react'
import MoveCustomersToPointModal from '../MoveCustomersToPointModal'

function RouteMapMoveCustomersModal(props) {
  const { modalOpen, pointToMove, selectedCustomerRoutes } = props.state

  if (Object.keys(pointToMove).length > 0) {
    return (
      <MoveCustomersToPointModal
        key={pointToMove.id}
        open={modalOpen}
        close={props.closeMovingModal}
        customers={props.props.customers}
        loadCustomers={props.props.loadCustomers}
        selectedPoint={pointToMove}
        selectedCustomers={props.selectedCustomers}
        selectedCustomerRoutes={selectedCustomerRoutes || []}
        routePoints={props.props.roadPoints}
      />
    )
  } else {
    return null
  }
}

export default RouteMapMoveCustomersModal
