import React from "react";
import Modal from '../../shared/Modal'
import Button from '@material-ui/core/Button'

function HomePointDeleteConfirmationModal (props) {

  return(
    <Modal classes="delete-home-p-confirmation" open={props.open} close={() => props.close()} title={`Are you sure you want to remove ${props.userCount} users?`}>
      <p>If a home point is left without any users it will be deleted.</p>
      <Button onClick={() => {props.handleDelete()}}>Delete</Button>
      <Button onClick={() => props.close()}>Cancel</Button>
    </Modal>
  )

}

export default HomePointDeleteConfirmationModal