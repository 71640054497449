import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

import Table from '@material-ui/core/Table'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

import { setMainTitle, startLoading, stopLoading, setFlash } from '../../../files/actions/index'
import BackButton from '../../shared/BackButton'
import OrdersTableHead from './OrdersTableHead'
import OrderLineItemsTableHead from './OrderLineItemsTableHead'
import OrdersTableRow from './OrdersTableRow'
import OrderLineItemsTableRow from './OrderLineItemsTableRow'

class OrdersAndOrderLineItemsTableClass extends React.Component {
  state = {
    paginationDisabled: true,
    orderView: true,
    currentPage: 1,
    pageData: []
  }

  componentDidMount() {
    this.validatesLocationStateAndSetHeader()
    this.loadPage()
  }

  validatesLocationStateAndSetHeader() {
    this.props.location.state
      ? this.setHeader(this.props.location.state.endOfDayReport)
      : this.loadEndOfDayreportAndSetHeader()
  }

  setHeader(endOfDayReport) {
    this.props.setMainTitle(`
      ${endOfDayReport.plan_event_date},
      ${endOfDayReport.route_name},
      ${endOfDayReport.car_internal_number},
      ${endOfDayReport.user_full_name}
    `)
  }

  loadEndOfDayreportAndSetHeader() {
    this.props.startLoading()
    axios
      .get(this.endOfDayReportUrl())
      .then((res) => {
        this.setHeader(res.data)
        this.props.stopLoading()
      })
      .catch((error) => {
        this.props.setFlash(`${error} (${this.endOfDayReportUrl()})`)
        this.stopLoading()
      })
  }

  loadPage() {
    this.props.startLoading()
    axios
      .get(this.ordersAndOrderLineItemsUrl())
      .then((res) => {
        this.setState({ pageData: res.data, paginationDisabled: false })
        this.props.stopLoading()
      })
      .catch((error) => {
        this.props.setFlash(`${error} (${this.ordersAndOrderLineItemsUrl()})`)
        this.setState({ paginationDisabled: false })
        this.props.stopLoading()
      })
  }

  endOfDayReportUrl() {
    return Routes.end_of_day_report_path(window.tenant, window.depoId, this.endOfDayReportId(), { format: 'json' })
  }

  ordersAndOrderLineItemsUrl() {
    const { orderView } = this.state
    const url = orderView ? 'orders_end_of_day_report_path' : 'order_line_items_end_of_day_report_path'
    return Routes[url](window.tenant, window.depoId, this.endOfDayReportId(), {
      format: 'json',
      page: this.state.currentPage
    })
  }

  endOfDayReportId() {
    return this.props.match.params.endOfDayReportId
  }

  changePage(pageIncrement) {
    const { currentPage, pageData } = this.state
    if (pageData.length < 100 && pageIncrement == 1) return
    const newPage = Math.min(Math.max(1, currentPage + pageIncrement))
    if (newPage === currentPage) return
    this.setState({ currentPage: newPage, paginationDisabled: true }, () => this.loadPage())
  }

  handleChange(event, value) {
    const { orderView } = this.state
    if (Number(!orderView) === value) return
    this.setState({ orderView: !orderView, currentPage: 1 }, () => {
      this.loadPage()
    })
  }

  renderTabSwitch() {
    const { orderView } = this.state
    return (
      <Tabs
        value={Number(!orderView)}
        indicatorColor='secondary'
        textColor='secondary'
        centered
        onChange={this.handleChange.bind(this)}
      >
        <Tab label='Orders' />
        <Tab label='Order line items' />
      </Tabs>
    )
  }

  renderPagination() {
    const { paginationDisabled, currentPage } = this.state
    return (
      <div className='pagination float-right'>
        <strong>Page: {currentPage}</strong>
        &nbsp;
        <IconButton onClick={this.changePage.bind(this, -1)} disabled={paginationDisabled}>
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton onClick={this.changePage.bind(this, 1)} disabled={paginationDisabled}>
          <KeyboardArrowRight />
        </IconButton>
      </div>
    )
  }

  renderTableHead() {
    const { orderView } = this.state
    return orderView ? <OrdersTableHead /> : <OrderLineItemsTableHead />
  }

  renderTableRows() {
    const { orderView, pageData } = this.state
    return this.state.pageData.map((data, index) =>
      orderView ? this.renderOrdersTableRow(data, index) : this.renderOrderLineItemsTableRow(data, index)
    )
  }

  renderOrdersTableRow(data, index) {
    return <OrdersTableRow order={data} key={index} />
  }

  renderOrderLineItemsTableRow(data, index) {
    const warehouseId = this.props.location.state ? this.props.location.state.endOfDayReport.car_warehouse_id : null
    return <OrderLineItemsTableRow orderLineItem={data} warehouseId={warehouseId} key={index} />
  }

  renderTable() {
    return (
      <Table>
        {this.renderTableHead()}
        {this.renderTableRows()}
      </Table>
    )
  }

  renderBackButton() {
    return (
      <div className='heat-map-back-button' style={{ marginTop: 50 }}>
        <BackButton to={Routes.end_of_day_reports_path(window.tenant, window.depoId)} />
      </div>
    )
  }

  render() {
    return (
      <div>
        <div className='float-right'>
          {this.renderBackButton()}
          {this.renderPagination()}
        </div>
        {this.renderTabSwitch()}
        {this.renderTable()}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMainTitle: (title) => dispatch(setMainTitle(title)),
    setFlash: (flash) => dispatch(setFlash(flash)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const OrdersAndOrderLineItemsTable = connect(null, mapDispatchToProps)(OrdersAndOrderLineItemsTableClass)

export default OrdersAndOrderLineItemsTable
