import React from 'react'
import { Marker } from 'react-map-gl'

function RouteMapUnplannedSales(props) {
  const fillOpacity = props.customerView ? 0.3 : 1

  return props.unplannedSales.map((carStopPoint, index) => {
    if (carStopPoint) {
      return (
        <Marker
          key={index}
          latitude={carStopPoint.coordinates[1]}
          longitude={carStopPoint.coordinates[0]}
          anchor='center'
        >
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: '#F3942F',
              opacity: { fillOpacity },
              borderRadius: '100%'
            }}
          ></div>
        </Marker>
      )
    }
  })
}

export default RouteMapUnplannedSales
