const closeCalendarOnClick = function () {
  document.querySelector('body').addEventListener('click', (e) => {
    if (!this.state.datePickerVisible) return

    const clickWithinCalendar =
      (e.target.className && e.target.className.length && e.target.className.includes('DayPicker')) ||
      e.target.getAttribute('datepickerelement')

    if (!clickWithinCalendar) this.toggleDatePicker()
  })
}

export default closeCalendarOnClick
