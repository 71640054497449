import React from 'react'
import { Link } from 'react-router-dom'

const CustomerSupportButton = ({ to, title }) => {
  return (
    <a
      className='mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent customer-support-button'
      href={to}
    >
      {title}
    </a>
  )
}

export default CustomerSupportButton
