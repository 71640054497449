import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'

class RoutePointDeletionDialog extends React.Component {
  state = { activeRoutes: [], activeRoutesLoaded: false }

  componentDidMount() {
    this.fetchActiveRoutes()
  }

  activeRoutesUrl() {
    return Routes.alternative_active_routes_route_point_path(
      window.tenant,
      window.depoId,
      this.props.routeId,
      this.props.routePoint.id,
      { format: 'json' }
    )
  }

  fetchActiveRoutes() {
    axios
      .get(this.activeRoutesUrl())
      .then((res) => {
        this.setState({ activeRoutesLoaded: true, activeRoutes: res.data })
      })
      .catch((error) => {
        this.props.setFlash(`${error} (${this.activeRoutesUrl()})`)
      })
  }

  toggleDesctuctionAndClose() {
    this.props.toggleDestruction()
    this.props.close()
  }

  renderActiveRoutes() {
    return this.state.activeRoutes.map((activeRoute) => {
      return (
        <Grid key={activeRoute.id} item className='ml-2'>
          {' '}
          - {activeRoute.name}
        </Grid>
      )
    })
  }

  renderActiveRoutesTitle() {
    if (this.state.activeRoutesLoaded) {
      return <p className='m-0'>Customers are also subscribed for stops in following routes:</p>
    }
  }

  renderLoadingSpinner() {
    if (!this.state.activeRoutesLoaded) {
      return (
        <CircularProgress
          className='loading-spinner'
          size={60}
          style={{
            position: 'absolute',
            zIndex: '999',
            marginLeft: 'calc(50% - 60px)',
            top: 'calc(50%)'
          }}
        />
      )
    }
  }

  renderTitle() {
    const { routePoint, position, customerCount } = this.props
    const customerCountSingularOrPlural =
      customerCount == 1 ? `${customerCount} customer` : `${customerCount} customers`
    return (
      <div>
        <DialogTitle>
          Stop "#{position}", address: "{routePoint.address}" has {customerCountSingularOrPlural} subscribed to it. Are
          you sure want to delete?
        </DialogTitle>
        <Divider />
      </div>
    )
  }

  renderContent() {
    return (
      <DialogContent>
        <Grid container className='mt-2 d-table'>
          {this.renderLoadingSpinner()}
          {this.renderActiveRoutesTitle()}
          {this.renderActiveRoutes()}
        </Grid>
      </DialogContent>
    )
  }

  render() {
    const { open, close } = this.props
    return (
      <Dialog open={open} onClose={close} id='deletion-dialog'>
        {this.renderTitle()}
        {this.renderContent()}
        <DialogActions>
          <Button variant='contained' color='primary' onClick={this.toggleDesctuctionAndClose.bind(this)}>
            Delete
          </Button>
          <Button variant='contained' color='secondary' onClick={close}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default RoutePointDeletionDialog
