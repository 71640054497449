import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import findIndex from 'lodash/findIndex'
import chunk from 'lodash/chunk'
import takeWhile from 'lodash/takeWhile'
import each from 'lodash/each'
import moment from 'moment'
import { startLoading, stopLoading, loadRoadPoints, setFlash } from '../../files/actions/index'

class GoogleTimeCalculationClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      batchedRoutePoints: [],
      batchIndex: 0,
      timeData: []
    }
  }

  filteredRoutePoints(routePoints) {
    return takeWhile(routePoints, 'address')
  }

  filteredWayPoints(routePoints) {
    return this.filteredRoutePoints(routePoints).map((routePoint) => {
      const coordinates = routePoint.point.coordinates
      return {
        location: new google.maps.LatLng(coordinates[1], coordinates[0]),
        stopover: true
      }
    })
  }

  stringTimeToFloat(time) {
    if (!time.includes(':')) return
    const splittedTime = time.split(':')
    const hours = parseFloat(splittedTime[0]) * 60
    const minutes = parseFloat(splittedTime[1])
    const seconds = parseFloat(splittedTime[2]) / 60
    return hours + minutes + seconds
  }

  setCalculatedEAT() {
    const { timeData } = this.state
    const { waitTime, arrivalTime, lastArrivalTime, toggle } = this.props
    const routePoints = this.props.routePoints.slice(0).filter((point) => point.address)
    const administrationTime = this.props.route.administration_time
    const distributedTime = this.stringTimeToFloat(administrationTime) / routePoints.length
    if (arrivalTime.length < 1) {
      this.props.setFlash('You need to enter arrival time to calculate google ETA')
      this.props.stopLoading()
      return
    }
    each(routePoints, (routePoint, index) => {
      if (index == 0 || routePoints[index - 1].separator) {
        routePoint.google_estimate_time = moment(arrivalTime, 'HH:mm:ss').format('HH:mm:ss')
      } else {
        const eat = moment(routePoints[index - 1].google_estimate_time, 'HH:mm:ss')
        eat.add(Math.ceil(timeData[index - 1] / 60), 'm')
        if (toggle === 'customStops') {
          const routePointWaitTime = routePoints[index - 1].wait_time
          eat.add(this.stringTimeToFloat(routePointWaitTime), 'm')
        } else if (toggle === 'stopDuration') {
          eat.add(this.stringTimeToFloat(waitTime), 'm')
        }
        routePoint.google_estimate_time = eat.add(distributedTime, 'm').format('HH:mm:ss')
      }
    })

    const routePointsWithNewEta =
      toggle === 'routeDuration' &&
      arrivalTime &&
      lastArrivalTime &&
      arrivalTime.length > 0 &&
      lastArrivalTime.length > 0
        ? this.calculateAndSetNewGoogleETA(routePoints)
        : routePoints

    each(
      routePointsWithNewEta,
      (routePoint) =>
        (routePoint.google_estimate_time = moment(routePoint.google_estimate_time, 'HH:mm:ss').format('HH:mm'))
    )

    this.props.loadRoutePoints(routePointsWithNewEta)
    this.setState({ timeData: [] })
    this.props.stopLoading()
  }

  calculateAndSetNewGoogleETA(routePoints) {
    const { route } = this.props
    if (routePoints.length < 0) return

    const firstArrivalTime = moment(this.props.arrivalTime, 'HH:mm:ss')
    const lastArrivalTime = moment(this.props.lastArrivalTime, 'HH:mm:ss')
    const routeDuration = lastArrivalTime.diff(firstArrivalTime, 'seconds') / 3600

    const firstGoogleETA = moment(routePoints[0].google_estimate_time, 'HH:mm:ss')
    const lastGoogleETA = moment(routePoints[routePoints.length - 1].google_estimate_time, 'HH:mm:ss')
    const difference = lastGoogleETA.diff(firstGoogleETA, 'seconds') / 3600

    const durationWithDepoTimes =
      routeDuration -
      (this.stringTimeToFloat(route.time_from_depo) / 60 + this.stringTimeToFloat(route.time_to_depo) / 60)
    const leftTime = durationWithDepoTimes - difference

    if (this.props.toggle !== 'routeDuration') return routePoints

    if (leftTime > 0) {
      const newDuration = (leftTime / routePoints.length) * 60
      each(routePoints, (routePoint, index) => {
        routePoint.wait_time = newDuration.toFixed(2)
        if (index != 0) {
          const calculatedDuration = newDuration + newDuration * index
          const newGoogleETA = moment(routePoint.google_estimate_time, 'HH:mm:ss').add(calculatedDuration, 'm')
          routePoint.google_estimate_time = newGoogleETA.format('HH:mm:ss')
        }
      })
    } else {
      each(routePoints, (routePoint) => (routePoint.wait_time = 0.0))
    }

    return routePoints
  }

  pointCoordinates(routePoint) {
    const coordinates = routePoint.point.coordinates
    return new google.maps.LatLng(coordinates[1], coordinates[0])
  }

  getGoogleDirections() {
    const { arrivalTime } = this.props
    const index = this.state.batchIndex
    const batchedRoutePoints = this.state.batchedRoutePoints
    const previousBatch = index == 0 ? [] : batchedRoutePoints[index - 1]
    const pointsGroup = batchedRoutePoints[index]
    const firstPoint =
      index == 0
        ? this.pointCoordinates(pointsGroup[0])
        : this.pointCoordinates(previousBatch[previousBatch.length - 1])
    const lastPoint = this.pointCoordinates(pointsGroup[pointsGroup.length - 1])
    const wayPoints = this.filteredWayPoints(pointsGroup)

    if (index == 0) {
      wayPoints.shift()
    }
    wayPoints.pop()

    const directionsService = new google.maps.DirectionsService()
    directionsService.route(
      {
        origin: firstPoint,
        destination: lastPoint,
        waypoints: wayPoints,
        travelMode: 'DRIVING',
        provideRouteAlternatives: false,
        unitSystem: google.maps.UnitSystem.METRIC,
        optimizeWaypoints: false,
        drivingOptions: {
          departureTime: moment(arrivalTime).add(1, 'days').toDate()
        }
      },
      this.handleGoogleRouteResponse.bind(this)
    )
  }

  handleGoogleRouteResponse(response, status) {
    if (status == 'OK') {
      const routeDirectionArray = response.routes[0].legs
      const timeData = routeDirectionArray.map((point) => point.duration.value)
      this.setState(
        {
          batchIndex: ++this.state.batchIndex,
          timeData: this.state.timeData.concat(timeData)
        },
        this.nextBatchDirectionsOrSetEAT.bind(this)
      )
      this.props.markAsUpdated()
    } else {
      this.props.setFlash(`Calculate EAT Failed with status ${status}`)
    }
  }

  nextBatchDirectionsOrSetEAT() {
    if (this.state.batchIndex < this.state.batchedRoutePoints.length) {
      this.getGoogleDirections()
    } else {
      this.setCalculatedEAT()
    }
  }

  calculateEAT() {
    const { routePoints } = this.props
    this.setState(
      {
        batchIndex: 0,
        batchedRoutePoints: chunk(this.filteredRoutePoints(routePoints), 24)
      },
      () => {
        this.props.startLoading()
        this.getGoogleDirections()
      }
    )
  }

  render() {
    return (
      <Button variant='contained' color='primary' onClick={this.calculateEAT.bind(this)} disabled={this.props.disabled}>
        Calculate ETA
      </Button>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    setFlash: (message) => dispatch(setFlash(message)),
    loadRoutePoints: (routePoints) => dispatch(loadRoadPoints(routePoints))
  }
}

const GoogleTimeCalculation = connect(mapStateToProps, mapDispatchToProps)(GoogleTimeCalculationClass)

export default GoogleTimeCalculation
