import React from 'react'
import Button from '@material-ui/core/Button'
import MaterialDatePicker from '../shared/MaterialDatePicker'
import EndOfDayReportsCarsFilter from './EndOfDayReportsCarsFilter'
import startCase from 'lodash/startCase'
import moment from 'moment'

class DateRangeCarFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...props }
  }

  dateChange(fieldName, date) {
    this.props.handleChange(fieldName, moment(date).format('DD-MM-YYYY'))
  }

  componentDidUpdate() {
    const { startDate, endDate } = this.props
    if (moment(startDate, dateTimeFormat).diff(moment(endDate, dateTimeFormat), 'days') > 0) {
      this.props.handleChange('endDate', startDate)
    }
  }

  renderDatePicker(fieldName, disableBeforeDate) {
    return (
      <MaterialDatePicker
        key={`${fieldName}-${this.props[fieldName]}`}
        fieldName={fieldName}
        label={startCase(fieldName)}
        value={this.props[fieldName]}
        disableBeforeDate={this.props[disableBeforeDate]}
        onDateChange={this.dateChange.bind(this, fieldName)}
      />
    )
  }

  render() {
    const { car_ids, cars, handleChange, disabled, refilter } = this.props
    return (
      <React.Fragment>
        <EndOfDayReportsCarsFilter handleChange={handleChange} disabled={disabled} car_ids={car_ids} cars={cars} />
        {this.renderDatePicker('startDate')}
        {this.renderDatePicker('endDate', 'startDate')}
        <Button className='ml-2 mr-2 align-bottom' onClick={refilter} variant='contained' color='primary' size='small'>
          Filter
        </Button>
      </React.Fragment>
    )
  }
}

export default DateRangeCarFilter
