import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import FunTextField from '../forms/FunTextField'

class RouteCopyDialog extends React.Component {
  state = { depo_id: this.props.route.depo_id, errors: '' }

  renderDialogTitle() {
    return (
      <DialogTitle id='alert-dialog-title'>{`Are you sure you want to copy ${this.props.route.name}?`}</DialogTitle>
    )
  }

  handleChange(e, value) {
    this.setState({ [value]: e.target.value })
  }

  renderDialogContent(depos) {
    return (
      <DialogContent>
        <FunTextField
          select
          label='Depo'
          className='depo-dropdown'
          field='depo_id'
          state={this.state}
          handleChange={this.handleChange.bind(this)}
        >
          {this.renderDepoOptions(depos)}
        </FunTextField>
      </DialogContent>
    )
  }

  renderDepoOptions(depos) {
    if (depos) {
      return depos.map((depo, index) => {
        return (
          <MenuItem key={index} value={depo.id}>
            {depo.title}
          </MenuItem>
        )
      })
    }
  }

  copyRoute() {
    this.props.copyRoute(this.state.depo_id)
  }

  renderReverseButton() {
    return (
      <Button onClick={this.props.reverseRoute.bind(this, this.state.depo_id)} color='primary'>
        Copy reverse
      </Button>
    )
  }

  render() {
    const { open, close, copyRoute, depos } = this.props
    return (
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {this.renderDialogTitle()}
        {this.renderDialogContent(depos)}
        <DialogActions>
          <Button onClick={close} color='primary'>
            Cancel
          </Button>
          <Button onClick={this.copyRoute.bind(this)} color='primary'>
            Copy route
          </Button>
          {this.renderReverseButton()}
        </DialogActions>
      </Dialog>
    )
  }
}

export default RouteCopyDialog
