import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import UsersForm from './UsersForm'
import UsersTable from './UsersTable'

const UsersComponent = (componentProps) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path='/:tenant/admin/depo/:depoId/users/:userId/edit'
          render={(props) => <UsersForm {...props} lakrids_host={componentProps.lakrids_host} />}
        />
        <Route
          path='/:tenant/admin/depo/:depoId/users/new'
          render={(props) => <UsersForm {...props} lakrids_host={componentProps.lakrids_host} />}
        />
        <Route path='/:tenant/admin/depo/:depoId/users' component={UsersTable} />
      </Switch>
    </BrowserRouter>
  )
}

export default UsersComponent
