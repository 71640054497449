import React from 'react'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'

const iconStyle = {
  color: 'gray',
  marginTop: 0,
  marginRight: 5,
  cursor: 'pointer'
}

const errorMessage = 'You must enter more than 2 characters in search field'

class FunSearchFieldWithoutRedux extends React.Component {
  constructor(props) {
    super(props)
    this.state = { searchPhrase: '' }
  }

  handleChange(e) {
    this.setState({ searchPhrase: e.target.value })
  }

  clearInput() {
    this.setState({ searchPhrase: '' }, this.props.clearSearch())
  }

  handleOnKeyDown(e) {
    if (e.key !== 'Enter') return
    const { searchPhrase } = this.state
    if (this.props.withoutSearchLimit) {
      this.props.handleSubmit(searchPhrase)
    } else {
      searchPhrase.length > 2 ? this.props.handleSubmit(searchPhrase) : this.props.setFlash(errorMessage)
    }
  }

  renderInputIcon() {
    if (this.state.searchPhrase) {
      return (
        <Icon style={iconStyle} onClick={this.clearInput.bind(this)}>
          close
        </Icon>
      )
    } else {
      return <Icon style={iconStyle}>search</Icon>
    }
  }

  render() {
    return (
      <Input
        id='search-field'
        value={this.state.searchPhrase}
        onChange={this.handleChange.bind(this)}
        onKeyDown={this.handleOnKeyDown.bind(this)}
        fullWidth
        margin='dense'
        placeholder='Search'
        disableUnderline={true}
        endAdornment={<InputAdornment position='end'>{this.renderInputIcon()}</InputAdornment>}
      />
    )
  }
}

export default FunSearchFieldWithoutRedux
