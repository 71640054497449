import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'
import Info from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'

class PlanEventCustomersFullDetailsRow extends React.Component {
  state = { open: false }

  handleTooltipOpening() {
    this.setState({ open: true })
  }

  handleTooltipOpeningWithDealy() {
    this.setState({ popperClass: '' })
    setTimeout(this.handleTooltipOpening.bind(this), 200)
  }

  handleTooltipClosing() {
    this.setState({ popperClass: 'reason-popper-no-transform' })
  }

  handleTooltipClosingWithDealy() {
    this.setState({ open: false })
    setTimeout(this.handleTooltipClosing.bind(this), 200)
  }

  renderTimeWithInfo(skipped) {
    const { full_description_of_sms_skip } = this.props.customer
    if (full_description_of_sms_skip) {
      return (
        <Tooltip
          classes={{ popper: this.state.popperClass, tooltip: 'tooltip' }}
          placement='left-start'
          open={this.state.open}
          title={
            <React.Fragment>
              <ul style={{ fontSize: 14, width: 300 }}>
                {full_description_of_sms_skip.split(',').map((reason) => {
                  return <li key={reason}>{reason}</li>
                })}
              </ul>
            </React.Fragment>
          }
        >
          <React.Fragment>
            <Info
              style={{ fill: skipped ? 'red' : 'green' }}
              onMouseEnter={this.handleTooltipOpeningWithDealy.bind(this)}
              onMouseLeave={this.handleTooltipClosingWithDealy.bind(this)}
            />
            {skipped ? this.props.customer.reason_of_sms_skip : this.props.customer.sms_sent_at}
          </React.Fragment>
        </Tooltip>
      )
    } else {
      return <>{this.props.customer.sms_sent_at}</>
    }
  }

  renderReasonOfSmsSkip() {
    return (
      <div className='vertical-center-items'>
        {this.renderMoreDescriptiveInfo()}
        <span style={{ marginLeft: 5, lineHeight: '12px' }}>{this.props.customer.reason_of_sms_skip}</span>
      </div>
    )
  }

  render() {
    const { customer } = this.props
    const { location, plan_event_route_point } = customer

    return (
      <TableRow key={customer.id}>
        <TableCell>{customer.phone_number}</TableCell>
        <TableCell>{`${location.address} ${location.city ? location.city : ''}`}</TableCell>
        <TableCell>{plan_event_route_point.address}</TableCell>
        <TableCell>{customer.sms_should_send_at}</TableCell>
        <TableCell>{this.renderTimeWithInfo(customer.sms_sent_skipped_at)}</TableCell>
        <TableCell>{customer.car_stopped_at ? customer.car_stopped_at : '--:--'}</TableCell>
        <TableCell>{customer.google_estimate_time}</TableCell>
        <TableCell>{customer.original_google_estimate_time}</TableCell>
      </TableRow>
    )
  }
}

export default PlanEventCustomersFullDetailsRow
