import React from 'react'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { withStyles } from '@material-ui/core/styles'

import FeedbackForm from './FeedbackForm'
import truncate from 'lodash/truncate'

const styles = {
  idTableCell: {
    padding: '4px 4px 4px 16px !important'
  },
  formTableRow: {
    height: 'auto'
  },
  formTableCellCollapsed: {
    border: 'none'
  },
  formTableCell: {
    padding: '0 !important'
  }
}

class FeedbackTableRow extends React.Component {
  state = { formClosed: true }

  toggleForm = () => this.setState({ formClosed: !this.state.formClosed })

  renderForm() {
    const {
      classes: { formTableCell, formTableRow, formTableCellCollapsed },
      feedback: { status },
      feedback
    } = this.props

    const className = `${formTableCell} ${
      this.state.formClosed && formTableCellCollapsed
    } eod-table-row-expanded status-${status}`

    return (
      <TableRow className={formTableRow}>
        <TableCell colSpan={12} className={className}>
          <Collapse in={!this.state.formClosed} timeout='auto' unmountOnExit>
            <FeedbackForm
              feedback={this.props.feedback}
              submitFeedback={this.props.submitFeedback.bind(this)}
              toggleForm={this.toggleForm}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    )
  }

  renderActions() {
    return (
      <React.Fragment>
        <IconButton onClick={this.toggleForm}>
          {this.state.formClosed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </IconButton>
      </React.Fragment>
    )
  }

  render() {
    const { formClosed } = this.state
    const {
      feedback: {
        id,
        created_at,
        route_name,
        driver_name,
        planned_arrival_time,
        actual_arrival_time,
        stop_duration,
        feedback,
        comment,
        manager_comment,
        status,
        customer_phone_number,
        handled_by_full_name,
        handled_by_at
      },
      classes: { formTableCellCollapsed, idTableCell }
    } = this.props

    const cellClass = formClosed ? '' : formTableCellCollapsed
    const statusClass = `status ${idTableCell} status-${status}`

    return (
      <TableBody>
        <TableRow className={`table-row-${id}`} hover={formClosed} onClick={this.toggleForm}>
          <TableCell className={`${cellClass} ${statusClass}`}>{created_at}</TableCell>
          <TableCell className={cellClass}>{route_name}</TableCell>
          <TableCell className={cellClass}>{driver_name}</TableCell>
          <TableCell className={cellClass}>{planned_arrival_time}</TableCell>
          <TableCell className={cellClass}>{actual_arrival_time}</TableCell>
          <TableCell className={cellClass}>{stop_duration}</TableCell>
          <TableCell className={cellClass}>{feedback}</TableCell>
          <TableCell className={cellClass}>{customer_phone_number}</TableCell>
          <TableCell style={{ width: 250 }} className={cellClass}>
            {truncate(comment, { length: 50 })}
          </TableCell>
          <TableCell style={{ width: 250 }} className={cellClass}>
            {truncate(manager_comment, { length: 50 })}
          </TableCell>
          <TableCell className={cellClass}>
            {handled_by_full_name && `${handled_by_at} (${handled_by_full_name})`}
          </TableCell>
          <TableCell className={cellClass}>{this.renderActions()}</TableCell>
        </TableRow>
        {this.renderForm()}
      </TableBody>
    )
  }
}

export default withStyles(styles)(FeedbackTableRow)
