import React from 'react'
import axios from 'axios'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import FunTextField from '../../forms/FunTextField'
import Product from '../../../models/Product'
import ProductsFormBalances from './ProductsFormBalances'
import ProductMovement from './ProductMovement'
import SaveButton from '../../shared/SaveButton'
import CancelButton from '../../shared/CancelButton'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import FlashNotification from '../../shared/FlashNotification'
import extractDataErrors from '../../support/extractDataErrors'
import Checkbox from '@material-ui/core/Checkbox'
import isEmpty from 'lodash/isEmpty'

class ProductsForm extends React.Component {
  state = {
    ...Product(),
    loading: false,
    message: null,
    movementStartItem: null,
    movementStartItemKey: null,
    startSingleMovementFrom: false,
    startMultipleMovementFrom: false,
    remaining_warehouse_product_balances: []
  }

  componentDidMount() {
    if (!this.productId()) return
    this.startLoading()
    axios
      .all([
        axios.get(this.productUrl()),
        axios.get(
          Routes.remaining_warehouse_product_balances_inventory_product_path(tenant, depoId, this.productId(), {
            format: 'json'
          })
        )
      ])
      .then(
        axios.spread((product, remaining_warehouse_product_balances) => {
          this.setState({
            ...product.data,
            remaining_warehouse_product_balances: remaining_warehouse_product_balances.data
          })
          this.stopLoading()
        })
      )
      .catch((err) => {
        this.setState({ message: `${err}` })
        this.stopLoading()
      })
  }

  fieldDisabled = (field) => this.state[`${field}_editable`] === false
  startLoading = () => this.setState({ loading: true })
  stopLoading = () => this.setState({ loading: false })
  clearErrors = () => this.setState({ full_errors: null, errors: {}, message: null })
  productId = () => this.props.match.params.productId || this.state.id
  productsUrl = () => Routes.inventory_products_path(tenant, depoId)
  productUrl = () =>
    Routes.inventory_product_path(tenant, depoId, this.productId(), {
      format: 'json'
    })
  url = () => (this.productId() ? this.productUrl() : this.productsUrl())
  method = () => (this.productId() ? 'patch' : 'post')

  data() {
    const {
      sku,
      description,
      sequence_number,
      packaging_size,
      sales_price,
      minimum_required_balances,
      tax_type,
      tax_amount,
      storage_unit,
      volume,
      status,
      b2b_product
    } = this.state

    return {
      product: {
        sku,
        description,
        sequence_number,
        packaging_size,
        sales_price,
        minimum_required_balances_attributes: minimum_required_balances,
        tax_type,
        tax_amount,
        storage_unit,
        volume,
        status,
        b2b_product
      },
      format: 'json'
    }
  }

  updateMinimumRequiredBalances = (data) => this.setState({ minimum_required_balances: data })
  handleRadioButtonChange = (e) => this.setState({ status: e.target.value })
  handleSubmitError = (error) => this.setState(extractDataErrors(error), this.stopLoading)
  handleChange = (e, field) => this.setState({ [field]: e.target.value })

  handleSubmitSuccess(response) {
    this.setState({
      ...response.data,
      errors: {},
      loading: false,
      message: `Product with sku: ${response.data.sku} successfully updated!`
    })
  }

  handleSuccessfulProductMovement = () => this.setState({ remaining_warehouse_product_balances: [] })

  submit(e) {
    e.preventDefault()
    this.startLoading()
    axios({
      method: this.method(),
      url: this.url(),
      data: this.data()
    })
      .then(this.handleSubmitSuccess.bind(this))
      .catch(this.handleSubmitError.bind(this))
  }

  handleCheckboxChange(field, e) {
    this.setState({ [field]: !this.state[field] })
  }

  renderMainTitleAndProductMovementButton() {
    const title = this.productId() ? `Edit Product #${this.productId()}` : 'New Product'
    return (
      <div className='center-items justify-content-between'>
        <span className='mdl-layout-title'>{title}</span>
        {this.renderProductMovement()}
      </div>
    )
  }

  renderProductMovement() {
    if (!this.productId()) return
    const { remaining_warehouse_product_balances } = this.state
    return (
      <ProductMovement
        handleSuccessfulProductMovement={this.handleSuccessfulProductMovement}
        disabled={remaining_warehouse_product_balances.length < 1}
        productId={this.productId()}
      />
    )
  }

  renderInputFields() {
    return [
      'sku',
      'description',
      'sequence_number',
      'packaging_size',
      'sales_price',
      'tax_type',
      'tax_amount',
      'volume'
    ].map((field) => {
      const type = ['sales_price', 'tax_amount', 'volume'].includes(field) ? 'number' : 'text'
      const disabled = this.fieldDisabled(field)
      return (
        <FunTextField
          key={field}
          field={field}
          type={type}
          state={this.state}
          disabled={disabled}
          handleChange={this.handleChange.bind(this)}
        />
      )
    })
  }

  renderUnitSelect() {
    const disabled = this.fieldDisabled('storage_unit')
    return (
      <FunTextField
        select
        key='storage_unit'
        field='storage_unit'
        state={this.state}
        disabled={disabled}
        handleChange={this.handleChange.bind(this)}
      >
        <MenuItem value='stk'>Stk.</MenuItem>
        <MenuItem value='Kolli'>Kolli</MenuItem>
      </FunTextField>
    )
  }

  renderStatusCheckboxes() {
    const { status } = this.state
    const disabled = this.fieldDisabled('status')
    return (
      <div style={{ marginBottom: '5px' }}>
        <RadioGroup
          disabled={disabled}
          style={{ display: 'block' }}
          aria-label='status'
          name='status'
          value={status}
          onChange={this.handleRadioButtonChange.bind(this)}
        >
          <FormControlLabel disabled={disabled} value='active' control={<Radio />} label='Active' />
          <FormControlLabel disabled={disabled} value='inactive' control={<Radio />} label='Inactive' />
        </RadioGroup>
      </div>
    )
  }

  renderButtons() {
    const saveDisabled = !isAdmin && !this.productId()
    return (
      <React.Fragment>
        <SaveButton disabled={saveDisabled} />
        <CancelButton to={this.productsUrl()} />
      </React.Fragment>
    )
  }

  renderProductBalances() {
    const { minimum_required_balances, remaining_warehouse_product_balances } = this.state
    return (
      <ProductsFormBalances
        productId={this.productId()}
        minimum_required_balances={minimum_required_balances}
        remaining_warehouse_product_balances={remaining_warehouse_product_balances}
        updateMinimumRequiredBalances={this.updateMinimumRequiredBalances.bind(this)}
      />
    )
  }

  renderB2bProductCheckbox() {
    if (isHjemis) {
      return (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                id='b2b-product'
                checked={this.state.b2b_product}
                onChange={this.handleCheckboxChange.bind(this, 'b2b_product')}
              />
            }
            label='B2B product'
          />
        </div>
      )
    }
  }

  renderFlashNotification() {
    const { full_errors, message } = this.state
    const msg = !isEmpty(full_errors) ? `${full_errors.join(', ')}` : message
    return <FlashNotification message={msg} clearErrors={this.clearErrors.bind(this)} />
  }

  renderLoading = () => <LoadingWithoutRedux loading={this.state.loading} />

  render() {
    return (
      <div>
        <Grid container justifyContent='space-between'>
          <Grid item xs={12} sm={8} md={4} lg={4}>
            {this.renderMainTitleAndProductMovementButton()}
            <form noValidate autoComplete='off' onSubmit={this.submit.bind(this)}>
              {this.renderInputFields()}
              {this.renderUnitSelect()}
              {this.renderStatusCheckboxes()}
              {this.renderB2bProductCheckbox()}
              {this.renderButtons()}
            </form>
          </Grid>
          <Grid item xs={12} sm={8} md={7} lg={7}>
            {this.renderProductBalances()}
          </Grid>
          {this.renderFlashNotification()}
          {this.renderLoading()}
        </Grid>
      </div>
    )
  }
}

export default ProductsForm
