import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import {
  setFlash,
  loadPrintPreviewStatus,
  updatePrintGridColumnCount,
  updatePrintGridRowCount,
  setMapPrintingStatus,
  cancelPrintPreview
} from '../../files/actions/index'

class RoutePointsPrintPreviewClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      printGridColumnCount: 1,
      printGridRowCount: 1
    }
  }

  componentDidMount() {
    const printJSIframe = document.getElementById('printJS')
    const printJSdiv = document.getElementById('printable-images')
    if (printJSIframe) printJSIframe.remove()
    if (printJSdiv) printJSdiv.remove()
    this.props.updatePrintGridColumnCount(this.state.printGridColumnCount)
    this.props.updatePrintGridRowCount(this.state.printGridRowCount)
  }

  cancelPrintPreview() {
    this.setState({ printGridColumnCount: 1, printGridRowCount: 1 }, this.props.cancelPrintPreview)
  }

  handleCountChange(event) {
    const { name, value } = event.target
    switch (name) {
      case 'row':
        this.setState({ printGridRowCount: value }, () => {
          value > 0 && value < 5 ? this.props.updatePrintGridRowCount(value) : this.showCountErrorMessage()
        })
        break
      case 'column':
        this.setState({ printGridColumnCount: value }, () => {
          value > 0 && value < 5 ? this.props.updatePrintGridColumnCount(value) : this.showCountErrorMessage()
        })
        break
    }
  }

  showCountErrorMessage() {
    this.props.setFlash('Rows and columns count must be between 1 and 4.')
  }

  validateRowAndColumnCount() {
    const { printGridRowCount, printGridColumnCount } = this.state
    if (
      parseInt(printGridColumnCount) < 1 ||
      parseInt(printGridColumnCount) > 4 ||
      parseInt(printGridRowCount) < 1 ||
      parseInt(printGridRowCount) > 4 ||
      !printGridColumnCount ||
      !printGridRowCount
    ) {
      return true
    }
    return false
  }

  startPrinting() {
    this.props.setMapPrintingStatus('started')
  }

  render() {
    const { printGridColumnCount, printGridRowCount } = this.state
    if (!this.props.printPreview) return null
    return (
      <div style={{ padding: '0 15px 15px 15px' }}>
        <div>
          <TextField
            onChange={this.handleCountChange.bind(this)}
            inputProps={{ min: '1', max: '4', step: '1' }}
            label='Rows'
            name='row'
            value={printGridRowCount}
            type='number'
            margin='normal'
            style={{ width: '100%' }}
          />
          <TextField
            onChange={this.handleCountChange.bind(this)}
            inputProps={{ min: '1', max: '4', step: '1' }}
            label='Columns'
            name='column'
            value={printGridColumnCount}
            type='number'
            margin='normal'
            style={{ width: '100%' }}
          />
        </div>
        <div className='form-actions'>
          <Button
            disabled={this.validateRowAndColumnCount() || this.props.mapPrinting == 'started'}
            onClick={this.startPrinting.bind(this)}
            color='primary'
            variant='contained'
          >
            Print
          </Button>
          <Button onClick={this.cancelPrintPreview.bind(this)}>Cancel</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    mapPrinting: state.mapPrinting
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFlash: (message) => dispatch(setFlash(message)),
    loadPrintPreviewStatus: (printPreview) => dispatch(loadPrintPreviewStatus(printPreview)),
    updatePrintGridColumnCount: (printGridColumnCount) => dispatch(updatePrintGridColumnCount(printGridColumnCount)),
    updatePrintGridRowCount: (printGridRowCount) => dispatch(updatePrintGridRowCount(printGridRowCount)),
    setMapPrintingStatus: (mapPrinting) => dispatch(setMapPrintingStatus(mapPrinting)),
    cancelPrintPreview: () => dispatch(cancelPrintPreview())
  }
}

const RoutePointsPrintPreview = connect(mapStateToProps, mapDispatchToProps)(RoutePointsPrintPreviewClass)

export default RoutePointsPrintPreview
