import React from 'react'
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import axios from 'axios'
import NewButton from '../../shared/NewButton'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import IconButton from '@material-ui/core/IconButton'
import ModeEdit from '@material-ui/icons/Edit'
import More from '@material-ui/icons/More'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import DeleteForever from '@material-ui/icons/DeleteForever'

class WarehousesTable extends React.Component {
  state = { warehouses: [], loading: true }

  componentDidMount() {
    axios
      .get(
        Routes.inventory_warehouses_path(window.tenant, window.depoId, {
          format: 'json'
        })
      )
      .then((res) => {
        this.setState({ warehouses: res.data, loading: false })
      })
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading} />
  }

  warehouseStyle(warehouse) {
    if (warehouse.invalid_products.length) {
      return { backgroundColor: 'red' }
    }
  }

  deleteWarehouse(warehouse, e) {
    e.preventDefault()
    if (!confirm('Are you sure you want destroy this Warehouse?')) return

    const slicedWarehouses = this.state.warehouses.slice(0)
    axios
      .delete(Routes.inventory_warehouse_path(window.tenant, window.depoId, warehouse.id, { format: 'json' }))
      .then((res) => {
        const index = slicedWarehouses.findIndex((w) => w.id === warehouse.id)
        slicedWarehouses.splice(index, 1)
        this.setState({ warehouses: slicedWarehouses })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  renderWarehouses() {
    const { warehouses } = this.state

    return warehouses.map((warehouse) => {
      return (
        <TableRow style={this.warehouseStyle(warehouse)} key={warehouse.id}>
          <TableCell>{warehouse.code}</TableCell>
          <TableCell>{warehouse.name}</TableCell>
          <TableCell>
            {warehouse.owner} ({warehouse.owner_type})
          </TableCell>
          <TableCell>{warehouse.invalid_products.join(', ')}</TableCell>
          <TableCell>
            <Link to={Routes.edit_inventory_warehouses_path(window.tenant, window.depoId, warehouse.id)}>
              <IconButton>
                <ModeEdit />
              </IconButton>
            </Link>
          </TableCell>
          <TableCell>
            <Link to={Routes.show_inventory_warehouse_path(window.tenant, window.depoId, warehouse.id, 'products')}>
              <IconButton>
                <More />
              </IconButton>
            </Link>
          </TableCell>
          <TableCell>
            <IconButton onClick={this.deleteWarehouse.bind(this, warehouse)}>
              <DeleteForever />
            </IconButton>
          </TableCell>
        </TableRow>
      )
    })
  }

  render() {
    return (
      <div>
        <Typography variant='h5'>Warehouses</Typography>
        <NewButton
          customStyle={{ zIndex: 999 }}
          to={Routes.new_inventory_warehouses_path(window.tenant, window.depoId)}
        />
        <Table className='resources-table'>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Negative balances</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderWarehouses()}</TableBody>
        </Table>
        {this.renderLoading()}
      </div>
    )
  }
}

export default WarehousesTable
