import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import More from '@material-ui/icons/More'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import BackButtons from '../shared/BackButtons'
import PrintableTable from '../shared/PrintableTable'

class StockTransfer extends React.Component {
  state = { transfer: {}, warehouse: null, loading: false }

  componentDidMount() {
    this.startLoading()
    axios
      .get(
        Routes.inventory_warehouse_stock_transfer_path(
          window.tenant,
          window.depoId,
          this.warehouseId(),
          this.stockOrderId(),
          { format: 'json' }
        )
      )
      .then((res) => {
        this.setState({ transfer: res.data })
      })

    axios
      .get(Routes.inventory_warehouse_path(window.tenant, window.depoId, this.warehouseId(), { format: 'json' }))
      .then((res) => {
        this.setState({ warehouse: res.data })
        this.stopLoading()
      })
  }

  stopLoading() {
    this.setState({ loading: false })
  }

  startLoading() {
    this.setState({ loading: true })
  }

  stockOrderId() {
    return this.props.match.params.stockOrderId
  }

  warehouseId() {
    return this.props.match.params.warehouseId
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading} />
  }

  renderBackButtons() {
    return <BackButtons warehouseId={this.warehouseId()} parent='transfers' />
  }

  renderMainTitle() {
    if (!this.state.warehouse) return
    const { warehouse } = this.state
    return (
      <Typography variant='h5' className='ml-1'>
        Warehouse - {warehouse.owner} ({warehouse.owner_type})
      </Typography>
    )
  }

  renderTransferHeader() {
    const { transfer } = this.state
    return (
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        {this.renderGridItem(12, 12, 12, 12, 'Transfer nr:', transfer.id)}
        {this.renderGridItem(12, 8, 6, 4, 'Transfered at:', transfer.transfered_at)}
        {this.renderGridItem(12, 8, 6, 4, 'From warehouse:', transfer.from_warehouse)}
        {this.renderGridItem(12, 8, 6, 4, 'To warehouse:', transfer.to_warehouse)}
        {this.renderGridItem(12, 8, 6, 4, 'Kind:', transfer.kind)}
        {transfer.main_storage_message &&
          this.renderGridItem(12, 8, 6, 4, 'Main Storage message:', transfer.main_storage_message)}
        {this.renderGridItem(12, 12, 12, 12, 'Description:', transfer.description)}
        {transfer.stock_order &&
          this.renderGridItem(
            12,
            12,
            12,
            12,
            'Created From:',
            <Link
              to={Routes.inventory_warehouse_stock_order_path(
                window.tenant,
                window.depoId,
                this.warehouseId(),
                transfer.stock_order.id
              )}
            >
              Stock Order #{transfer.stock_order.id}
            </Link>
          )}
      </Grid>
    )
  }

  renderGridItem(xs, sm, md, lg, title, value) {
    return (
      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
        <strong>{title}</strong> {value}
      </Grid>
    )
  }

  renderTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell>SKU</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Packages (amount)</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  renderTableBody() {
    if (!this.state.transfer.stock_transfer_line_items) return
    return (
      <TableBody>
        {this.state.transfer.stock_transfer_line_items.map(({ sku, description, packaged_amount, amount }, index) => {
          return (
            <TableRow key={index}>
              <TableCell>{sku}</TableCell>
              <TableCell>{description}</TableCell>
              <TableCell>{packaged_amount ? `${packaged_amount} (${amount})` : amount}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    )
  }

  renderPrintMarkupWithButton() {
    if (Object.keys(this.state.transfer).length === 0) return

    const {
      transfer: { stock_transfer_line_items, from_warehouse, to_warehouse, created_by, created_at }
    } = this.state

    const header = {
      documentTitle: `stock-transfer-${created_at}`,
      forWarehouse: `For warehouse: ${from_warehouse}-${to_warehouse}`,
      createdBy: `Created by: ${created_by}`,
      createdAt: `Created at ${created_at}`
    }

    return (
      <PrintableTable
        items={stock_transfer_line_items.map(({ amount, description, sku }) => ({ amount, description, sku }))}
        header={header}
        onlyAmount
      />
    )
  }

  renderExportButton() {
    const { transfer: id } = this.state
    if (!id) return
    const path = Routes.export_transfer_document_inventory_warehouse_stock_transfer_path(
      tenant,
      depoId,
      this.warehouseId(),
      id,
      { format: 'xlsx' }
    )

    return (
      <Link className='ml-3' to={path} target='_blank'>
        <Button className='xlsx-export mr-3' variant='contained' color='primary'>
          Export
        </Button>
      </Link>
    )
  }

  render() {
    return (
      <div>
        <div className='content-header sticky-header'>
          <div className='content-header-title mt-3'>
            {this.renderBackButtons()}
            {this.renderMainTitle()}
          </div>
          <div className='action-buttons mt-3'>
            {this.renderExportButton()}
            {this.renderPrintMarkupWithButton()}
          </div>
          <div className='content-subheader'>{this.renderTransferHeader()}</div>
        </div>
        <Table>
          {this.renderTableHead()}
          {this.renderTableBody()}
        </Table>
        {this.renderLoading()}
      </div>
    )
  }
}

export default StockTransfer
