import React from 'react'
import { connect } from 'react-redux'
import { Route, Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import find from 'lodash/find'
import { setMainTitle, startLoading, stopLoading, setFlash } from '../../../files/actions/index'

import Depo from '../../../models/Depo'
import GridWrapper from '../../shared/GridWrapper'
import SaveButton from '../../shared/SaveButton'
import CancelButton from '../../shared/CancelButton'

import FunTextField from '../../forms/FunTextField'
import extractDataErrors from '../../support/extractDataErrors'
import MaterialMapboxAutocomplete from '../../shared/MaterialMapboxAutocomplete'
import { debounce } from 'lodash'

class DeposFormClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = Depo()
  }

  componentDidMount() {
    this.props.startLoading()

    const depo = this.findDepoInStore()

    if (depo) {
      this.setDepoAndMainTitle(depo)
      this.props.stopLoading()
    } else if (this.depoId()) {
      axios
        .get(this.loadUrl())
        .then((res) => {
          this.setDepoAndMainTitle(res.data)
        })
        .catch((error) => {
          this.props.setFlash(`${error} (${this.loadUrl()})`)
        })
        .then(this.props.stopLoading)
    } else {
      this.setMainTitle()
      this.props.stopLoading()
    }
  }

  depoId() {
    return this.props.match.params.depoId || this.state.id
  }

  findDepoInStore() {
    return find(this.props.depos, ({ id }) => id == this.depoId())
  }

  setDepoAndMainTitle(depo) {
    this.setDepo(depo)
  }

  setDepo(depo) {
    const {
      id,
      title,
      description,
      address,
      dashboard,
      sms_text,
      sms_route_text,
      sms_subscribe_text,
      sms_unsubscribe_text,
      recepient,
      marketing_sms,
      customer_axa_id,
      wallmob_id
    } = depo
    this.setState(
      {
        id,
        title,
        description,
        address,
        dashboard,
        recepient,
        sms_text,
        sms_route_text,
        sms_subscribe_text,
        sms_unsubscribe_text,
        marketing_sms,
        customer_axa_id,
        wallmob_id
      },
      () => this.setMainTitle()
    )
  }

  setMainTitle() {
    this.props.setMainTitle(this.depoId() ? `Edit depo ${this.state.title}` : 'New depo')
  }

  setStateAfterSave(data) {
    this.setState({
      id: data.id,
      errors: {}
    })
  }

  data() {
    return { depo: this.state, format: 'json' }
  }

  loadUrl() {
    return Routes.admin_depo_path(tenant, this.depoId(), { format: 'json' })
  }

  url() {
    return this.depoId()
      ? Routes.admin_depo_path(tenant, this.depoId(), { format: 'json' })
      : Routes.admin_depos_path(tenant, { format: 'json' })
  }

  method() {
    return this.depoId() ? 'patch' : 'post'
  }

  getLocationAndSubmit(e) {
    e.preventDefault()
    this.props.startLoading()
    this.submit()
  }

  submit() {
    axios({
      method: this.method(),
      url: this.url(),
      data: this.data()
    })
      .then(this.handleSubmitSuccess.bind(this))
      .catch(this.handleSubmitError.bind(this))
      .then(this.props.stopLoading())
  }

  handleSubmitSuccess(response) {
    const successMessage = this.depoId() ? 'Depo successfully updated!' : `Depo successfully created!`
    this.setStateAfterSave(response.data)
    this.props.setFlash(successMessage)
    this.setMainTitle()
  }

  handleSubmitError(error) {
    this.setState(extractDataErrors(error))
    this.props.setFlash(`${error} (${this.url()})`)
  }

  handleChange(e, value) {
    this.setState({ [value]: e.target.value })
  }

  handleAddressChange(getPlaces, e) {
    this.setState({ address: e.target.value }, debounce(getPlaces, 500))
  }

  selectAddress(address, coordinates) {
    this.setState({ address: address }, )
    if (coordinates) {
      this.setState({ address_coordinates: [coordinates.lat, coordinates.lng] })
    }
  }

  renderSmsTextFields() {
    return (
      <React.Fragment>
        <Typography variant='h5' className='pt-4'>
          SMS texts
        </Typography>
        {['sms_text', 'sms_route_text', 'sms_subscribe_text', 'sms_unsubscribe_text'].map((field) => {
          if (isHjemis && field == 'sms_route_text') return
          return (
            <FunTextField
              key={field}
              field={field}
              multiline
              state={this.state}
              handleChange={this.handleChange.bind(this)}
            />
          )
        })}
      </React.Fragment>
    )
  }

  renderCustomerAxField() {
    if (isHjemis) {
      return (
        <FunTextField
          field='customer_axa_id'
          state={this.state}
          label='AX customer ID'
          handleChange={this.handleChange.bind(this)}
        />
      )
    }
  }

  renderMarketingForm() {
    if (isHjemis) {
      return (
        <FunTextField
          field='marketing_sms'
          state={this.state}
          label='Marketing SMS'
          handleChange={this.handleChange.bind(this)}
        />
      )
    }
  }

  renderForm() {
    return (
      <form noValidate autoComplete='off' onSubmit={this.getLocationAndSubmit.bind(this)}>
        <FunTextField field='title' state={this.state} handleChange={this.handleChange.bind(this)} />
        <FunTextField field='description' state={this.state} handleChange={this.handleChange.bind(this)} />
        <MaterialMapboxAutocomplete
            type="global"
            handleChange={this.handleAddressChange.bind(this)}
            value={this.state.address}
            selectAddress={this.selectAddress.bind(this)}
            accessToken={this.props.access_token}
            label="Address"
          />
        <FunTextField field="dashboard" state={this.state} handleChange={this.handleChange.bind(this)} />
        <FunTextField field="recepient" multiline state={this.state} handleChange={this.handleChange.bind(this)} />
        <FunTextField field="wallmob_id" multiline state={this.state} handleChange={this.handleChange.bind(this)} />
        {this.renderSmsTextFields()}
        {this.renderMarketingForm()}
        {this.renderCustomerAxField()}
        <SaveButton />
        <CancelButton to={Routes.admin_depos_path(tenant)} />
      </form>
    )
  }

  render() {
    return <GridWrapper>{this.renderForm()}</GridWrapper>
  }
}

const mapStateToProps = (state) => {
  return {
    mainTitle: state.mainTitle,
    depos: state.resources
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMainTitle: (mainTitle) => dispatch(setMainTitle(mainTitle)),
    setFlash: (flash) => dispatch(setFlash(flash)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const DeposForm = connect(mapStateToProps, mapDispatchToProps)(DeposFormClass)

export default DeposForm
