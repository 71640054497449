import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import snakeCase from 'lodash/snakeCase'

const BackButtons = ({ warehouseId, parent, label }) => {
  return (
    <div>
      <Link
        style={{ textDecoration: 'none', marginRight: 10 }}
        to={Routes.show_inventory_warehouse_path(tenant, depoId, warehouseId, 'products')}
      >
        <Button variant='contained' size='small' color='primary'>
          Warehouse
        </Button>
      </Link>
      <Link
        style={{ textDecoration: 'none' }}
        to={Routes.show_inventory_warehouse_path(tenant, depoId, warehouseId, snakeCase(parent))}
      >
        <Button variant='contained' size='small' color='primary'>
          {label || parent}
        </Button>
      </Link>
    </div>
  )
}

export default BackButtons
