import { SET_MAIN_TITLE, SET_FLASH, START_LOADING, STOP_LOADING } from '../constants/action-types'

const initialState = {
  mainTitle: '',
  flash: null,
  loading: false
}

const appReducer = (state = initialState, action) => {
  const payload = action.payload
  switch (action.type) {
    case SET_MAIN_TITLE:
      return { ...state, mainTitle: payload }
    case SET_FLASH:
      return { ...state, flash: payload }
    case START_LOADING:
      return { ...state, loading: true }
    case STOP_LOADING:
      return { ...state, loading: false }
    default:
      return state
  }
}

export default appReducer
