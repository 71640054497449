import React from 'react'
import axios from 'axios'

import ReactDataGrid from 'react-data-grid'
import { Editors } from 'react-data-grid-addons'

import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Close from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'

import FunTextField from '../../forms/FunTextField'
import MaterialDatePicker from '../../shared/MaterialDatePicker'
import DataGrid from '../../shared/DataGrid'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import BackButtons from '../shared/BackButtons'

import startCase from 'lodash/startCase'
import includes from 'lodash/includes'
import omit from 'lodash/omit'

import moment from 'moment'
import FlashNotification from '../../shared/FlashNotification'

const { AutoComplete } = Editors

const transferKinds = [
  { id: 'regular', name: 'Regular' },
  { id: 'sales', name: 'Sales' },
  { id: 'write_off', name: 'Write Off' },
  { id: 'manual_counting', name: 'Manual Counting' },
  { id: 'other', name: 'Other' },
  { id: 'win_lose', name: 'Win/lose' }
]

class StockOrderForm extends React.Component {
  state = {
    rows: [{}],
    deletedRows: [],
    products: [],
    stock_order: {},
    warehouses: [],
    toWarehouse: '',
    fromWarehouse: '',
    reason: '',
    ordered_at: moment().format('DD-MM-YYYY'),
    loading: false,
    errors: {},
    message: null,
    showNotEnoughStock: false,
    kind: 'regular',
    depos: [],
    fromWarehouses: [],
    selectedDepo: window.depoId,
    isSubmitting: false
  }

  componentDidMount() {
    this.startLoading()
    this.fetchWarehouses()
    this.fetchDepos()
  }

  loadOrder() {
    const { stockOrderId } = this.props.match.params
    if (stockOrderId) {
      axios
        .get(
          Routes.inventory_warehouse_stock_order_path(tenant, depoId, this.warehouseId(), stockOrderId, {
            format: 'json'
          })
        )
        .then((res) => {
          const {
            data,
            data: {
              stock_order_line_items,
              to_warehouse_id,
              from_warehouse_id,
              reason,
              ordered_at,
              kind,
              main_storage_message
            }
          } = res

          const rowData = stock_order_line_items
            .map((stli) => {
              if (from_warehouse_id) {
                stli.remaining_balance =
                  this.state.products.filter((p) => p.id === stli.product_id)[0].remaining_balance - stli.amount
              } else {
                stli.remaining_balance = 0
              }
              return stli
            })
            .concat({})
          this.setState(
            {
              rows: rowData,
              stock_order: data,
              toWarehouse: to_warehouse_id,
              fromWarehouse: from_warehouse_id || '',
              reason: reason || '',
              ordered_at: ordered_at || '',
              kind: kind,
              main_storage_message: main_storage_message
            },
            this.highlightNegatives.bind(this)
          )
          this.stopLoading()
        })
        .catch((err) => {
          this.setState({ message: `${err}` })
          this.stopLoading()
        })
    }
  }

  startLoading = () => this.setState({ loading: true })
  stopLoading = () => this.setState({ loading: false })

  fetchProducts() {
    axios
      .get(
        Routes.inventory_products_path(tenant, depoId, {
          statuses: ['active'],
          warehouse_id: this.state.fromWarehouse,
          format: 'json'
        })
      )
      .then((res) => {
        this.setState({ products: res.data, rows: [{}] }, () => this.loadOrder())
      })
      .catch((err) => {
        this.setState({ message: `${err}` })
        this.stopLoading()
      })
      .then(this.insertSelectedProducts.bind(this))
  }

  fetchDepos() {
    this.startLoading()
    axios
      .get(Routes.admin_depos_path(window.tenant, { format: 'json' }))
      .then((res) => {
        const dataWithName = res.data.map((d) => {
          d.name = d.title
          return d
        })
        this.setState({ depos: dataWithName })
      })
      .catch((err) => {
        this.setState({ message: `${err}` })
        this.stopLoading()
      })
  }

  warehouseId() {
    return this.props.match.params.warehouseId
  }

  insertSelectedProducts() {
    if (!this.props.location.state) return
    const { selectedProducts } = this.props.location.state
    if (!selectedProducts.length > 0) return

    this.setState(
      {
        rows: selectedProducts
          .filter((p) => p.checked && p.filledAmount)
          .map((product) => {
            const productWithBalance = this.state.products.filter((p) => p.id === product.id)[0]
            return {
              product_id: product.id,
              sku: product.sku,
              description: product.description,
              package_amount: product.filledPackages || 0,
              amount: product.filledAmount || 0,
              remaining_balance: (productWithBalance ? productWithBalance.remaining_balance : 0) - product.filledAmount
            }
          })
          .concat({})
      },
      () => this.highlightNegatives()
    )
  }

  fetchWarehouses() {
    return axios
      .get(Routes.inventory_warehouses_path(tenant, depoId, { format: 'json' }))
      .then((res) => {
        const depoWarehouse = res.data.filter((r) => r.owner_type === 'Depo' && r.primary)
        const carWarehouse = res.data.filter((r) => r.id == this.warehouseId() && r.owner_type == 'Car')
        if (carWarehouse.length > 0) {
          this.setState(
            {
              warehouses: res.data,
              fromWarehouse: depoWarehouse[0] && depoWarehouse[0].id,
              toWarehouse: carWarehouse[0].id
            },
            this.fetchProducts.bind(this)
          )
        } else {
          this.setState(
            {
              warehouses: res.data,
              toWarehouse: depoWarehouse[0] && depoWarehouse[0].id
            },
            this.fetchProducts.bind(this)
          )
        }
        this.stopLoading()
      })
      .catch((err) => {
        this.stopLoading()
      })
  }

  fetchFromWarehouses(depo) {
    axios
      .get(
        Routes.inventory_warehouses_path(window.tenant, depo, {
          format: 'json'
        })
      )
      .then((res) => {
        this.setState({ fromWarehouses: res.data })
      })
      .catch((err) => {
        this.setState({ message: `${err}` })
        this.stopLoading()
      })
  }

  onGridRowsUpdated({ fromRow, toRow, updated }) {
    this.setState((state) => {
      const rows = state.rows.slice()
      const selectedProduct = this.state.products.filter(
        (product) => updated[Object.keys(updated)[0]] === product[Object.keys(updated)[0]]
      )
      updated = selectedProduct.length > 0 ? omit(selectedProduct[0], 'id') : updated

      for (let i = fromRow; i <= toRow; i++) {
        if (rows[i].product_id) {
          const product = this.state.products.filter((p) => p.id === rows[i].product_id)
          const updatedKey = Object.keys(updated)[0]
          switch (updatedKey) {
            case 'package_amount':
              updated.amount = updated.package_amount * product[0].packaging_size
              break
            case 'amount':
              updated.package_amount = Math.ceil(updated.amount / product[0].packaging_size)
              break
            default:
              if (selectedProduct.length > 0) {
                updated.amount = 0
                updated.package_amount = 0
              }
          }
          if (this.state.fromWarehouse) {
            rows[i].remaining_balance = product[0].remaining_balance - updated.amount
            updated.remaining_balance = product[0].remaining_balance - updated.amount
          } else {
            rows[i].remaining_balance = 0
            updated.remaining_balance = 0
          }
        }
        if (selectedProduct.length > 0) {
          rows[i] = {
            ...rows[i],
            ...updated,
            product_id: selectedProduct[0].id
          }
        } else {
          rows[i] = { ...rows[i], ...updated }
        }
      }
      return { rows }
    }, this.highlightNegatives.bind(this))
  }

  highlightNegatives() {
    if (!!this.state.fromWarehouse) {
      Array.from(document.getElementsByClassName('remaining_balance')).forEach((cell) => {
        if (cell.attributes.value && cell.attributes.value.value < 0) {
          cell.classList.add('negative')
          !this.state.showNotEnoughStock && this.setState({ showNotEnoughStock: true })
        } else {
          cell.classList.remove('negative')
        }
      })
    } else {
      Array.from(document.getElementsByClassName('remaining_balance')).forEach((cell) => {
        cell.classList.remove('negative')
      })
    }
  }

  removeCurrentRow(deletable_row) {
    const { rows } = this.state
    if (rows.length > 1) {
      this.setState({
        deletedRows: [...this.state.deletedRows, { id: deletable_row.id, _destroy: true }],
        rows: rows.filter((row) => row !== deletable_row)
      })
    }
  }

  getCellActions(column, row) {
    const cellActions = [
      {
        icon: <Close style={{ marginTop: 5 }} />,
        callback: this.removeCurrentRow.bind(this, row)
      }
    ]

    return column.key == 'action' ? cellActions : null
  }

  returnGridColumns() {
    return [
      {
        key: 'sku',
        name: 'Sku',
        editable: true,
        editor: this.renderAutoCompleteEditor('sku'),
        cellClass: 'product-sku'
      },
      {
        key: 'description',
        name: 'Description',
        editable: true,
        editor: this.renderAutoCompleteEditor('description')
      },
      {
        key: 'package_amount',
        name: 'Packages amount',
        editable: true,
        cellClass: 'package-amount'
      },
      {
        key: 'amount',
        name: 'Order amount',
        editable: true,
        cellClass: 'transfer-amount'
      },
      {
        key: 'remaining_balance',
        name: 'Remaining balance',
        editable: true,
        cellClass: 'remaining_balance'
      },
      { key: 'action', name: 'Actions', editable: false }
    ]
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading} />
  }

  renderAutoCompleteEditor(type) {
    if (this.state.products.length > 0) {
      return <AutoComplete options={this.avaliableProducts(type)} />
    }
  }

  avaliableProducts(type) {
    const { products } = this.state
    return products.map((p) => ({
      id: p.id,
      title: type == 'sku' ? p.sku : p.description
    }))
  }

  appendEmptyRow(e) {
    if (e.rowIdx == this.state.rows.length - 1) {
      this.setState({ rows: [...this.state.rows, {}] })
    }
  }

  handleChange(field, e) {
    if (field === 'kind' && e.target.value === 'win_lose') {
      const rows = this.state.rows
        .map((row) => {
          row.remaining_balance = 0
          return row
        })
        .filter((row) => row.sku)
        .concat({})
      this.setState({ rows: [{}] }, () => {
        this.setState(
          {
            [field]: e.target.value,
            fromWarehouse: '',
            rows: rows
          },
          this.highlightNegatives
        )
      })
    } else if (field === 'fromWarehouse') {
      if (this.state.kind === 'win_lose') {
        this.setState({ kind: '' })
      }
      this.setState({ [field]: e.target.value }, this.fetchProducts.bind(this))
    } else {
      this.setState({ [field]: e.target.value })
    }
  }

  handleDepoChange(e) {
    this.setState({ selectedDepo: e.target.value }, () => this.fetchFromWarehouses(this.state.selectedDepo))
  }

  renderDeposSelect() {
    const { depos, selectDisabled, selectedDepo } = this.state
    return (
      <FormControl>
        <InputLabel htmlFor='warehouse-select'>Depo</InputLabel>
        <Select
          className={`warehouse-select depo-select`}
          id='depo-select'
          disabled={selectDisabled}
          value={selectedDepo}
          onChange={this.handleDepoChange.bind(this)}
        >
          {depos.map((depo) => {
            return (
              <MenuItem key={depo.id} value={depo.id}>
                <ListItemText primary={depo.name} />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }

  renderWarehouseSelect(field) {
    let warehouses = this.state.warehouses
    if (field === 'fromWarehouse' && this.state.fromWarehouses.length > 0) {
      warehouses = this.state.fromWarehouses
    }
    return (
      <FormControl>
        <InputLabel htmlFor='warehouse-select'>{startCase(field)}</InputLabel>
        <Select
          className={`warehouse-select ${field}`}
          id={field}
          disabled={!!this.state.stock_order.id}
          value={this.state[field] || ''}
          onChange={this.handleChange.bind(this, field)}
        >
          {warehouses.map((warehouse) => (
            <MenuItem key={warehouse.id} value={warehouse.id}>
              <ListItemText primary={warehouse.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  renderKindSelect() {
    return (
      <FormControl>
        <InputLabel htmlFor='kind-select'>Kind</InputLabel>
        <Select
          id='kind-select'
          className='warehouse-select'
          disabled={!!this.state.stock_order.id}
          value={this.state.kind}
          onChange={this.handleChange.bind(this, 'kind')}
        >
          {transferKinds.map((option) => {
            return (
              <MenuItem key={option.id} value={option.id}>
                <ListItemText primary={option.name} />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }

  stockOrderUrl() {
    if (this.state.stock_order.id) {
      return Routes.inventory_warehouse_stock_order_path(
        tenant,
        depoId,
        this.warehouseId(),
        this.state.stock_order.id,
        { format: 'json' }
      )
    } else {
      return Routes.inventory_warehouse_stock_orders_path(tenant, depoId, this.warehouseId(), { format: 'json' })
    }
  }

  stockTransferUrl() {
    return Routes.inventory_warehouse_stock_transfers_path(tenant, depoId, this.warehouseId(), { format: 'json' })
  }

  method() {
    return this.state.stock_order.id ? 'patch' : 'post'
  }

  stockOrderData() {
    const { deletedRows, toWarehouse, fromWarehouse, reason } = this.state
    return {
      stock_order: {
        stock_order_line_items_attributes: this.validRows().concat(...deletedRows),
        to_warehouse_id: toWarehouse,
        from_warehouse_id: fromWarehouse,
        reason: reason,
        kind: this.state.kind,
        main_storage_message: this.state.main_storage_message
      },
      format: 'json'
    }
  }

  stockTransferData(stockTransfer) {
    const { ordered_at, to_warehouse, from_warehouse, id, kind, main_storage_message } = stockTransfer
    return {
      stock_transfer: {
        transfered_at: ordered_at,
        stock_transfer_line_items_attributes: this.stockTransferLineItemData(),
        to_warehouse_id: to_warehouse && to_warehouse.id,
        from_warehouse_id: from_warehouse && from_warehouse.id,
        kind: kind,
        stock_order_id: id,
        stock_order_attributes: { id: id, status: 'received' },
        main_storage_message: main_storage_message
      },
      format: 'json'
    }
  }

  validRows() {
    return this.state.rows
      .filter((row) => row.sku && row.amount != 0)
      .map((r) => {
        return { id: r.id, product_id: r.product_id, amount: r.amount }
      })
  }

  stockTransferLineItemData() {
    return this.state.rows
      .filter((row) => row.sku && row.amount != 0)
      .map((r) => {
        return {
          product_id: r.product_id,
          amount: r.amount,
          stock_order_line_item_id: r.id
        }
      })
  }

  renderMainTitle() {
    if (this.state.stock_order.id) {
      return <Typography variant='h5'>Edit Order #{this.state.stock_order.id}</Typography>
    } else {
      return <Typography variant='h5'>New Stock Order</Typography>
    }
  }

  updateIsSubmiting() {
    this.setState({ isSubmitting: false })
  }

  submit(action) {
    if (this.state.isSubmitting) return;

    this.setState({ isSubmitting: true });

    this.startLoading()
    const handleSubmitSuccess =
      action === 'approve' ? this.handleSubmitSuccessAndCreateTransfer : this.handleSubmitSuccess
    axios({
      method: this.method(),
      url: this.stockOrderUrl(),
      data: this.stockOrderData()
    }).then(
      handleSubmitSuccess.bind(this)
    ).catch(
      this.handleSubmitError.bind(this)
    )

    setTimeout(this.updateIsSubmiting.bind(this), 2000)
  }

  submitStockTransfer(stockTransfer) {
    this.startLoading()
    axios({
      method: 'post',
      url: this.stockTransferUrl(),
      data: this.stockTransferData(stockTransfer)
    })
      .then(this.handleTransferSubmitSuccess.bind(this))
      .catch(this.handleSubmitError.bind(this))
  }

  handleSubmitSuccess(response) {
    this.props.history.push(
      Routes.inventory_warehouse_stock_order_path(tenant, depoId, this.warehouseId(), response.data.id)
    )
  }

  handleTransferSubmitSuccess(response) {
    const {
      stock_order: { id }
    } = response.data
    this.props.history.push({
      pathname: Routes.inventory_warehouse_stock_order_path(tenant, depoId, this.warehouseId(), id),
      state: { transferSuccessMessage: 'Stock Transfer succesfuly created' }
    })
  }

  handleSubmitSuccessAndCreateTransfer(response) {
    this.submitStockTransfer(response.data)
  }

  handleSubmitError(error) {
    this.setState({
      message: error.response.data.full_errors && `${error.response.data.full_errors.join(', ')}`,
      loading: false
    })
  }

  renderSaveAndApproveButton() {
    return (
      <Button className="ml-3" variant="contained" color="primary" onClick={this.submit.bind(this, 'approve')} disabled={this.state.isSubmitting}>Save & Approve</Button>
    )
  }

  renderInputFields() {
    return (
      <React.Fragment>
        <FunTextField
          style={{ width: 200, marginRight: 10, marginTop: 0, marginBottom: 0 }}
          label='Movement order ID'
          field='main_storage_message'
          state={this.state}
          handleChange={this.handleChange.bind(this, 'main_storage_message')}
        />
        <FunTextField
          style={{ width: 200, marginRight: 10, marginTop: 0, marginBottom: 0 }}
          field='reason'
          state={this.state}
          handleChange={this.handleChange.bind(this, 'reason')}
        />
      </React.Fragment>
    )
  }

  renderBackButtons() {
    return <BackButtons warehouseId={this.warehouseId()} parent='orders' />
  }

  clearErrors() {
    this.setState({ message: false })
  }

  renderNewTransferFromDepot() {
    const { fromWarehouse, showNotEnoughStock } = this.state

    if (showNotEnoughStock && fromWarehouse) {
      return (
        <a
          style={{ marginLeft: 10 }}
          href={Routes.new_inventory_warehouse_stock_transfers_path(
            window.tenant,
            window.depoId,
            this.state.fromWarehouse
          )}
          target='_blank'
        >
          <strong>Not enough stock?</strong>
        </a>
      )
    }
  }

  renderHeader() {
    return (
      <React.Fragment>
        <div className='content-header'>
          <div className='content-header-title'>
            {this.renderBackButtons()}
            {this.renderMainTitle()}
            {this.renderNewTransferFromDepot()}
          </div>
        </div>
        <div className='content-subheader'>
          {this.renderDeposSelect()}
          {this.renderWarehouseSelect('fromWarehouse')}
          {this.renderWarehouseSelect('toWarehouse')}
          {this.renderKindSelect()}
          {this.renderInputFields()}
          {this.renderSaveAndApproveButton()}
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <div>
        <FlashNotification message={this.state.message} clearErrors={this.clearErrors.bind(this)} />
        {this.renderHeader()}
        <DataGrid
          onGridRowsUpdated={this.onGridRowsUpdated.bind(this)}
          getCellActions={this.getCellActions.bind(this)}
          columns={this.returnGridColumns()}
          appendEmptyRow={this.appendEmptyRow.bind(this)}
          rows={this.state.rows}
        />
        {this.renderLoading()}
      </div>
    )
  }
}

export default StockOrderForm
