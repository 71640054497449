import React from 'react'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import SharedTableRow from './SharedTableRow'

class SharedTable extends React.Component {
  renderTableHead() {
    if (!this.props.columns) return
    return (
      <TableHead>
        <TableRow>
          {this.props.columns.map((item, index) => {
            return (
              <TableCell style={{ padding: '5px 5px 5px 5px' }} key={index}>
                {item}
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
    )
  }

  renderData(data) {
    return data.map((dataItem, dataIndex) => {
      return (
        <SharedTableRow
          additionalRowStyles={this.props.additionalRowStyles}
          key={dataIndex}
          toggleForm={this.props.toggleForm}
          dataItem={dataItem}
          dataIndex={dataIndex}
          formComponent={this.props.formComponent}
          formColspan={this.props.formColspan}
          columns={this.props.columns}
        />
      )
    })
  }

  renderTableBody() {
    if (!this.props.data) return
    return this.renderData(this.props.data)
  }

  render() {
    return (
      <Table className={this.props.className}>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </Table>
    )
  }
}

export default SharedTable
