import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import CarForm from './CarForm'
import CarsTable from './CarsTable'

const CarsComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/:tenant/admin/depo/:depoId/cars/:carId/edit' component={CarForm} />
        <Route path='/:tenant/admin/depo/:depoId/cars/new' component={CarForm} />
        <Route path='/:tenant/admin/depo/:depoId/cars' component={CarsTable} />
      </Switch>
    </BrowserRouter>
  )
}

export default CarsComponent
