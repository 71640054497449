import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormLabel from '@material-ui/core/FormLabel'

export default class FeedbackForm extends React.Component {
  state = { ...this.props.feedback }

  handleApprovement = () => {
    this.props.submitFeedback(this.state.id, {
      manager_comment: this.state.manager_comment,
      status: 'approved'
    })
  }

  handleSave = () => {
    this.props.submitFeedback(this.state.id, {
      manager_comment: this.state.manager_comment
    })
  }

  handleChange = (e) => this.setState({ manager_comment: e.target.value })

  renderActionButtons() {
    if (this.state.status == 'approved') return

    return (
      <div className='header-actions'>
        <Button variant='contained' color='primary' onClick={this.handleApprovement}>
          Approve
        </Button>
        <Button variant='contained' onClick={this.handleSave}>
          Save
        </Button>
      </div>
    )
  }

  renderCustomerComment() {
    const { comment, status } = this.state

    if (comment.length) {
      return (
        <div className={`comment-container ${status}`}>
          <FormLabel>Customer comment:</FormLabel>
          <Typography variant='body2'>{comment}</Typography>
        </div>
      )
    }
  }

  renderDepoManagerComment() {
    const { manager_comment, status } = this.state

    if (status === 'approved') {
      return (
        <div className={`comment-container ${status}`}>
          <FormLabel>Depo manager comment (internal):</FormLabel>
          <Typography variant='body1'>{manager_comment}</Typography>
        </div>
      )
    } else {
      return (
        <div className='comment-container'>
          <TextField
            className='feedback-input'
            label='Depo manager comment (internal):'
            fullWidth
            placeholder='Depo manager comment here...'
            InputLabelProps={{
              shrink: true
            }}
            onChange={this.handleChange}
            value={manager_comment || ''}
          />
        </div>
      )
    }
  }

  render() {
    return (
      <div className='feedback-form'>
        {this.renderActionButtons()}
        {this.renderCustomerComment()}
        {this.renderDepoManagerComment()}
      </div>
    )
  }
}
