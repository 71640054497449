import React from 'react'
import { Provider } from 'react-redux'
import store from '../../files/store'
import PlanEventTemplatesIndex from './PlanEventTemplatesIndex'
import PlanEventCustomers from '../planEvents/PlanEventCustomers'
import PlanEventCustomersMap from '../planEvents/PlanEventCustomersMap'
import ErrorCatcher from '../shared/ErrorCatcher'
import PlanEventCustomersFullDetails from '../planEvents/PlanEventCustomersFullDetails'

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PlanEventMap from '../map/PlanEventMap'
import PlanEventHeatMap from '../map/PlanEventHeatMap'

const PlanEventTemplatesIndexWithStore = (props) => {
  return (
    <Provider store={store}>
      <ErrorCatcher>
        <BrowserRouter>
          <Switch>
            <Route path='/:tenant/depo/:depoId/plan_events/all_customers/:weekday?' component={PlanEventCustomers} />
            <Route
              path='/:tenant/depo/:depoId/plan_events/:planEventId/sms_customers/full_details'
              component={PlanEventCustomersFullDetails}
            />
            <Route path='/:tenant/depo/:depoId/plan_events/:planEventId/sms_customers' component={PlanEventCustomers} />
            <Route
              path='/:tenant/depo/:depoId/plan_events/:planEventId/sms_customer_map'
              render={(renderProps) => (
                <PlanEventCustomersMap {...renderProps} mapboxAccessToken={props.access_token} />
              )}
            />
            <Route
              path='/:tenant/depo/:depoId/plan_events/:planEventId/heat_map'
              render={(renderProps) => <PlanEventHeatMap {...renderProps} mapboxAccessToken={props.access_token} />}
            />
            <Route
              path='/:tenant/depo/:depoId/plan_events/:planEventId'
              render={(renderProps) => <PlanEventMap {...renderProps} mapboxAccessToken={props.access_token} />}
            />
            <Route path='/:tenant/depo/:depoId/plan_event_templates/:week?' component={PlanEventTemplatesIndex} />
          </Switch>
        </BrowserRouter>
      </ErrorCatcher>
    </Provider>
  )
}

export default PlanEventTemplatesIndexWithStore
