import React from 'react'
import { connect } from 'react-redux'
import { Route, Link } from 'react-router-dom'
import Select from '@material-ui/core/Select'
import { FormControl } from '@material-ui/core'
import MaterialDatePicker from '../../shared/MaterialDatePicker'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import axios from 'axios'
import filter from 'lodash/filter'
import moment from 'moment'

import { setMainTitle, startLoading, stopLoading, setFlash, loadDepos } from '../../../files/actions/index'

import SmsCampaign from '../../../models/SmsCampaign'
import GridWrapper from '../../shared/GridWrapper'
import SaveButton from '../../shared/SaveButton'
import CancelButton from '../../shared/CancelButton'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FunTextField from '../../forms/FunTextField'
import IntegrationReactSelect from '../../forms/IntegrationReactSelect'
import extractDataErrors from '../../support/extractDataErrors'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

class SmsCampaignsFormClass extends React.Component {
  constructor(props) {
    super(props)
    const smsCampaign = SmsCampaign()
    this.state = { ...smsCampaign }
  }

  componentDidMount() {
    if (this.props.match.params.smsCampaignId) {
      this.props.startLoading()

      axios
        .get(this.loadUrl())
        .then((res) => {
          this.setSmsCampaignAndMainTitle(res.data)
        })
        .catch((error) => {
          this.props.setFlash(`${error} (${this.loadUrl()})`)
        })
        .then(this.props.stopLoading)
    } else {
      this.setMainTitle()
    }
  }

  setSmsCampaignAndMainTitle(sms_campaign) {
    this.setSmsCampaign(sms_campaign)
    this.setMainTitle()
  }

  setSmsCampaign(sms_campaign) {
    this.setState({
      id: sms_campaign.id,
      name: sms_campaign.name || '',
      text: sms_campaign.text || '',
      include_subscribe_info: sms_campaign.include_subscribe_info || false,
      start_date: sms_campaign.start_date || '',
      end_date: sms_campaign.end_date || ''
    })
  }

  setMainTitle() {
    this.props.setMainTitle(!!this.state.id ? `Edit sms campaign ${this.state.name}` : 'New sms campaign')
  }

  setStateAfterSave(data) {
    this.setState({
      id: data.id,
      errors: {}
    })
  }

  data() {
    return { sms_campaign: this.state, format: 'json' }
  }

  loadUrl() {
    return Routes.admin_sms_campaign_path(window.tenant, this.props.match.params.smsCampaignId, { format: 'json' })
  }

  url() {
    return this.state.id
      ? Routes.admin_sms_campaign_path(window.tenant, this.state.id)
      : Routes.admin_sms_campaigns_path(window.tenant)
  }

  method() {
    return this.state.id ? 'patch' : 'post'
  }

  submit(e) {
    e.preventDefault()
    this.props.startLoading()
    axios({
      method: this.method(),
      url: this.url(),
      data: this.data()
    })
      .then(this.handleSubmitSuccess.bind(this))
      .catch(this.handleSubmitError.bind(this))
      .then(this.props.stopLoading())
  }

  handleSubmitSuccess(response) {
    this.setStateAfterSave(response.data)
    this.props.setFlash('Sms Campaign saved successfully!')
    this.props.history.push(Routes.edit_admin_sms_campaigns_path(window.tenant, response.data.id))
    this.setMainTitle()
  }

  handleSubmitError(error) {
    this.setState(extractDataErrors(error))
    this.props.setFlash(`${error} (${this.url()})`)
  }

  handleChange(e, value) {
    this.setState({ [value]: e.target.value })
  }

  handleDateChange(field, value) {
    this.setState({ [field]: moment(value).format('DD-MM-YYYY') })
  }

  handleCheckboxChange(field) {
    this.setState({ [field]: !this.state[field] })
  }

  renderIncludeSubscribeInfoCheckbox() {
    return (
      <div className='w-100 d-inline-block'>
        <FormControlLabel
          label='Include subscribe info [NOT AVAILABLE YET]'
          control={
            <Checkbox
              id='include-subscribe-info'
              checked={this.state.include_subscribe_info}
              onChange={this.handleCheckboxChange.bind(this, 'include_subscribe_info')}
              disabled
            />
          }
        />
      </div>
    )
  }

  renderForm() {
    const { start_date, end_date } = this.state

    return (
      <form noValidate autoComplete='off' onSubmit={this.submit.bind(this)}>
        <FunTextField field='name' state={this.state} handleChange={this.handleChange.bind(this)} />
        <FunTextField field='text' state={this.state} handleChange={this.handleChange.bind(this)} />
        <div>
          <MaterialDatePicker
            classNames='w-50'
            fieldName='start_date'
            label='Start date'
            key={`start-date-${start_date}`}
            value={start_date || ''}
            onDateChange={this.handleDateChange.bind(this, 'start_date')}
            position='bottom-right-position'
          />
          <MaterialDatePicker
            classNames='w-50'
            key={`end-date-${end_date}`}
            fieldName='end_date'
            label='End date'
            value={end_date || ''}
            onDateChange={this.handleDateChange.bind(this, 'end_date')}
          />
        </div>
        {this.renderIncludeSubscribeInfoCheckbox()}
        <SaveButton />
        <CancelButton to={window.Routes.admin_sms_campaigns_path(window.tenant)} />
      </form>
    )
  }

  render() {
    return <GridWrapper>{this.renderForm()}</GridWrapper>
  }
}

const mapStateToProps = (state) => {
  return {
    mainTitle: state.mainTitle,
    sms_campaigns: state.resources
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMainTitle: (mainTitle) => dispatch(setMainTitle(mainTitle)),
    setFlash: (flash) => dispatch(setFlash(flash)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const SmsCampaignsForm = connect(mapStateToProps, mapDispatchToProps)(SmsCampaignsFormClass)

export default SmsCampaignsForm
