import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Assignment from '@material-ui/icons/Assignment'
import DeleteForever from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Icon from '@material-ui/core/Icon'
import Collapse from '@material-ui/core/Collapse'
import { withStyles } from '@material-ui/core/styles'

import EndOfDayReport from './EndOfDayReport'

const styles = {
  idTableCell: {
    padding: '4px 4px 4px 16px !important'
  },
  formTableRow: {
    height: 'auto'
  },
  formTableCellCollapsed: {
    border: 'none'
  },
  formTableCell: {
    padding: '0 !important'
  },
  invalidRow: {
    backgroundColor: '#ffb09c'
  }
}

class EndOfDayReportsTableRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = { formClosed: true }
  }

  stockTransferUrl(carWarehoueId, stockTransferId) {
    return Routes.inventory_warehouse_stock_transfer_path(tenant, depoId, carWarehoueId, stockTransferId)
  }

  endOfDayReportShowUrl(endOfDayReportId) {
    return Routes.show_end_of_day_reports_path(tenant, depoId, endOfDayReportId)
  }

  toggleForm(e) {
    this.setState({ formClosed: !this.state.formClosed })
  }

  planEventExists() {
    return Boolean(this.props.endOfDayReport.plan_event_id)
  }

  renderForm() {
    const {
      classes: { formTableCell, formTableRow, formTableCellCollapsed },
      endOfDayReport: { status },
      endOfDayReport
    } = this.props
    const className = `${formTableCell} ${
      this.state.formClosed && formTableCellCollapsed
    } status-${status} eod-table-row-expanded`
    return (
      <TableRow className={formTableRow}>
        <TableCell colSpan={8} className={className}>
          <Collapse in={!this.state.formClosed} timeout='auto' unmountOnExit>
            <EndOfDayReport endOfDayReport={endOfDayReport} planEventExists={this.planEventExists.bind(this)} />
          </Collapse>
        </TableCell>
      </TableRow>
    )
  }

  renderInvalidWallmobUserModalIcon() {
    if (!this.props.endOfDayReport.wallmob_user_defined) {
      return (
        <Icon style={{ verticalAlign: 'middle' }} className='eod-warning-tooltip'>
          <Tooltip title='Current Driver does not have assigned wallmob user!'>
            <Icon color='secondary'>warning</Icon>
          </Tooltip>
        </Icon>
      )
    }
  }

  renderOrdersViewLink() {
    if (!this.planEventExists()) return
    const {
      endOfDayReport,
      endOfDayReport: { id }
    } = this.props
    const className = `mdl-button mdl-js-button mdl-button--icon eod-${id}-edit-path`
    return (
      <Link
        className={className}
        to={{
          pathname: this.endOfDayReportShowUrl(id),
          state: { endOfDayReport: endOfDayReport }
        }}
      >
        <Assignment />
      </Link>
    )
  }

  renderTransferLink(stock_transfer_id, car_warehouse_id) {
    if (stock_transfer_id && car_warehouse_id) {
      return (
        <Link target='_blank' to={this.stockTransferUrl(car_warehouse_id, stock_transfer_id)}>
          #{stock_transfer_id}
        </Link>
      )
    }
  }

  handleDestruction(e) {
    e.stopPropagation()
    if (!confirm('Are you sure you want destroy this EOD?')) return
    this.props.startLoading()
    this.setState({ destructionInProgress: true })
    axios
      .delete(Routes.end_of_day_report_path(tenant, depoId, this.props.endOfDayReport.id, { format: 'json' }))
      .then((res) => {
        this.props.destroyRow(this.props.endOfDayReport)
        this.props.stopLoading()
      })
      .catch((err) => {
        this.props.setFlash(`Error happened while trying to destroy row ${err}`)
        this.props.stopLoading()
      })
  }

  renderDestroyButton() {
    const {
      endOfDayReport: { id, is_approved }
    } = this.props
    if (is_approved && isHjemis) return
    return (
      <IconButton
        className={`delete-${id}`}
        disabled={this.state.destructionInProgress}
        onClick={this.handleDestruction.bind(this)}
      >
        <DeleteForever />
      </IconButton>
    )
  }

  renderActions() {
    return (
      <React.Fragment>
        <IconButton onClick={this.toggleForm.bind(this)}>
          {this.state.formClosed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </IconButton>
        {this.renderOrdersViewLink()}
        {this.renderInvalidWallmobUserModalIcon()}
        {this.renderDestroyButton()}
      </React.Fragment>
    )
  }

  render() {
    const { formClosed } = this.state
    const {
      endOfDayReport: {
        id,
        status,
        plan_event_date,
        route_name,
        car_internal_number,
        user_full_name,
        final_total,
        processed_by_at,
        stock_transfer_id,
        car_warehouse_id,
        invalid
      },
      classes: { formTableCellCollapsed, idTableCell, invalidRow }
    } = this.props
    const cellClass = formClosed ? '' : formTableCellCollapsed
    const statusClass = `status-${status} ${idTableCell}`
    const invalidClass = invalid ? invalidRow : ''

    return (
      <TableBody>
        <TableRow
          id={`table-row-${id}`}
          className={invalidClass}
          hover={formClosed}
          onClick={this.toggleForm.bind(this)}
        >
          <TableCell className={`${cellClass} ${statusClass}`}>{plan_event_date}</TableCell>
          <TableCell className={cellClass}>{route_name}</TableCell>
          <TableCell className={cellClass}>{car_internal_number}</TableCell>
          <TableCell className={cellClass}>{user_full_name}</TableCell>
          <TableCell className={cellClass}>{this.planEventExists() && final_total}</TableCell>
          <TableCell className={cellClass}>{status.startsWith('approved') ? processed_by_at : ''}</TableCell>
          <TableCell className={cellClass}>{this.renderTransferLink(stock_transfer_id, car_warehouse_id)}</TableCell>
          <TableCell className={cellClass}>{this.renderActions()}</TableCell>
        </TableRow>
        {this.renderForm()}
      </TableBody>
    )
  }
}

export default withStyles(styles)(EndOfDayReportsTableRow)
