import React from 'react'
import axios from 'axios'
import FunTextField from '../../forms/FunTextField'
import SmsTemplate from '../../../models/SmsTemplate'
import SaveButton from '../../shared/SaveButton'
import CancelButton from '../../shared/CancelButton'
import extractDataErrors from '../../support/extractDataErrors'
import Typography from '@material-ui/core/Typography'
import GridWrapper from '../../shared/GridWrapper'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import FlashNotification from '../../shared/FlashNotification'

class SmsTemplatesForm extends React.Component {
  state = { ...SmsTemplate(), title: '', loading: false, message: null }

  componentDidMount() {
    const { id } = this.props.match.params
    this.startLoading()
    if (id) {
      axios.get(Routes.admin_sms_template_path(window.tenant, id, { format: 'json' })).then((res) => {
        this.setState({
          ...res.data,
          loading: false,
          title: `Edit ${res.data.name}`
        })
      })
    } else {
      this.setState({ title: 'New SMS template', loading: false })
    }
  }

  startLoading() {
    this.setState({ loading: true })
  }

  stopLoading() {
    this.setState({ loading: false })
  }

  url() {
    return this.state.id
      ? Routes.admin_sms_template_path(window.tenant, this.state.id)
      : Routes.admin_sms_templates_path(window.tenant)
  }

  method() {
    return this.state.id ? 'patch' : 'post'
  }

  data() {
    return { sms_template: this.state, format: 'json' }
  }

  handleSubmitSuccess(response) {
    this.setState({
      ...response.data,
      errors: {},
      message: 'SMS template succesfuly updated',
      title: `Edit ${response.data.name}`
    })
    this.stopLoading()
  }

  handleSubmitError(error) {
    this.setState(extractDataErrors(error))
    this.stopLoading()
  }

  handleChange(e, field) {
    this.setState({ [field]: e.target.value })
  }

  submit(e) {
    this.startLoading()
    e.preventDefault()
    axios({
      method: this.method(),
      url: this.url(),
      data: this.data()
    })
      .then(this.handleSubmitSuccess.bind(this))
      .catch(this.handleSubmitError.bind(this))
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading} />
  }

  renderFlashNotification() {
    return <FlashNotification message={this.state.message} />
  }

  render() {
    return (
      <GridWrapper>
        {this.renderLoading()}
        {this.renderFlashNotification()}
        <form noValidate autoComplete='off' onSubmit={this.submit.bind(this)}>
          <Typography variant='h5'>{this.state.title}</Typography>
          <FunTextField field='name' state={this.state} handleChange={this.handleChange.bind(this)} />
          <FunTextField multiline field='text' state={this.state} handleChange={this.handleChange.bind(this)} />
          <SaveButton />
          <CancelButton to={Routes.admin_sms_templates_path(window.tenant)} />
        </form>
      </GridWrapper>
    )
  }
}

export default SmsTemplatesForm
