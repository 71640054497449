import React from 'react'
import { Link } from 'react-router-dom'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Tooltip from '@material-ui/core/Tooltip'
import Info from '@material-ui/icons/Info'
import pick from 'lodash/pick'

class OrderLineItemsTableRow extends React.Component {
  validateProductPresence(orderLineItem) {
    if (!orderLineItem.inventory_presence) return 'product-not-present-in-inventory'
  }

  renderTooltip(key, productNotPresent) {
    if (key == 'wallmob_sku' && productNotPresent) {
      return (
        <Tooltip title='Current item is not present in inventory products list!'>
          <Info className='product-not-present-in-inventory-tooltip' />
        </Tooltip>
      )
    }
  }

  renderStockTransferLink() {
    const {
      orderLineItem: { stock_transfer_id },
      warehouseId
    } = this.props
    if (!stock_transfer_id || !warehouseId) return
    return (
      <Link
        target='_blank'
        key={`${stock_transfer_id}-${warehouseId}`}
        to={Routes.inventory_warehouse_stock_transfer_path(tenant, depoId, warehouseId, stock_transfer_id)}
      >
        #{stock_transfer_id}
      </Link>
    )
  }

  render() {
    const { orderLineItem } = this.props
    if (!orderLineItem.product_name) return null
    const productNotPresent = this.validateProductPresence(orderLineItem)
    const validKeys = ['category_name', 'product_name', 'wallmob_sku', 'quantity', 'total_line_amount', 'discount']
    return (
      <TableBody>
        <TableRow>
          {Object.keys(pick(orderLineItem, validKeys)).map((key, index) => (
            <TableCell key={index} className={key == 'wallmob_sku' ? productNotPresent : ''}>
              {orderLineItem[key]}
              {this.renderTooltip(key, productNotPresent)}
            </TableCell>
          ))}
          <TableCell>{this.renderStockTransferLink()}</TableCell>
        </TableRow>
      </TableBody>
    )
  }
}

export default OrderLineItemsTableRow
