import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { withStyles } from '@material-ui/core/styles'

const styles = {}

const CarsTableHead = ({ classes }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>#</TableCell>
        <TableCell>Internal Number</TableCell>
        <TableCell>Mobile Number</TableCell>
        <TableCell>Plate Number</TableCell>
        <TableCell>Axapta code</TableCell>
        <TableCell>Depo</TableCell>
        <TableCell>MapOn Id</TableCell>
        <TableCell>Latest location</TableCell>
        <TableCell>Location updated at</TableCell>
        <TableCell>Last Synced at</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default withStyles(styles)(CarsTableHead)
