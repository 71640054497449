import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import FunTextField from '../../forms/FunTextField'
import StopType from '../../../models/StopType'
import SaveButton from '../../shared/SaveButton'
import CancelButton from '../../shared/CancelButton'
import GridWrapper from '../../shared/GridWrapper'
import { setMainTitle, startLoading, stopLoading, setFlash } from '../../../files/actions/index'
import extractDataErrors from '../../support/extractDataErrors'
import FunTimeInput from '../../forms/FunTimeInput'

class StopTypesFormClass extends React.Component {
  state = { ...StopType() }

  componentDidMount() {
    const { stopTypeId } = this.props.match.params
    if (stopTypeId) {
      axios
        .get(
          Routes.admin_stop_type_path(window.tenant, stopTypeId, {
            format: 'json'
          })
        )
        .then((res) => {
          this.setState({ ...res.data })
          this.props.setMainTitle(res.data.stop_type)
        })
    } else {
      this.props.setMainTitle('New stop type')
    }
    this.props.stopLoading()
  }

  url() {
    return this.state.id
      ? Routes.admin_stop_type_path(window.tenant, this.state.id)
      : Routes.admin_stop_types_path(window.tenant)
  }

  method() {
    return this.state.id ? 'patch' : 'post'
  }

  data() {
    return { stop_type: this.state, format: 'json' }
  }

  handleSubmitSuccess(response) {
    this.setState({ ...response.data, errors: {} })
    this.props.setMainTitle(response.data.stop_type)
  }

  handleSubmitError(error) {
    this.setState(extractDataErrors(error))
    this.props.setFlash(`${extractDataErrors(error).full_errors} (${this.url()})`)
  }

  handleChange(e, field) {
    this.setState({ [field]: e.target.value })
  }

  handleTimeChange(field, value) {
    this.setState({ [field]: value })
  }

  submit(e) {
    e.preventDefault()
    this.props.startLoading()
    axios({
      method: this.method(),
      url: this.url(),
      data: this.data()
    })
      .then(this.handleSubmitSuccess.bind(this))
      .catch(this.handleSubmitError.bind(this))
      .then(this.props.stopLoading)
  }

  renderTimeInput() {
    const settings = {
      value: this.state.wait_time
    }

    return (
      <FunTimeInput
        id='wait_time'
        label='Wait time'
        settings={settings}
        onChange={this.handleTimeChange.bind(this, 'wait_time')}
        containerStyles={{ width: '100%', marginBottom: 5 }}
      />
    )
  }

  render() {
    return (
      <GridWrapper>
        <form noValidate autoComplete='off' onSubmit={this.submit.bind(this)}>
          <FunTextField field='stop_type' state={this.state} handleChange={this.handleChange.bind(this)} />
          {this.renderTimeInput()}
          <SaveButton />
          <CancelButton to={Routes.admin_stop_types_path(window.tenant)} />
        </form>
      </GridWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    mainTitle: state.mainTitle,
    depos: state.resources
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMainTitle: (mainTitle) => dispatch(setMainTitle(mainTitle)),
    setFlash: (flash) => dispatch(setFlash(flash)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const StopTypesForm = connect(mapStateToProps, mapDispatchToProps)(StopTypesFormClass)

export default StopTypesForm
