import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

class LoadingWithoutRedux extends React.Component {
  render() {
    if (!this.props.loading) return null
    const defaultStyles = {
      position: 'absolute',
      zIndex: '1301',
      marginLeft: '50%',
      top: 'calc(50% - 20px)'
    }
    return (
      <CircularProgress
        className='loading-spinner'
        size={60}
        style={{ ...defaultStyles, ...this.props.additionalStyles }}
      />
    )
  }
}

export default LoadingWithoutRedux
