import { PLAN_EVENTS_FOR_CALENDAR, LOAD_ORDER_TRANSACTIONS, LOAD_PLAN_EVENT } from '../constants/action-types'

import groupBy from 'lodash/groupBy'
import uniqBy from 'lodash/uniqBy'
import merge from 'lodash/merge'
import each from 'lodash/each'
import arrayMove from '../../components/support/arrayMove'

const initialState = {
  planEventsGrouped: {},
  orderTransactions: [],
  planEvent: {}
}

const planEventsReducer = (state = initialState, action) => {
  const payload = action.payload

  let planEvents
  switch (action.type) {
    case PLAN_EVENTS_FOR_CALENDAR:
      // All this is needed just because we don't use react keys to render calendar
      // so we need to handle manually new records or replace existing ones
      const groupedPlanEvent = groupBy(payload, 'car_internal_number')
      const licenseNumbers = Object.keys(groupedPlanEvent)
      const uniqByDatePlanEvents = {}
      each(licenseNumbers, (license) => {
        uniqByDatePlanEvents[license] = uniqBy(
          groupedPlanEvent[license].sort((a, b) => b.id - a.id),
          (pe) => pe.date
        )
      })

      return {
        ...state,
        planEvents: payload,
        planEventsGrouped: uniqByDatePlanEvents
      }
    case LOAD_ORDER_TRANSACTIONS:
      return { ...state, orderTransactions: action.payload }
    case LOAD_PLAN_EVENT:
      return { ...state, planEvent: action.payload }
    default:
      return state
  }
}

export default planEventsReducer
