import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    padding: '4px'
  }
}

class PlanEventCustomersTableHead extends React.Component {
  state = { checked: false }

  handleCheckboxChange() {
    this.setState({ checked: !this.state.checked }, () => this.props.checkAll(this.state.checked))
  }

  render() {
    const { classes } = this.props

    return (
      <TableHead>
        <TableRow>
          <TableCell className={classes.root}>
            <Checkbox
              className='check-all'
              checked={this.state.checked}
              onChange={this.handleCheckboxChange.bind(this)}
              value='checked'
            />
          </TableCell>
          <TableCell className={classes.root}>#</TableCell>
          <TableCell className={classes.root}>Customer</TableCell>
          <TableCell className={classes.root}>Customer Address</TableCell>
          <TableCell className={classes.root}>Route</TableCell>
          <TableCell className={classes.root}>Route Point</TableCell>
          <TableCell className={classes.root}>SMS sent at</TableCell>
          <TableCell className={classes.root}>Car stopped at</TableCell>
          <TableCell className={classes.root}>Public google ETA</TableCell>
          <TableCell className={classes.root}>SMS link</TableCell>
        </TableRow>
      </TableHead>
    )
  }
}

export default withStyles(styles)(PlanEventCustomersTableHead)
