import React from 'react'
import BrowserDetection from 'react-browser-detection'
import Info from '@material-ui/icons/Info'

const BrowserCheck = () => {
  const ieErrorMessage = (browser) => {
    const names = {
      edge: 'Microsoft Edge',
      ie: 'Internet Explorer'
    }

    return (
      <div className='alert alert-warning'>
        <Info />
        &nbsp;We have detected that you are using unsupported browser - <strong>{names[browser]}</strong>
      </div>
    )
  }

  const browserHandler = {
    ie: ieErrorMessage,
    edge: ieErrorMessage,
    default: (browser) => null
  }

  return <BrowserDetection>{browserHandler}</BrowserDetection>
}

export default BrowserCheck
