import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Tooltip from '@material-ui/core/Tooltip'
import Icon from '@material-ui/core/Icon'
import DeleteForever from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'
import ModeEdit from '@material-ui/icons/Edit'
import Check from '@material-ui/icons/Check'
import DateTimePicker from '../shared/DateTimePicker'
import extractDataErrors from '../support/extractDataErrors'
import Modal from '../shared/Modal'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import IntegrationReactSelect from '../forms/IntegrationReactSelect'

const rowStyle = {
  marginBottom: 10,
  display: 'flex',
  justifyContent: 'space-between',
  height: 30,
  alignItems: 'center'
}

class UnmappedEndOfDayReportsTableRow extends React.Component {
  state = {
    destructionInProgress: false,
    errors: {},
    editMode: false,
    edited_start_at: null,
    edited_end_at: null,
    approvalModalOpen: false,
    selectedCarId: '',
    selectedUserId: '',
    selectedRouteId: ''
  }

  componentDidMount() {
    const {
      endOfDayReport: { edited_start_at, edited_end_at, start_at, end_at }
    } = this.props
    this.setState({
      edited_start_at: edited_start_at || start_at,
      edited_end_at: edited_end_at || end_at
    })
  }

  openEditMode() {
    this.setState({ editMode: true })
  }

  handleDateChange(field, value) {
    this.setState({ [field]: value })
  }

  data() {
    const { edited_start_at, edited_end_at } = this.state
    return {
      format: 'json',
      end_of_day_report: {
        edited_start_at,
        edited_end_at
      }
    }
  }

  submitChanges() {
    this.props.startLoading()
    const {
      endOfDayReport: { id }
    } = this.props
    axios({
      method: 'patch',
      url: Routes.end_of_day_report_path(tenant, depoId, id, {
        format: 'json'
      }),
      data: this.data()
    })
      .then((res) => {
        this.setState({ editMode: false, errors: {} }, () => {
          const { plan_event_id, plan_event_title } = res.data
          if (plan_event_id) {
            this.props.setFlash(`End of day report linked with with planned route: '${plan_event_title}'`)
          } else {
            this.props.setFlash('Row successfully updated!')
          }
          this.props.stopLoading()
        })
      })
      .catch((error) => {
        this.setState({
          errors: extractDataErrors(error) && extractDataErrors(error).errors
        })
        this.props.setFlash(`${error}`)
        this.props.stopLoading()
      })
  }

  renderTooltipIcon(date) {
    const dateObject = moment(date, dateFormat)
    return (
      <Icon style={{ verticalAlign: 'middle' }} className='eod-warning-tooltip'>
        <Tooltip title='Current End of day report does not have plan event!'>
          <Link
            to={`${Routes.plan_event_templates_path(tenant, depoId)}/${dateObject.weekYear()}-${dateObject.week()}`}
            target='_blank'
          >
            <Icon color='secondary'>warning</Icon>
          </Link>
        </Tooltip>
      </Icon>
    )
  }

  renderEditOrShow(field) {
    const fieldValue = this.state[field]
    if (this.state.editMode) {
      return (
        <DateTimePicker
          handleChange={this.handleDateChange.bind(this)}
          value={fieldValue}
          state={this.state}
          field={field}
          className={`${field}_edit_input_field`}
        />
      )
    } else {
      return fieldValue
    }
  }

  renderApproveButton() {
    if (tenant === 'hjem-is') {
      return (
        <IconButton onClick={this.openApprovalModal.bind(this)}>
          <Check />
        </IconButton>
      )
    }
  }

  renderActions(start_at) {
    const { editMode } = this.state
    const icon = editMode ? <Check className='submit-icon' /> : <ModeEdit className='edit-icon' />
    const action = editMode ? this.submitChanges.bind(this) : this.openEditMode.bind(this)

    return (
      <div style={{ display: 'flex' }}>
        {this.renderApproveButton()}
        <IconButton onClick={action}>{icon}</IconButton>
        <IconButton>{this.renderTooltipIcon(start_at)}</IconButton>
        {this.renderDestroyButton()}
      </div>
    )
  }

  openApprovalModal = () => this.setState({ approvalModalOpen: true })
  closeApprovalModal = () => this.setState({ approvalModalOpen: false })
  handleChange = (field, e) => this.setState({ [field]: e.target.value })

  renderApprovalModal() {
    const {
      endOfDayReport: { user_full_name, start_at, cash_registers_name }
    } = this.props

    return (
      <Modal
        style={{ padding: 25, width: 450 }}
        open={this.state.approvalModalOpen}
        close={this.closeApprovalModal.bind(this)}
        title='Approve EOD'
      >
        <React.Fragment>
          <div style={rowStyle}>
            <b>Date:</b> {moment(start_at, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY')}
          </div>
          <div style={rowStyle}>
            <b>Route:</b>
            {this.renderRouteSelect()}
          </div>
          <div style={rowStyle}>
            <b>User:</b>
            <div style={{ display: 'flex' }}>
              {this.renderUserSelect()} <span style={{ marginLeft: 5, paddingTop: 20 }}>({user_full_name})</span>
            </div>
          </div>
          <div style={rowStyle}>
            <b>Cash register:</b>
            <div style={{ display: 'flex' }}>
              {this.renderCarSelect()} <span style={{ marginLeft: 5, paddingTop: 20 }}>({cash_registers_name})</span>
            </div>
          </div>
          <div style={{ marginTop: 10, textAlign: 'center' }}>
            <Button onClick={this.closeApprovalModal.bind(this)}>Cancel</Button>
            <Button color='primary' variant='contained' onClick={this.approve.bind(this)}>
              Approve
            </Button>
          </div>
        </React.Fragment>
      </Modal>
    )
  }

  usersOptions(input, callback) {
    callback(null, {
      options: this.props.users.map((user) => ({
        value: user.id,
        label: user.full_name
      })),
      complete: true
    })
  }

  routesOptions(input, callback) {
    callback(null, {
      options: this.props.routes.map((route) => ({
        value: route.id,
        label: route.name
      })),
      complete: true
    })
  }

  carOptions(input, callback) {
    callback(null, {
      options: this.props.cars.map((car) => ({
        value: car.id,
        label: car.internal_number
      })),
      complete: true
    })
  }

  selectDefaultValue(id, collection, labelName) {
    if (id) {
      const selectedObject = collection.find((object) => object.id === id)
      return { value: selectedObject.id, label: selectedObject[labelName] }
    }
  }

  handleRouteChange(selectedRouteId) {
    this.setState({ selectedRouteId: selectedRouteId.value })
  }

  handleUserChange(selectedUserId) {
    this.setState({ selectedUserId: selectedUserId.value })
  }

  handleCarChange(selectedCarId) {
    this.setState({ selectedCarId: selectedCarId.value })
  }

  renderUserSelect() {
    const { selectedUserId } = this.state
    return (
      <div>
        <IntegrationReactSelect
          name='user'
          placeholder='User'
          value={selectedUserId}
          loadOptions={this.usersOptions.bind(this)}
          defaultOptions={this.selectDefaultValue(selectedUserId, this.props.users, 'full_name')}
          onChange={this.handleUserChange.bind(this)}
          autoload={false}
          cache={false}
          specificWidth={180}
        />
      </div>
    )
  }

  renderRouteSelect() {
    const { selectedRouteId } = this.state
    return (
      <div>
        <IntegrationReactSelect
          name='route'
          placeholder='Route'
          value={selectedRouteId}
          loadOptions={this.routesOptions.bind(this)}
          defaultOptions={this.selectDefaultValue(selectedRouteId, this.props.routes, 'name')}
          onChange={this.handleRouteChange.bind(this)}
          autoload={false}
          cache={false}
          specificWidth={180}
        />
      </div>
    )
  }

  renderCarSelect() {
    const { selectedCarId } = this.state
    return (
      <div>
        <IntegrationReactSelect
          name='car'
          placeholder='Car'
          value={selectedCarId}
          loadOptions={this.carOptions.bind(this)}
          defaultOptions={this.selectDefaultValue(selectedCarId, this.props.cars, 'internal_number')}
          onChange={this.handleCarChange.bind(this)}
          autoload={false}
          cache={false}
          specificWidth={180}
        />
      </div>
    )
  }

  approve(e) {
    e.stopPropagation()
    const {
      endOfDayReport: { id, start_at, user_id }
    } = this.props
    const { selectedCarId, selectedUserId, selectedRouteId } = this.state
    if (!selectedCarId) {
      this.props.setFlash('You must choose car before approving EOD')
      return
    }

    this.props.startLoading()
    const data = {
      car_id: selectedCarId,
      date: moment(start_at, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY'),
      user_id: selectedUserId || user_id,
      route_id: selectedRouteId
    }

    axios
      .put(
        Routes.approve_unmapped_end_of_day_report_end_of_day_report_path(tenant, depoId, id, { format: 'json' }),
        data
      )
      .then((res) => {
        this.props.removeRow(this.props.endOfDayReport)
        this.props.setFlash('End of day report has been succesfully approved and attached to an event')
        this.props.stopLoading()
      })
      .catch((error) => {
        this.setState({
          errors: extractDataErrors(error) && extractDataErrors(error).errors
        })
        this.props.setFlash(`${error}`)
        this.props.stopLoading()
      })
  }

  handleDestruction(e) {
    e.stopPropagation()
    this.setState({ destructionInProgress: true })
    axios
      .delete(Routes.end_of_day_report_path(tenant, depoId, this.props.endOfDayReport.id, { format: 'json' }))
      .then((res) => {
        this.props.destroyRow(this.props.endOfDayReport)
        this.props.stopLoading()
      })
      .catch((err) => {
        this.props.stopLoading()
        this.props.setFlash(`Error happened while trying to destroy row ${err}`)
      })
  }

  renderDestroyButton() {
    return (
      <IconButton disabled={this.state.destructionInProgress} onClick={this.handleDestruction.bind(this)}>
        <DeleteForever />
      </IconButton>
    )
  }

  render() {
    const {
      endOfDayReport: {
        edited_start_at,
        edited_end_at,
        user_full_name,
        final_total,
        start_at,
        end_at,
        cash_registers_name
      }
    } = this.props

    return (
      <TableBody>
        {this.renderApprovalModal()}
        <TableRow className='unmapped-end-of-day-row'>
          <TableCell>{this.renderEditOrShow('edited_start_at')}</TableCell>
          <TableCell>{this.renderEditOrShow('edited_end_at')}</TableCell>
          <TableCell>{user_full_name}</TableCell>
          <TableCell>{cash_registers_name}</TableCell>
          <TableCell>{final_total}</TableCell>
          <TableCell>{this.renderActions(start_at)}</TableCell>
        </TableRow>
      </TableBody>
    )
  }
}

export default UnmappedEndOfDayReportsTableRow
