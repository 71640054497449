const extractDataErrors = (error) => {
  let data = (error.response || {})['data']

  if (typeof data === 'string' || data === undefined || data === null) {
    data = { errors: {} }
  }

  return { errors: data.errors || {}, full_errors: data.full_errors || {} }
}

export default extractDataErrors
