import {
  LOAD_RESOURCES,
  UPDATE_RESOURCE,
  LOAD_CARS,
  LOAD_USERS,
  LOAD_ROUTES,
  LOAD_DEPOS,
  LOAD_USER_HTTP_REQUESTS,
  DELETE_RESOURCE,
  LOAD_ROUTE,
  COPY_RESOURCE
} from '../constants/action-types'

import merge from 'lodash/merge'

const initialState = {
  resources: [],
  cars: [],
  users: [],
  routes: [],
  depos: [],
  userHttpRequests: [],
  route: {}
}

const resourcesReducer = (state = initialState, action) => {
  const payload = action.payload
  let newResources
  switch (action.type) {
    case LOAD_RESOURCES:
      return { ...state, resources: payload }
    case UPDATE_RESOURCE:
      const resources = [...state.resources]
      const resourceIndex = resources.findIndex(({ id }) => id == payload.id)
      if (resourceIndex == -1) {
        resources.unshift(payload)
      } else {
        resources[resourceIndex] = merge(resources[resourceIndex], payload)
      }
      return { ...state, resources: resources }
    case LOAD_CARS:
      return { ...state, cars: payload }
    case LOAD_USERS:
      return { ...state, users: payload }
    case LOAD_ROUTES:
      return { ...state, routes: payload }
    case LOAD_DEPOS:
      return { ...state, depos: payload }
    case LOAD_USER_HTTP_REQUESTS:
      return { ...state, userHttpRequests: payload }
    case DELETE_RESOURCE:
      newResources = [...state.resources]
      const index = newResources.findIndex(({ id }) => id == payload.id)

      if (index > -1) {
        newResources.splice(index, 1)
      }
      return { ...state, resources: newResources }
    case LOAD_ROUTE:
      return { ...state, route: payload }
    case COPY_RESOURCE:
      newResources = [...state.resources]
      const route = payload[0]
      const previousRoute = payload[1]
      const previousIndex = newResources.findIndex(({ id }) => id == previousRoute.id)
      // Push it right below copied route
      newResources.splice(previousIndex + 1, 0, route)

      return { ...state, resources: newResources }
    default:
      return state
  }
}

export default resourcesReducer
