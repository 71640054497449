import React from 'react'
import { Marker } from 'react-map-gl'

function RouteMapPoints(props) {
  const keys = Object.keys(props.placedPointsOnMap)
  return keys.map((key) => {
    return props.placedPointsOnMap[key][0].map((routePoint, index) => {
      if (routePoint && routePoint.point) {
        if (isIsbilen && props.useNewMapMarkers) {
          return (
            <Marker
              key={index}
              latitude={routePoint.point.coordinates[1]}
              longitude={routePoint.point.coordinates[0]}
              anchor='center'
            >
              <div
                style={{
                  backgroundColor: props.placedPointsOnMap[key][1],
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%'
                }}
              ></div>
            </Marker>
          )
        } else {
          return (
            <Marker
              key={index}
              latitude={routePoint.point.coordinates[1]}
              longitude={routePoint.point.coordinates[0]}
              anchor='center'
            >
              <div
                style={{
                  backgroundColor: props.placedPointsOnMap[key][1],
                  width: '22px',
                  height: '22px',
                  borderRadius: '50%',
                  position: 'relative',
                  border: 'solid white 1px',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <p
                  style={{
                    textAlign: 'center',
                    position: 'relative',
                    bottom: '2px',
                    color: 'white'
                  }}
                >
                  {index + 1}
                </p>
              </div>
            </Marker>
          )
        }
      }
    })
  })
}

export default RouteMapPoints
