import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import PlanEventCustomersFullDetailsRow from './PlanEventCustomersFullDetailsRow'
import FlashNotification from '../shared/FlashNotification'
import LoadingWithoutRedux from '../shared/LoadingWithoutRedux'

class PlanEventCustomersFullDetails extends React.Component {
  state = { customers: [], message: null }

  componentDidMount() {
    this.loadCustomers()
  }

  loadCustomers() {
    this.setState({ loading: true })
    axios
      .get(
        Routes.sms_customers_plan_event_path(window.tenant, window.depoId, this.props.match.params.planEventId, {
          format: 'json'
        })
      )
      .then((res) => {
        this.setState({ customers: res.data, loading: false })
      })
      .catch((err) => {
        this.setState({ message: `${err}`, loading: false })
      })
  }

  renderTableBody() {
    const { customers } = this.state

    return (
      <TableBody>
        {customers.map((customer) => {
          return <PlanEventCustomersFullDetailsRow key={customer.id} customer={customer} />
        })}
      </TableBody>
    )
  }

  renderRouteName() {
    if (this.state.customers.length == 0) return ''

    return this.state.customers[0].plan_event_route.name
  }

  render() {
    return (
      <div className='content-container'>
        <Typography variant='h5'>Full customer details for {this.renderRouteName()}</Typography>
        <Table className='resources-table'>
          <TableHead>
            <TableRow>
              <TableCell>Customer</TableCell>
              <TableCell>Customer Address</TableCell>
              <TableCell>Route Point</TableCell>
              <TableCell>SMS should send at</TableCell>
              <TableCell>SMS sent at</TableCell>
              <TableCell>Car stopped at</TableCell>
              <TableCell>Public google ETA</TableCell>
              <TableCell>Original google ETA</TableCell>
            </TableRow>
          </TableHead>
          {this.renderTableBody()}
        </Table>
        <FlashNotification message={this.state.message} clearErrors={() => this.setState({ full_errors: null })} />
        <LoadingWithoutRedux loading={this.state.loading} />
      </div>
    )
  }
}

export default PlanEventCustomersFullDetails
