import React from 'react'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import { FormControl } from '@material-ui/core'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'

import filter from 'lodash/filter'

class EndOfDayReportsCarsFilter extends React.Component {
  constructor(props) {
    super(props)
  }

  selectedCars() {
    return filter(this.props.cars, (car) => this.props.car_ids.indexOf(car.id) > -1)
      .map((car) => car.internal_number)
      .join(', ')
  }

  handleChange(e) {
    this.props.handleChange('car_ids', e.target.value)
  }

  render() {
    const { car_ids, cars, disabled } = this.props
    return (
      <FormControl style={{ verticalAlign: 'bottom' }}>
        <InputLabel htmlFor='car_ids'>Cars</InputLabel>
        <Select
          className='eod-cars-filter'
          multiple
          value={car_ids}
          fullWidth
          input={<Input id='car_ids' style={{ width: 200 }} />}
          renderValue={this.selectedCars.bind(this)}
          onChange={this.handleChange.bind(this)}
          disabled={disabled}
        >
          {cars.map((car) => {
            const checked = car_ids.indexOf(car.id) > -1
            return (
              <MenuItem key={`car-${car.id}`} value={car.id}>
                <Checkbox checked={checked} />
                <ListItemText primary={car.internal_number} />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }
}

export default EndOfDayReportsCarsFilter
