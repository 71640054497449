import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TablePagination from '@material-ui/core/TablePagination'

import { loadUserHttpRequests, setMainTitle, startLoading, stopLoading } from '../../../files/actions/index'
import UserHttpRequestsTableHead from './UserHttpRequestsTableHead'
import UserHttpRequestsTableRow from './UserHttpRequestsTableRow'

class UserHttpRequestsTable extends React.Component {
  state = { page: 0 }

  componentDidMount() {
    this.props.setMainTitle('Audit')
    this.fetchData()
  }

  async fetchData() {
    this.props.startLoading()

    const { data } = await axios.get(this.url())

    this.props.loadUserHttpRequests(data.user_http_requests)
    this.props.stopLoading()

    this.setState({ rowCount: data.user_http_request_count })
  }

  url() {
    return Routes.admin_user_http_requests_path(window.tenant, {
      page: this.state.page + 1,
      format: 'json'
    })
  }

  onChangePage(_event, page) {
    this.setState({ page }, this.fetchData.bind(this))
  }

  renderTableBody() {
    const rows = this.props.userHttpRequests.map((r) => <UserHttpRequestsTableRow userHttpRequest={r} key={r.id} />)

    return <TableBody>{rows}</TableBody>
  }

  render() {
    return (
      <div>
        <TablePagination
          component='div'
          rowsPerPage={50}
          rowsPerPageOptions={[50]}
          page={this.state.page}
          count={this.state.rowCount}
          onPageChange={this.onChangePage.bind(this)}
        />

        <Table className='resources-table'>
          <UserHttpRequestsTableHead />
          {this.renderTableBody()}
        </Table>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { userHttpRequests: state.userHttpRequests }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadUserHttpRequests: (data) => dispatch(loadUserHttpRequests(data)),
    setMainTitle: (title) => dispatch(setMainTitle(title)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserHttpRequestsTable)
