import React from 'react'
import { Provider } from 'react-redux'
import store from '../../../files/store'
import AdminDeposIndex from './AdminDeposIndex'
import ErrorCatcher from '../../shared/ErrorCatcher'

const AdminDeposIndexWithStore = (props) => {
  return (
    <Provider store={store}>
      <ErrorCatcher>
        <AdminDeposIndex access_token={props.access_token} />
      </ErrorCatcher>
    </Provider>
  )
}

export default AdminDeposIndexWithStore
