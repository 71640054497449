import React from 'react'
import { connect } from 'react-redux'
import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Checkbox from '@material-ui/core/Checkbox'
import axios from 'axios'
import moment from 'moment'
import { Link } from 'react-router-dom'
import DayPicker from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import closeCalendarOnClick from '../public/helpers/closeCalendarOnClick'
import Modal from '../shared/Modal'
import RoutePointsPrintTable from '../shared/RoutePointsPrintTable'
import TextField from '@material-ui/core/TextField'
import 'react-day-picker/lib/style.css'
import { formatDate, parseDate } from 'react-day-picker/moment'
import IntegrationReactSelect from '../forms/IntegrationReactSelect'
import PlanEventDestroyConfirmationDialog from './PlanEventDestroyConfirmationDialog'

import {
  startLoading,
  stopLoading,
  updateResource,
  setFlash,
  createPlanEvents,
  destroyPlanEvents,
  destroyPlanEventWithSeries,
  editPlanEvent
} from '../../files/actions/index'

const styles = {
  cell: {
    padding: '4px !important',
    border: 'solid 1px lightgray',
    width: `${99 / 7}%`,
    maxWidth: 1,
    fontSize: '0.8125rem'
  },
  cellWithData: {
    width: 'auto',
    padding: '4px !important',
    border: 'solid 1px lightgray',
    width: `${99 / 7}%`,
    backgroundColor: '#FFC'
  },
  emptyCell: {
    width: 'auto',
    padding: '4px !important',
    border: 'solid 1px lightgray',
    background: 'rgba(0, 0, 0, 0.07)',
    width: `${99 / 7}%`
  },
  action: {
    fontSize: 10,
    padding: '2px 8px',
    minHeight: 16,
    minWidth: 32
  },
  date: {
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'block',
    marginLeft: 2
  },
  flexbox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  datePickerContainer: {
    position: 'absolute',
    zIndex: 10,
    marginBottom: 10
  },
  datePickerContainerLeft: {
    marginLeft: -215
  },
  datePickerPointer: {
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderBottom: '8px solid #C3C3C3',
    marginLeft: 25,
    float: 'left'
  },
  datePickerPointerLeft: {
    float: 'right',
    marginRight: 25
  },
  datePicker: {
    background: 'white',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.3)',
    border: '1px solid lightgray',
    borderRadius: 3,
    marginLeft: 5,
    marginTop: 7
  },
  cellButton: {
    width: 24,
    height: 24,
    left: 2,
    bottom: 2
  },
  cellButtonIcon: {
    fontSize: 18
  },
  labelsContainer: {
    margin: 12
  },
  inputsContainer: {
    margin: 12,
    marginBottom: 30
  },
  formElementPadding: {
    padding: 1
  },
  topMargin: {
    marginTop: 25
  },
  recurringEventCheckbox: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 6
  },
  recurringEventLabel: {
    alignSelf: 'center',
    fontSize: 14
  },
  error: {
    position: 'absolute',
    color: '#AD2828',
    marginLeft: 50
  },
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  buttonsContainer: {
    width: 25,
    height: 32
  },
  centeredText: {
    textAlign: 'center'
  },
  actionsContainer: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row-reverse'
  }
}

class PlanEventTemplatesTableCellClass extends React.Component {
  constructor(props) {
    super(props)

    const { planEvent } = this.props
    const defaultDate = moment(this.props.date)

    defaultDate.add(defaultDate.utcOffset(), 'minutes')

    this.initialState = {
      selectedDays: [defaultDate],
      recurringEvent: false,
      recurrenceInterval: 14,
      recurringEventEndDate: defaultDate.toDate(),
      editing: 'show',
      modalOpen: false,
      start_at_hour: planEvent && planEvent.start_at_hour,
      start_at_min: planEvent && planEvent.start_at_min,
      planEventRoutePoints: [],
      destroyDialogOpen: false,
      hasEshopStops: false,
      driverInput: '',
      routeInput: ''
    }

    this.state = this.initialState
  }

  componentDidMount() {
    closeCalendarOnClick.bind(this)()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { planEvent } = nextProps

    this.setState({
      start_at_hour: planEvent && planEvent.start_at_hour,
      start_at_min: planEvent && planEvent.start_at_min
    })
  }

  unusedOptions(collection, key) {
    const takenIds = this.props.planEvents.map((pe) => pe[key])

    return collection.filter((option) => !takenIds.includes(option.value))
  }

  routesOptions(input, callback) {
    callback(this.unusedOptions(this.props.routes, 'route_id'))
  }

  usersOptions(input, callback) {
    callback(this.unusedOptions(this.props.users, 'user_id'))
  }

  handleRouteChange(routeId) {
    this.setState({ changed: true, routeId: routeId && routeId.value })
  }

  handleDriverChange(driverId) {
    this.setState({ changed: true, driverId: driverId && driverId.value })
  }

  onRecurringEventEndDateChange(date) {
    if (!date) return
    this.setState({ recurringEventEndDate: date })
  }

  onRecurrenceIntervalChange(e) {
    this.setState({ recurrenceInterval: e.target.value })
  }

  toggleRecurringEvent() {
    this.setState({ recurringEvent: !this.state.recurringEvent })
  }

  toggleModal() {
    this.setState({ modalOpen: !this.state.modalOpen })
  }

  showDatePicker() {
    this.setState({ datePickerVisible: true })
  }

  toggleDatePicker() {
    this.setState({ datePickerVisible: !this.state.datePickerVisible })
  }

  switchToShow() {
    this.setState({ editing: 'show' })
  }

  switchToEdit() {
    this.setState({ editing: 'edit' })
  }

  switchToStartAtEdit() {
    this.setState({ editing: 'startAtEdit' })
  }

  printRoutePoints = () => this.refs.routePointsPrintTable.startPrinting()

  renderRoutePointsPrintMarkup() {
    const { planEventRoutePoints } = this.state
    const printHeader = `${this.props.planEvent.date} ${this.props.planEvent.route_name}`
    return (
      <RoutePointsPrintTable
        routePoints={planEventRoutePoints}
        ref='routePointsPrintTable'
        header={printHeader}
        startLoading={this.props.startLoading}
        stopLoading={this.props.stopLoading}
      />
    )
  }

  fetchAndPrintPlanEvent(id) {
    axios
      .get(
        Routes.route_points_plan_event_path(tenant, depoId, id, {
          format: 'json'
        })
      )
      .then((res) => {
        this.setState({ planEventRoutePoints: res.data }, () => this.printRoutePoints())
      })
      .catch((error) => {
        this.props.setFlash(`${error}`)
      })
  }

  createPlanEvents() {
    const { routeId, driverId, recurringEvent, recurrenceInterval } = this.state
    const plan_event = {
      date: this.getSelectedDays(),
      route_id: routeId,
      user_id: driverId,
      car_id: this.props.car.id,
      recurrence_interval: recurringEvent ? recurrenceInterval : null
    }

    this.submit(
      axios.post(Routes.plan_events_path(tenant, depoId, { format: 'json' }), {
        plan_event
      }),
      this.props.createPlanEvents
    )
  }

  editPlanEvent() {
    const { id, route_id, user_id } = this.props.planEvent

    const plan_event = {
      route_id: this.state.routeId || route_id,
      user_id: this.state.driverId || user_id
    }

    this.submit(
      axios.patch(Routes.plan_event_path(tenant, depoId, id, { format: 'json' }), { plan_event }),
      this.props.editPlanEvent
    )
  }

  editStartAtPlanEvent() {
    const { id, start_at_hour, start_at_min } = this.props.planEvent

    const plan_event = {
      start_at_hour: this.state.start_at_hour || start_at_hour,
      start_at_min: this.state.start_at_min || start_at_min
    }

    this.submit(axios.patch(Routes.plan_event_path(tenant, depoId, id), { plan_event }), this.props.editPlanEvent)
  }

  async submit(request, callback) {
    this.setState({ saving: true })

    try {
      const { data } = await request

      callback(data)

      this.setState({ editing: 'show' })
    } catch ({ response }) {
      this.props.setFlash(response.data.error)
    }

    this.setState({ saving: false })
  }

  getSelectedDays() {
    const dateFormat = 'DD-MM-YYYY'
    const { recurringEvent, recurringEventEndDate, recurrenceInterval, selectedDays } = this.state

    if (recurringEvent) {
      const startDate = moment(this.props.date)
      const endDate = moment(recurringEventEndDate, dateFormat).add(12, 'hours')
      const dates = []

      startDate.add(startDate.utcOffset(), 'minutes')

      while (endDate.isSameOrAfter(startDate)) {
        dates.push(startDate.clone().format(dateFormat))
        startDate.add(recurrenceInterval, 'days')
      }

      return dates
    } else {
      return selectedDays
    }
  }

  onDayClick(dateString) {
    this.setState((state) => {
      const date = moment(dateString)

      if (state.selectedDays.some((d) => d.isSame(date, 'day'))) {
        return {
          ...state,
          selectedDays: state.selectedDays.filter((d) => !d.isSame(date, 'day'))
        }
      } else {
        return { ...state, selectedDays: state.selectedDays.concat([date]) }
      }
    })
  }

  destroyPlanEvent({ series = false }) {
    const { planEvent, destroyPlanEvent, destroyPlanEventWithSeries } = this.props

    if (!planEvent.can_be_deleted) {
      this.props.setFlash("You can't destory because, plan event has already started")
      this.setState(this.initialState)
      return
    }

    let url

    if (series) {
      url = Routes.destroy_with_series_plan_event_path(tenant, depoId, planEvent)
    } else {
      url = Routes.plan_event_path(tenant, depoId, planEvent)
    }

    this.setState({ destroying: true })

    axios
      .delete(url)
      .then(() => {
        series ? destroyPlanEventWithSeries(planEvent) : destroyPlanEvent(planEvent)
      })
      .catch((error) => {
        this.props.setFlash(`${error} (${url})`)
      })
      .then(() => {
        this.setState(this.initialState)
        this.setState({ destroying: false })
      })
  }

  renderSaveButton() {
    const { saving, changed, recurringEvent, recurringEventEndDate, recurrenceInterval } = this.state

    if (recurringEvent && (!recurringEventEndDate || !(Number.parseInt(recurrenceInterval) > 0))) {
      return null
    } else if (saving) {
      return <CircularProgress size={20} />
    } else if (changed) {
      return this.renderButton('SAVE', this.createPlanEvents)
    }
  }

  renderEditButton() {
    if (this.state.saving) {
      return <CircularProgress size={20} />
    } else {
      return this.renderButton('SAVE', this.editPlanEvent)
    }
  }

  toggleDestroyDialog() {
    axios
      .get(Routes.has_eshop_stops_plan_event_path(tenant, depoId, this.props.planEvent.id, { format: 'json' }))
      .then((res) => {
        this.setState({
          destroyDialogOpen: !this.state.destroyDialogOpen,
          hasEshopStops: res.data
        })
      })
      .catch((err) => {
        this.setState({
          destroyDialogOpen: !this.state.destroyDialogOpen,
          hasEshopStops: false
        })
      })
  }

  destroyPlanEventAndCloseDialog() {
    this.destroyPlanEvent({})
    this.setState({ destroyDialogOpen: !this.state.destroyDialogOpen })
  }

  destroyPlanEventSeriesAndCloseDialog() {
    this.destroyPlanEvent({ series: true })
    this.toggleDestroyDialog()
  }

  renderDestroyButton() {
    const { saving, destroying } = this.state

    if (!saving) {
      if (destroying) {
        return <CircularProgress size={20} />
      } else {
        return (
          <React.Fragment>
            {this.renderButton('DESTROY', this.toggleDestroyDialog, 'secondary')}
            <PlanEventDestroyConfirmationDialog
              planEvent={this.props.planEvent}
              open={this.state.destroyDialogOpen}
              hasEshopStops={this.state.hasEshopStops}
              close={this.toggleDestroyDialog.bind(this)}
              destroy={this.destroyPlanEventAndCloseDialog.bind(this)}
              destroySeries={this.destroyPlanEventSeriesAndCloseDialog.bind(this)}
            />
          </React.Fragment>
        )
      }
    }
  }

  renderButton(text, onClick, color = 'primary') {
    return (
      <Button className={this.props.classes.action} color={color} size='small' onClick={onClick.bind(this)}>
        {text}
      </Button>
    )
  }

  renderPlanEvent() {
    const {
      classes,
      daySpan,
      planEvent: { user_id, route_id }
    } = this.props
    const { car_not_moving } = this.props.planEvent
    const backgroundColor = user_id && route_id ? '#EAF4FE' : '#FFFBF0'
    const carNotMovingClass = car_not_moving ? 'car-not-moving' : ''

    return (
      <TableCell className={[classes.cell, carNotMovingClass].join(' ')} style={{ backgroundColor }} colSpan={daySpan}>
        {this.state.editing === 'startAtEdit' && this.renderStartAtForm()}
        {this.state.editing === 'edit' && this.renderPlanEventEditForm()}
        {this.state.editing === 'show' && this.renderPlanEventShow()}
      </TableCell>
    )
  }

  renderPlanEventEditForm() {
    const { driverId, routeId, saving } = this.state
    const { user_id, route_id } = this.props.planEvent

    const actions = (
      <div style={styles.actionsContainer}>
        {this.renderEditButton()}
        {!saving && this.renderButton('CANCEL', this.switchToShow, 'default')}
        {this.renderDestroyButton()}
      </div>
    )

    return this.renderPlanEventForm(routeId || route_id, driverId || user_id, actions, false)
  }

  renderStartAtForm() {
    const { saving, start_at_hour, start_at_min } = this.state
    return (
      <div>
        <TextField
          style={{ width: '25%' }}
          label='Hour'
          value={start_at_hour || ''}
          onChange={(e) => this.setState({ start_at_hour: e.target.value })}
        />
        <span>&nbsp;</span>
        <TextField
          style={{ width: '25%' }}
          label='Minutes'
          value={start_at_min || ''}
          onChange={(e) => this.setState({ start_at_min: e.target.value })}
        />
        <div style={styles.actionsContainer}>
          {!saving && this.renderButton('SAVE', this.editStartAtPlanEvent)}
          {!saving && this.renderButton('CANCEL', this.switchToShow, 'default')}
        </div>
      </div>
    )
  }

  renderPrintIcon(route_name, id) {
    if (!route_name) return

    return (
      <IconButton style={styles.cellButton} onClick={this.fetchAndPrintPlanEvent.bind(this, id)}>
        <Icon style={styles.cellButtonIcon}>print</Icon>
      </IconButton>
    )
  }

  renderActionButtons(start_at_hour, route_name, id) {
    const existingTimeColor = start_at_hour ? 'red' : ''
    return (
      <div style={styles.buttonsContainer} className='plan-event-table-cell-action-buttons'>
        <IconButton style={styles.cellButton} onClick={this.switchToEdit.bind(this)}>
          <Icon style={styles.cellButtonIcon}>edit</Icon>
        </IconButton>
        <IconButton style={styles.cellButton} onClick={this.switchToStartAtEdit.bind(this)}>
          <Icon style={{ ...styles.cellButtonIcon, color: existingTimeColor }}>alarm</Icon>
        </IconButton>
        {this.renderPrintIcon(route_name, id)}
        {this.renderSeriesInfoButton()}
      </div>
    )
  }

  renderB2bStopsCount() {
    if (isHjemis) {
      return (
        <React.Fragment>
          <Icon className='stops-icon'>store_front</Icon>
          <b>{this.props.planEvent.b2b_stops_count}</b>
        </React.Fragment>
      )
    }
  }

  renderEshopStopsCount() {
    return (
      <React.Fragment>
        <Icon className='stops-icon'>shopping_cart</Icon>
        <b>{this.props.planEvent.eshop_stops_count}</b>
      </React.Fragment>
    )
  }

  renderPlanEventShow() {
    const {
      id, route_name, total_revenue_without_manual_orders, user_full_name, sms_receivers_count,
      planned_stops_count, visited_stops_count, start_at_hour, route_start_end_time,
      home_receivers_count
    } = this.props.planEvent

    const homeReceivers = home_receivers_count > 0 ? `(${home_receivers_count})` : ''

    return (
      <React.Fragment>
        <div style={{ ...styles.flexbox, minHeight: 75 }} className='plan-event-top-container'>
          <div style={styles.textOverflow}>
            <div style={styles.textOverflow} className='plan-event-table-cell-route-name'>
              <Link to={Routes.plan_event_path(tenant, depoId, id)} title={route_name}>
                {route_name}
              </Link>
            </div>
            <div className="fun-revenue"><strong>$:</strong> {total_revenue_without_manual_orders} {currency}</div>
            <div style={styles.textOverflow}>{user_full_name}</div>
            <div>Time: {route_start_end_time}</div>
          </div>
          {this.renderActionButtons(start_at_hour, route_name, id)}
        </div>
        <div className='plan-event-bottom-container'>
          <Icon className='subscribers-icon'>people</Icon>
          <Link to={Routes.sms_customers_plan_event_path(tenant, depoId, this.props.planEvent.id)}>
            {sms_receivers_count} {homeReceivers}
          </Link>
          <Icon className='stops-icon'>directions_bus</Icon>
          <b>
            {visited_stops_count} / {planned_stops_count}
          </b>
          {this.renderB2bStopsCount()}
          {this.renderEshopStopsCount()}
        </div>
        {this.renderRoutePointsPrintMarkup()}
        {this.renderSeriesInfoModal()}
      </React.Fragment>
    )
  }

  renderSeriesInfoButton() {
    if (!this.props.planEvent.series_id) return

    return (
      <IconButton style={{ ...styles.cellButton }} onClick={this.toggleModal.bind(this)}>
        <Icon style={styles.cellButtonIcon}>link</Icon>
      </IconButton>
    )
  }

  renderSeriesInfoModal() {
    const {
      series_start_date,
      series_end_date,
      series_recurrence_interval,
      car_internal_number,
      user_email,
      route_name
    } = this.props.planEvent
    const title = `${car_internal_number} - ${route_name} - ${user_email}`

    const startDate = moment(series_start_date).format('DD-MM-YYYY')
    const endDate = moment(series_end_date).format('DD-MM-YYYY')

    return (
      <Modal open={this.state.modalOpen} title={title} close={this.toggleModal.bind(this)} style={styles.centeredText}>
        Series last from <b>{startDate}</b> to <b>{endDate}</b> and occur with <b>{series_recurrence_interval}</b> days
        interval
      </Modal>
    )
  }

  renderPlanEventNewForm() {
    const { date, classes, loading } = this.props
    const { routeId, driverId, editing } = this.state

    if (loading) return <TableCell className={classes.cell} />
    if (editing === 'show') return this.renderEmptyCell()

    const actions = (
      <div style={{ ...styles.flexbox, marginTop: 6 }}>
        <a onClick={this.showDatePicker.bind(this)} style={styles.date}>
          {date.format('DD-MM-YYYY')}
        </a>

        {this.renderSaveButton()}
      </div>
    )

    return <TableCell className={classes.cell}>{this.renderPlanEventForm(routeId, driverId, actions, true)}</TableCell>
  }

  selectDefaultValue(id, collection) {
    if (id) {
      return collection.find(({ value }) => value === id)
    }
  }

  renderPlanEventForm(routeId, driverId, actions, showDatePicker) {
    const { routes, users } = this.props
    const selectedUser = users.filter((u) => u.value == driverId)
    const selectedRoute = routes.filter((r) => r.value == routeId)

    return (
      <React.Fragment>
        <IntegrationReactSelect
          name='route'
          value={selectedRoute && selectedRoute[0]}
          placeholder='Route'
          loadOptions={this.routesOptions.bind(this)}
          onChange={this.handleRouteChange.bind(this)}
        />
        <IntegrationReactSelect
          name='driver'
          value={selectedUser && selectedUser[0]}
          placeholder='Driver'
          loadOptions={this.usersOptions.bind(this)}
          onChange={this.handleDriverChange.bind(this)}
        />

        {actions}

        {showDatePicker && this.renderDatePicker()}
      </React.Fragment>
    )
  }

  renderEmptyCell() {
    const { car, wday } = this.props
    const onClickHandler = car.depo_id == depoId ? this.switchToEdit.bind(this) : null
    return (
      <TableCell
        id={`c${car.id}-wday${wday}`}
        className={`${this.props.classes.cell} empty-calendar-cell`}
        onClick={onClickHandler}
      >
        <span className='create-new-plan-event'>{car.depo_id == depoId ? 'CREATE NEW PLAN EVENT' : ''}</span>
      </TableCell>
    )
  }

  renderRecurringEventForm() {
    const { recurringEventEndDate, recurrenceInterval } = this.state

    return (
      <div className='DayPicker-element recurring-event-form'>
        <div className='DayPicker-element' style={styles.labelsContainer}>
          <div className='DayPicker-element' style={styles.formElementPadding}>
            End date
          </div>
          <div className='DayPicker-element' style={{ ...styles.topMargin, ...styles.formElementPadding }}>
            Recurrence interval
          </div>
        </div>

        <div className='DayPicker-element' style={styles.inputsContainer}>
          <DayPickerInput
            inputProps={{ className: 'DayPicker-element' }}
            formatDate={(date) => moment(date).format('DD-MM-YYYY')}
            placeholder='DD-MM-YYYY'
            parseDate={parseDate}
            formatDate={formatDate}
            format='DD-MM-YYYY'
            onDayChange={this.onRecurringEventEndDateChange.bind(this)}
            value={recurringEventEndDate}
            dayPickerProps={{
              firstDayOfWeek: 1,
              selectedDays: [recurringEventEndDate]
            }}
          />
          {!recurringEventEndDate && (
            <div className='DayPicker-element' style={styles.error}>
              Invalid!
            </div>
          )}

          <br />

          <input
            id='recurrence-interval'
            className='DayPicker-element'
            style={styles.topMargin}
            value={recurrenceInterval}
            onChange={this.onRecurrenceIntervalChange.bind(this)}
          />
          <span className='DayPicker-element' style={{ marginLeft: 4, padding: 1 }}>
            days
          </span>
          {!(Number.parseInt(recurrenceInterval) > 0) && (
            <div className='DayPicker-element' style={styles.error}>
              Invalid!
            </div>
          )}
        </div>
      </div>
    )
  }

  renderDatePicker() {
    const { datePickerVisible, recurringEvent, selectedDays } = this.state

    if (!datePickerVisible) return

    let content
    if (recurringEvent) {
      content = this.renderRecurringEventForm()
    } else {
      content = (
        <DayPicker
          onDayClick={this.onDayClick.bind(this)}
          selectedDays={selectedDays.map((d) => d.toDate())}
          firstDayOfWeek={1}
        />
      )
    }

    const wday = this.props.wday

    return (
      <div
        style={
          wday > 5
            ? {
                ...styles.datePickerContainer,
                ...styles.datePickerContainerLeft
              }
            : styles.datePickerContainer
        }
      >
        <div
          style={wday > 5 ? { ...styles.datePickerPointer, ...styles.datePickerPointerLeft } : styles.datePickerPointer}
        />

        <div style={styles.datePicker}>
          <div className='DayPicker-element' style={styles.recurringEventCheckbox}>
            <label className='DayPicker-element' style={styles.recurringEventLabel}>
              Recurring event
            </label>
            <Checkbox
              inputProps={{ datepickerelement: 'true' }}
              checked={recurringEvent}
              onChange={this.toggleRecurringEvent.bind(this)}
            />
          </div>

          {content}
        </div>
      </div>
    )
  }

  render() {
    if (this.props.planEvent) {
      return this.renderPlanEvent()
    } else {
      return this.renderPlanEventNewForm()
    }
  }
}

const mapStateToProps = (state, props) => {
  const { users = [], routes = [], planEvents = [] } = state
  const date = props.date.format('YYYY-MM-DD')

  return {
    users,
    routes,
    loading: state.loading,
    planEvents: planEvents.filter((pe) => pe.date === date)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFlash: (flash) => dispatch(setFlash(flash)),
    updatePlanEventTemplate: (planEventTemplate) => dispatch(updateResource(planEventTemplate)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    createPlanEvents: (newPlanEvents) => dispatch(createPlanEvents(newPlanEvents)),
    destroyPlanEvent: (planEvent) => dispatch(destroyPlanEvents([planEvent])),
    destroyPlanEventWithSeries: (planEvent) => dispatch(destroyPlanEventWithSeries(planEvent)),
    editPlanEvent: (planEvent) => dispatch(editPlanEvent(planEvent))
  }
}

const PlanEventTemplatesTableCell = connect(mapStateToProps, mapDispatchToProps)(PlanEventTemplatesTableCellClass)

export default withStyles(styles)(PlanEventTemplatesTableCell)
