import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import ModeEdit from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

const styles = {}

const UsersTableRow = ({ user, index, classes }) => {
  return (
    <TableRow hover>
      <TableCell>{user.id}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.first_name}</TableCell>
      <TableCell>{user.last_name}</TableCell>
      <TableCell>{user.axapta_code}</TableCell>
      <TableCell>{user.role}</TableCell>
      <TableCell>
        <Link
          className='mdl-button mdl-js-button mdl-button--icon'
          to={Routes.edit_admin_users_path(tenant, depoId, user.id)}
        >
          <ModeEdit />
        </Link>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(UsersTableRow)
