import React from 'react'
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import axios from 'axios'
import NewButton from '../../shared/NewButton'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import MultiSelect from '../../shared/MultiSelect'
import Button from '@material-ui/core/Button'
import ProductTableRow from './ProductTableRow'
import FlashNotification from '../../shared/FlashNotification'
import FunSearchFieldWithoutRedux from '../../forms/FunSearchFieldWithoutRedux'

class ProductsTable extends React.Component {
  state = {
    products: [],
    loading: false,
    statuses: ['active', 'inactive'],
    filteredStatuses: ['active'],
    message: null
  }

  componentDidMount() {
    this.loadPage()
  }

  loadPage() {
    const { filteredStatuses, searchPhrase } = this.state

    this.startLoading()
    axios
      .get(
        Routes.inventory_products_path(tenant, depoId, {
          format: 'json',
          statuses: filteredStatuses,
          search: searchPhrase
        })
      )
      .then((res) => {
        this.setState({ products: res.data, loading: false })
      })
      .catch((err) => {
        this.setState({ message: `${err}` })
        this.stopLoading()
      })
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading} />
  }

  startLoading() {
    this.setState({ loading: true })
  }

  setMessage(message) {
    this.setState({ message })
  }

  stopLoading() {
    this.setState({ loading: false })
  }

  handleFilterChange(e, value) {
    this.setState({ [value]: e.target.value })
  }

  refilter() {
    this.loadPage()
  }

  openMessage(message) {
    this.setState({ message })
  }

  renderFilters() {
    return (
      <div className='cars-filter float-left'>
        <MultiSelect
          selectedValues={this.state.filteredStatuses}
          values={this.state.statuses}
          inputName='filteredStatuses'
          label='Status'
          selectClassName='status-filter'
          handleChange={this.handleFilterChange.bind(this)}
        />
        &nbsp;
        <Button
          variant='contained'
          color='primary'
          size='small'
          style={{ verticalAlign: 'bottom' }}
          style={{ verticalAlign: 'bottom' }}
          onClick={this.refilter.bind(this)}
        >
          Filter
        </Button>
      </div>
    )
  }

  renderProducts() {
    const { products } = this.state

    return products.map((product) => {
      return <ProductTableRow key={product.id} product={product} openMessage={this.openMessage.bind(this)} />
    })
  }

  handleSearch(searchPhrase) {
    this.setState({ searchPhrase }, this.loadPage)
  }

  clearSearch() {
    this.setState({ searchPhrase: false }, this.loadPage)
  }

  renderSearchField() {
    return (
      <div className='search-field'>
        <FunSearchFieldWithoutRedux
          withoutSearchLimit={true}
          setFlash={this.setMessage.bind(this)}
          clearSearch={this.clearSearch.bind(this)}
          handleSubmit={this.handleSearch.bind(this)}
        />
      </div>
    )
  }

  renderNewButton() {
    if (isAdmin)
      return <NewButton className='new-product-button' to={Routes.new_inventory_products_path(tenant, depoId)} />
    return false
  }

  render() {
    return (
      <div>
        <FlashNotification message={this.state.message} />
        <Typography variant='h5'>Products</Typography>
        {this.renderNewButton()}
        {this.renderFilters()}
        {this.renderSearchField()}
        <Table className='resources-table'>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Sku</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Sequence number</TableCell>
              <TableCell>Packaging size</TableCell>
              <TableCell>Sales price, {currency}</TableCell>
              <TableCell>Depo minimum</TableCell>
              <TableCell>Car minimum</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderProducts()}</TableBody>
        </Table>
        {this.renderLoading()}
      </div>
    )
  }
}

export default ProductsTable
