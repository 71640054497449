import React from 'react'
import locationMarkerBlack from '../public/icons/marker_icon_black.svg'
import locationMarkerBlue from '../public/icons/location_search_marker.svg'
import ArrowForward from '@material-ui/icons/ArrowForward'
import axios from 'axios'
import debounce from 'lodash/debounce'
import logGoogleEvent from '../public/customers/logGoogleEvent'

const REQUEST_TIME_WAIT = 250

class MapboxAutocomplete extends React.Component {
  state = { searchPhrase: '', placesList: [], selectedItem: {} }

  componentDidMount() {
    this.closeAutocompleteOnOutsideClick()
  }

  renderSearchIcon() {
    const { showSearchIcon } = this.props

    if (showSearchIcon) {
      return (
        <div className='search-icon' onClick={this.selectClosestSuggestion.bind(this)}>
          <ArrowForward />
        </div>
      )
    }
  }

  closeAutocompleteOnOutsideClick() {
    document.querySelector('body').addEventListener('click', (e) => {
      if (!this.state.placesList.length) return

      const clickWithinList =
        e.target.className &&
        e.target.className.length &&
        (e.target.className.includes('mapbox-places-list') || e.target.className.includes('location-search-container'))

      if (!clickWithinList) this.setState({ placesList: [] })
    })
  }

  getPlaces(e) {
    if (this.state.searchPhrase.length < 3) {
      this.setState({ placesList: [] })
      return
    }

    // Do not trigger places fetch too often in a minute
    const fetchWithDebounce = debounce(this.fetchPlaces.bind(this), REQUEST_TIME_WAIT)
    fetchWithDebounce()
  }

  fetchHjemisAddresses() {
    const { searchPhrase } = this.state

    axios
      .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchPhrase}.json`, {
        params: {
          access_token: this.props.accessToken,
          autocomplete: true,
          country: ['dk']
        }
      })
      .then((res) => {
        if (res.data.features.length > 0) {
          if (this.props.changeAction) {
            this.props.changeAction(res.data.features)
          }
          this.setState({ placesList: res.data.features, showList: true })
        } else {
          this.setState({ placesList: [] })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  fetchIsbilenAddresses() {
    const { searchPhrase } = this.state

    const token = btoa(`den-norske-isbilen:${this.props.isbillen_api_key}`)

    axios
      .get(
        Routes.public_isbillen_addresses_path({
          Query: searchPhrase,
          Size: 5
        }),
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then((res) => {
        if (res.data.SearchResults.length > 0) {
          res.data.SearchResults.forEach((result) => {
            result.place_name = result.Text
            result.center = [result.Source.Posisjon.X, result.Source.Posisjon.Y]
            result.id = result.Id
          })
          if (this.props.changeAction) {
            this.props.changeAction(res.data.SearchResults)
          }

          this.setState({ placesList: res.data.SearchResults, showList: true })
        } else {
          this.setState({ placesList: [] })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  fetchPlaces() {
    const { searchPhrase } = this.state
    if (!searchPhrase.length) return

    if (this.props.locale == 'hjem-is') {
      this.fetchHjemisAddresses()
    } else {
      this.fetchIsbilenAddresses()
    }
  }

  handleChange(e) {
    logGoogleEvent(this.props.googleAction)
    this.setState({ searchPhrase: e.target.value }, this.getPlaces.bind(this))
  }

  handleKeyUp(e) {
    const { placesList, searchPhrase, showList } = this.state
    if ((!placesList.length && searchPhrase.length < 3) || !showList) return

    if (e.key === 'ArrowDown') {
      if (this.state.selectedItem) {
        const index = placesList.findIndex((place) => place.id === this.state.selectedItem.id)
        const nextIndex = index + 1
        if (nextIndex === placesList.length) {
          this.setState({
            selectedItem: placesList[0],
            searchPhrase: placesList[0].place_name
          })
        } else {
          this.setState({
            selectedItem: placesList[nextIndex],
            searchPhrase: placesList[nextIndex].place_name
          })
        }
      } else {
        this.setState({
          selectedItem: placesList[0],
          searchPhrase: placesList[0].place_name
        })
      }
    } else if (e.key === 'ArrowUp') {
      if (this.state.selectedItem) {
        const index = placesList.findIndex((place) => place.id === this.state.selectedItem.id)
        const prevIndex = index - 1
        if (prevIndex === -1) {
          this.setState({
            selectedItem: placesList[placesList.length - 1],
            searchPhrase: placesList[placesList.length - 1].place_name
          })
        } else {
          this.setState({
            selectedItem: placesList[prevIndex],
            searchPhrase: placesList[prevIndex].place_name
          })
        }
      } else {
        this.setState({
          selectedItem: placesList[placesList.length - 1],
          searchPhrase: placesList[placesList.length - 1].place_name
        })
      }
    }

    if (e.key === 'Enter' && Object.keys(this.state.selectedItem).length) {
      this.selectSuggestion(this.state.selectedItem)
    } else if (e.key === 'Enter' && !Object.keys(this.state.selectedItem).length) {
      this.selectClosestSuggestion()
    }

    if (e.key === 'Escape') {
      this.setState({ showList: false, placesList: [] })
    }
  }

  selectClosestSuggestion() {
    const { placesList } = this.state
    if (placesList.length) {
      this.selectSuggestion(placesList[0])
    }
  }

  selectSuggestion(item) {
    const { hotjarLink, googleSelectAction } = this.props
    const correctCoordinates = { lat: item.center[1], lng: item.center[0] }
    this.setState({ showList: false, searchPhrase: item.place_name })
    this.props.onAddressChange(hotjarLink, correctCoordinates, googleSelectAction)
  }

  renderPlacesList() {
    const { placesList, selectedItem } = this.state

    return placesList.map((place) => {
      const activeClass = selectedItem && selectedItem.id === place.id ? 'location-search-autocomplete-item-active' : ''
      return (
        <div
          key={place.id}
          onClick={this.selectSuggestion.bind(this, place)}
          className={`mapbox-places-list-item location-search-autocomplete-item ${activeClass}`}
        >
          {place.place_name}
        </div>
      )
    })
  }

  renderResult() {
    if (this.state.showList && !this.props.overrideDropdown) {
      return <div className='mapbox-places-list location-search-suggestions-container'>{this.renderPlacesList()}</div>
    }
  }

  render() {
    const { translations } = this.props
    let containerClasses = `location-search-container ${this.props.classNames}`
    const locationMarker = this.props.locationMarkerColor === 'black' ? locationMarkerBlack : locationMarkerBlue

    if (this.props.nomarker) {
      return (
        <div className={containerClasses}>
          <input
            placeholder='Address'
            onChange={this.handleChange.bind(this)}
            onKeyUp={this.handleKeyUp.bind(this)}
            value={this.state.searchPhrase}
            className='mdl-textfield__input mdl-input address-input'
          />
          {this.renderSearchIcon()}
          {this.renderResult()}
        </div>
      )
    } else {
      return (
        <div className={containerClasses}>
          <input
            placeholder={translations?.search_for_an_address || "Search"}
            onChange={this.handleChange.bind(this)}
            onKeyUp={this.handleKeyUp.bind(this)}
            value={this.state.searchPhrase}
            className='location-search-input'
          />
          <img src={locationMarker} className='location-search-input-icon marker-icon' />
          {this.renderSearchIcon()}
          {this.renderResult()}
        </div>
      )
    }
  }
}

MapboxAutocomplete.defaultProps = {
  accessToken: 'ACCESS_TOKEN'
}

export default MapboxAutocomplete
