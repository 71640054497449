const Route = () => {
  return {
    id: '',
    name: '',
    radius: 200,
    route_duration: '',
    approval_status: '',
    minimum_efficient_sales_count: '',
    tag: '',
    time_from_depo: '',
    time_to_depo: '',
    administration_time: '00:00:00',
    eod_administration_time: '00:00:00',
    hidden: false,
    errors: {},
  }
}

export default Route
