import React from 'react'
import axios from 'axios'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import FunSearchFieldWithoutRedux from '../../forms/FunSearchFieldWithoutRedux'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import FlashNotification from '../../shared/FlashNotification'
import SharedTable from '../../shared/SharedTable'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import extractDataErrors from '../../support/extractDataErrors'
import snakeCase from 'lodash/snakeCase'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PrintHtml from '../PrintHtml'
import Checkbox from '@material-ui/core/Checkbox'
import moment from 'moment'
import DateRangeFilter from '../../feedbacks/DateRangeFilter'
import RouteChangeModal from '../RouteChangeModal'
import DepoChangeModal from '../DepoChangeModal'
import CleanupModal from '../CleanupModal'
import VersionChangelog from '../VersionChangelog'
import fastIcon from '../../../files/images/fast.svg'

class OrdersList extends React.Component {
  state = {
    page: 1,
    orders: [],
    message: null,
    loading: false,
    searchPhrase: null,
    paginationDisabled: false,
    orderView: 0,
    viewChanging: false,
    modalOpen: false,
    startDate: moment().startOf('month').format('DD-MM-YYYY'),
    endDate: moment().endOf('month').format('DD-MM-YYYY'),
    cleanupModalOpen: false
  }

  componentDidMount() {
    this.loadOrders()
  }

  loadOrders() {
    const { page, searchPhrase, startDate, endDate } = this.state
    this.startLoading()
    axios
      .get(this.url(), {
        params: {
          page,
          search_phrase: searchPhrase ? searchPhrase : null,
          start_date: startDate,
          end_date: endDate
        }
      })
      .then((res) => {
        const uncheckedOrders = res.data.map((r) => {
          r.checked = false
          return r
        })
        this.setState({
          orders: uncheckedOrders,
          loading: false,
          paginationDisabled: false
        })
      })
      .catch((error) => {
        this.stopLoading()
        this.setMessage(`${error}`)
      })
  }

  url() {
    const { orderView } = this.state
    let status
    switch (orderView) {
      case 0:
        status = 'new'
        break
      case 1:
        status = 'completed'
        break
      case 2:
        status = 'failed'
        break
      case 3:
        status = 'cancelled'
        break
      case 4:
        status = 'pickup'
        break
      default:
    }

    return Routes.eshop_orders_path(tenant, depoId, {
      status: status,
      format: 'json'
    })
  }

  startLoading() {
    this.setState({ loading: true })
  }

  stopLoading() {
    this.setState({ loading: false })
  }

  setMessage(message) {
    this.setState({ message })
  }

  handleSearch(searchPhrase) {
    this.setState({ searchPhrase }, this.loadOrders)
  }

  clearErrors() {
    this.setState({ message: null })
  }

  clearSearch() {
    this.setState({ searchPhrase: false }, this.loadOrders)
  }

  changePage(pageIncrement) {
    const { orders, page } = this.state
    if (orders.length < 25 && pageIncrement == 1) return

    const newPage = Math.min(Math.max(1, page + pageIncrement))
    if (newPage === page) return
    this.setState({ page: newPage, paginationDisabled: true }, this.loadOrders)
  }

  handleChange(orderIndex, e) {
    const copyOrders = this.state.orders.slice(0)
    copyOrders[orderIndex].comment = e.target.value
    this.setState({ orders: copyOrders })
  }

  updateStatus(orderIndex, status, e) {
    e.preventDefault()
    const order = this.state.orders[orderIndex]
    if (status === order.status) return

    const ordersCopy = this.state.orders.slice(0)
    this.startLoading()
    axios
      .put(
        Routes.update_status_eshop_order_path(tenant, depoId, order.id, {
          format: 'json'
        }),
        { status: status }
      )
      .then((res) => {
        this.setMessage('Order updated')
        this.stopLoading()
        ordersCopy.splice(orderIndex, 1)
        this.setState({ orders: ordersCopy })
      })
      .catch((err) => {
        this.setMessage(`${extractDataErrors(err).full_errors}`)
        this.stopLoading()
      })
  }

  submitOrder(orderIndex, e) {
    this.startLoading()
    const order = this.state.orders[orderIndex]
    axios
      .put(Routes.eshop_order_path(tenant, depoId, order.id, { format: 'json' }), order)
      .then((res) => {
        this.setMessage('Order updated')
        this.stopLoading()
      })
      .catch((err) => {
        this.setMessage(`${extractDataErrors(err).full_errors}`)
        this.stopLoading()
      })
  }

  toggleOrderForPrintout(order, e) {
    const copyOrders = this.state.orders.slice(0)
    const orderIndex = copyOrders.findIndex((o) => o.id === order.id)
    copyOrders[orderIndex].checked = !copyOrders[orderIndex].checked
    this.setState({ orders: copyOrders })
  }

  updateOrdersAsPrinted() {
    const ordersIds = this.state.orders.filter((order) => order.checked).map((order) => order.id)
    if (ordersIds.length === 0) return

    axios
      .post(
        Routes.update_printed_status_eshop_orders_path(tenant, depoId, {
          format: 'json',
          order_ids: ordersIds
        })
      )
      .then((res) => {
        this.setMessage('Orders set as printed')
        const copyOrders = this.state.orders.slice(0)
        copyOrders.forEach((order) => {
          if (order.checked) {
            const orderFromResponse = res.data.filter((data) => data.id === order.id)[0]
            order.printed_at = orderFromResponse.printed_at
          }
        })
        this.setState({ orders: copyOrders })
      })
      .catch((err) => {
        this.setMessage(`${extractDataErrors(err).full_errors}`)
      })
  }

  openModal(order, attribute) {
    const copyOrders = this.state.orders.slice(0)
    const orderIndex = copyOrders.findIndex((o) => o.id === order.id)
    copyOrders[orderIndex][attribute] = true
    this.setState({ orders: copyOrders })
  }

  toggleForm(order) {
    const copyOrders = this.state.orders.slice(0)
    const orderIndex = copyOrders.findIndex((o) => o.id === order.id)
    copyOrders[orderIndex].formOpen = !copyOrders[orderIndex].formOpen
    this.setState({ orders: copyOrders })
  }

  handleModalClose(order) {
    const copyOrders = this.state.orders.slice(0)
    const orderIndex = copyOrders.findIndex((o) => o.id === order.id)
    copyOrders[orderIndex].modalOpen = false
    copyOrders[orderIndex].depoModalOpen = false
    copyOrders[orderIndex].changelogOpen = false
    this.setState({ orders: copyOrders })
  }

  handleTabChange(e, value) {
    if (this.state.orderView == value) return

    this.setState({ orderView: value, page: 1 }, this.loadOrders)
  }

  handleDateFilter(field, value) {
    this.setState({ [field]: value }, this.loadOrders)
  }

  renderSearchField() {
    return (
      <div className='search-field inline'>
        <FunSearchFieldWithoutRedux
          withoutSearchLimit={true}
          setFlash={this.setMessage.bind(this)}
          clearSearch={this.clearSearch.bind(this)}
          handleSubmit={this.handleSearch.bind(this)}
        />
      </div>
    )
  }

  renderTablePagination() {
    const { paginationDisabled, page } = this.state
    return (
      <div className='pagination float-right'>
        <strong>Page: {page}</strong>
        &nbsp;
        <IconButton onClick={this.changePage.bind(this, -1)} disabled={paginationDisabled}>
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton onClick={this.changePage.bind(this, 1)} disabled={paginationDisabled}>
          <KeyboardArrowRight />
        </IconButton>
      </div>
    )
  }

  renderDateRangeFilter() {
    return (
      <DateRangeFilter
        handleChange={this.handleDateFilter.bind(this)}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
      />
    )
  }

  openCleanupModal() {
    this.setState({ cleanupModalOpen: true })
  }

  closeCleanupModal() {
    this.setState({ cleanupModalOpen: false })
  }

  renderCleanUp() {
    return (
      <>
        <Button style={{ marginRight: 10 }} variant="contained" color="primary" onClick={this.openCleanupModal.bind(this)}>Cleanup </Button>

        <CleanupModal
          setMessage={this.setMessage.bind(this)}
          type='eshop'
          path={Routes.cleanup_eshop_orders_path}
          handleModalClose={this.closeCleanupModal.bind(this)}
          open={this.state.cleanupModalOpen}
        />
      </>
    )
  }

  renderHead() {
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          {this.renderTitle()}
          {isHjemis && this.renderCleanUp()}
          {this.renderPrintButton()}
          {this.renderDateRangeFilter()}
          {this.renderSearchField()}
        </div>
        {this.renderTablePagination()}
      </div>
    )
  }

  renderTable() {
    const { orders } = this.state

    return (
      <SharedTable
        formComponent={this.renderForm.bind(this)}
        additionalRowStyles={this.additionalRowStyles}
        toggleForm={this.toggleForm.bind(this)}
        formColspan={12}
        columns={['Actions', 'Labels', 'Delivery date', 'Delivery time', 'Total', 'Route',  'Customer address', 'Customer phone', 'Eshop ID', 'Additional driving time', 'Additional driving distance', 'Order origin', 'Printed at']}
        data={orders.map(order => ({ actions: [this.renderCheckbox(order)], labels: this.renderLabels(order), ...order }))} />
    )
  }

  renderLabels(order) {
    let deliveryLabel = ''

    if (order.call_me) {
      deliveryLabel = <span className='tag-label call-me-label'>CALL ME</span>
    }
    if (order.fast_delivery) {
      deliveryLabel = <img style={{ width: 35, marginBottom: 5 }} src={fastIcon} />
    }
    return <React.Fragment>{deliveryLabel}</React.Fragment>
  }

  additionalRowStyles(data) {
    let color = '5px solid teal'
    let backgroundColor = 'white'
    if (data.status === 'cancelled') {
      color = '5px solid red'
    } else if (data.status === 'failed') {
      color = '5px solid #FBC02D'
    } else if (data.status === 'completed') {
      color = '5px solid #AED581'
    }
    return { borderLeft: color }
  }

  additionalItemStyles(data) {
    let color = 'white'
    if (!data.product_exists) {
      color = '#FBC02D'
    }
    return { backgroundColor: color }
  }

  renderOrderData(order) {
    return (
      <Grid container className="pt-3 pb-3">
        {['Eshop ID', 'Delivery date', 'Total', 'Customer address', 'Payment type', 'Customer name', 'Fast delivery', 'Order origin'].map((item, index) => {
          const fastDelivery = order['fast_delivery']
          let itemInfo = order[snakeCase(item).toLowerCase()]

          if (snakeCase(item).toLowerCase() == 'fast_delivery' && fastDelivery) {
            itemInfo = <span className='tag-label warning-label'>YES</span>
          } else if (snakeCase(item).toLowerCase() == 'fast_delivery') {
            itemInfo = <span className='tag-label'>NO</span>
          }
          return (
            <Grid className='pb-1' key={index} item xs={12} sm={12} md={12} lg={6}>
              <strong>{item}:</strong> {itemInfo}
            </Grid>
          )
        })}
      </Grid>
    )
  }

  renderCheckbox(order) {
    return (
      <Checkbox
        className='check-order'
        checked={order.checked || false}
        onChange={this.toggleOrderForPrintout.bind(this, order)}
      />
    )
  }

  renderItemsTable(items) {
    if (items && items.length) {
      return (
        <SharedTable
          additionalRowStyles={this.additionalItemStyles}
          columns={['Product sku', 'Product Description', 'Quantity', 'Price', 'Created at']}
          data={items}
        />
      )
    }
  }

  renderForm(data) {
    const orderIndex = this.state.orders.findIndex((o) => o.id === data.id)
    const order = this.state.orders[orderIndex]

    return (
      <div style={{ padding: 10 }} key={order.id}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div style={{ flex: 3, display: 'flex', alignItems: 'flex-end' }}>
            <Button
              style={{ marginRight: 10 }}
              variant='contained'
              color='primary'
              onClick={this.updateStatus.bind(this, orderIndex, 'completed')}
            >
              Delivered
            </Button>
            <Button
              style={{ marginRight: 10 }}
              variant='contained'
              color='default'
              onClick={this.updateStatus.bind(this, orderIndex, 'failed')}
            >
              Problem with order
            </Button>
            <Button
              style={{ marginRight: 10 }}
              variant='contained'
              color='secondary'
              onClick={this.updateStatus.bind(this, orderIndex, 'cancelled')}
            >
              Cancel order
            </Button>
            <TextField
              style={{ width: '30%', marginRight: 10 }}
              fullWidth
              onChange={this.handleChange.bind(this, orderIndex)}
              value={order.comment || ''}
              label='Comment'
            />
            <Button
              style={{ marginRight: 10 }}
              vairant='contained'
              color='primary'
              onClick={this.submitOrder.bind(this, orderIndex)}
            >
              Save
            </Button>
            <Button
              style={{ marginRight: 10, marginLeft: 10 }}
              vairant='contained'
              color='primary'
              onClick={this.openModal.bind(this, order, 'modalOpen')}
            >
              Change Route
            </Button>
            <Button
              style={{ marginRight: 10, marginLeft: 10 }}
              vairant='contained'
              color='primary'
              onClick={this.openModal.bind(this, order, 'depoModalOpen')}
            >
              Change Depo
            </Button>
            {order.versions.length > 0 && (
              <Button
                style={{ marginRight: 10, marginLeft: 10 }}
                vairant='contained'
                color='primary'
                onClick={this.openModal.bind(this, order, 'changelogOpen')}
              >
                Changelog
              </Button>
            )}
          </div>
        </div>
        {this.renderOrderData(order)}
        {this.renderItemsTable(order.line_items)}
        <RouteChangeModal
          loadOrders={this.loadOrders.bind(this)}
          setMessage={this.setMessage.bind(this)}
          order={order}
          type='eshop'
          fetchPath={Routes.fetch_delivery_time_eshop_order_path}
          updatePath={Routes.update_route_eshop_order_path}
          handleModalClose={this.handleModalClose.bind(this)}
        />
        <DepoChangeModal
          loadOrders={this.loadOrders.bind(this)}
          setMessage={this.setMessage.bind(this)}
          order={order}
          path={Routes.move_to_different_depo_eshop_order_path}
          handleModalClose={this.handleModalClose.bind(this)}
        />
        <VersionChangelog order={order} handleModalClose={this.handleModalClose.bind(this)} />
      </div>
    )
  }

  renderPrintButton() {
    const orders = this.state.orders.filter((order) => order.checked)
    orders.forEach((order) => {
      const header = {
        customerAddress: order.customer_address,
        route: order.route,
        eshopId: order.eshop_id,
        deliveryDate: order.delivery_date,
        deliveryTime: order.delivery_time,
        paymentType: order.payment_type,
        comment: order.comment,
        total: order.total
      }

      order.header = header
    })

    return (
      <PrintHtml
        orders={orders}
        updateOrdersAsPrinted={this.updateOrdersAsPrinted.bind(this)}
        title='E-shop orders printout'
      />
    )
  }

  renderTitle() {
    return (
      <Typography variant='h5' className='mt-2 mr-2 float-left'>
        Orders
      </Typography>
    )
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading} />
  }

  renderFlashNotification() {
    return <FlashNotification message={this.state.message} clearErrors={this.clearErrors.bind(this)} />
  }

  renderTabSwitch() {
    const { orderView, viewChanging } = this.state
    return (
      <Tabs
        indicatorColor='secondary'
        textColor='secondary'
        className='w-100 mt-3'
        value={orderView}
        centered
        onChange={this.handleTabChange.bind(this)}
      >
        <Tab className='new-tab' value={0} label='New' disabled={viewChanging} />
        <Tab className='delivered-tab' value={1} label='Delivered' disabled={viewChanging} />
        <Tab className='problem-tab' value={2} label='Problem' disabled={viewChanging} />
        <Tab className='cancelled-tab' value={3} label='Cancelled' disabled={viewChanging} />
        {isHjemis && ( // Pickup orders only in Danish eshop
          <Tab className='pickup-tab' value={4} label='Pickup' disabled={viewChanging} />
        )}
      </Tabs>
    )
  }

  render() {
    return (
      <div>
        {this.renderHead()}
        {this.renderTabSwitch()}
        {this.renderTable()}
        {this.renderFlashNotification()}
        {this.renderLoading()}
      </div>
    )
  }
}

export default OrdersList
