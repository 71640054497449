import React from 'react'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import axios from 'axios'
import NewButton from '../../shared/NewButton'
import ModeEdit from '@material-ui/icons/Edit'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import FlashNotification from '../../shared/FlashNotification'

class SmsTemplates extends React.Component {
  state = { smsTemplates: [], loading: false, message: null }

  componentDidMount() {
    this.startLoading()
    axios
      .get(Routes.admin_sms_templates_path(window.tenant, { format: 'json' }))
      .then((res) => {
        this.setState({ smsTemplates: res.data, loading: false })
      })
      .catch((err) => this.setState({ message: `${err}` }))
  }

  startLoading() {
    this.setState({ loading: true })
  }

  stopLoading() {
    this.setState({ loading: false })
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading} />
  }

  renderFlashNotification() {
    return <FlashNotification message={this.state.message} />
  }

  renderSmsTemplates() {
    const { smsTemplates } = this.state

    return smsTemplates.map((smsTemplate) => {
      return (
        <TableRow key={smsTemplate.id}>
          <TableCell>{smsTemplate.id}</TableCell>
          <TableCell>{smsTemplate.name}</TableCell>
          <TableCell>{smsTemplate.text}</TableCell>
          <TableCell>
            <Link
              className='mdl-button mdl-js-button mdl-button--icon'
              to={Routes.edit_admin_sms_templates_path(window.tenant, smsTemplate.id)}
            >
              <ModeEdit />
            </Link>
          </TableCell>
        </TableRow>
      )
    })
  }

  render() {
    return (
      <div>
        {this.renderLoading()}
        {this.renderFlashNotification()}
        <Typography variant='h5'>Sms templates</Typography>
        <NewButton to={Routes.new_admin_sms_templates_path(window.tenant)} customStyle={{ zIndex: 5 }} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Text</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderSmsTemplates()}</TableBody>
        </Table>
      </div>
    )
  }
}

export default SmsTemplates
