import React from 'react'
import AdminProductsIndex from './AdminProductsIndex'
import ErrorCatcher from '../../shared/ErrorCatcher'

const AdminProductsIndexWithStore = () => {
  return (
    <ErrorCatcher>
      <div className='content-container'>
        <AdminProductsIndex />
      </div>
    </ErrorCatcher>
  )
}

export default AdminProductsIndexWithStore
