import React from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import FunSearchFieldWithoutRedux from '../../forms/FunSearchFieldWithoutRedux'
import FlashNotification from '../../shared/FlashNotification'
import extractDataErrors from '../../support/extractDataErrors'
import SharedTable from '../../shared/SharedTable'
import Grid from '@material-ui/core/Grid'
import snakeCase from 'lodash/snakeCase'
import Typography from '@material-ui/core/Typography'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import Select from '../../shared/Select'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Add from '@material-ui/icons/Add'
import ModalWrapper from '../../shared/Modal'
import Input from '@material-ui/core/Input'
import { Link } from 'react-router-dom'

class CustomersTable extends React.Component {
  state = {
    customers: [],
    customerNumber: '',
    searchPhrase: '',
    paginationDisabled: false,
    currentPage: 1,
    full_errors: null,
    errors: {},
    message: null,
    loading: false,
    depos: [],
    selectedDepoId: '',
    selectedTab: 'all',
    viewChanging: false,
    statusSizes: [0, 0, 0, 0, 0],
    selectedStatus: 'active',
    open: false,
    edit_fields: {}
  }

  componentDidMount() {
    this.fetchStatusSizes()
    this.fetchDepos()
  }

  startLoading() {
    this.setState({ loading: true })
  }

  stopLoading() {
    this.setState({ loading: false })
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading} />
  }

  loadPage() {
    const { searchPhrase, currentPage, selectedDepoId, selectedTab, selectedStatus } = this.state
    this.startLoading()
    axios
      .get(
        Routes.admin_b2b_customers_path(tenant, {
          format: 'json',
          search_phrase: searchPhrase,
          page: currentPage,
          depo: selectedDepoId,
          tab: selectedTab,
          status: selectedStatus
        })
      )
      .then((res) => {
        this.setState({
          customers: res.data,
          paginationDisabled: false,
          viewChanging: false
        })
        this.stopLoading()
      })
      .catch((err) => {
        this.setState({ ...extractDataErrors(err), viewChanging: false })
        this.stopLoading()
      })
  }

  fetchStatusSizes() {
    const { selectedDepoId, selectedStatus, searchPhrase } = this.state
    this.startLoading()
    axios
      .get(
        Routes.fetch_status_sizes_admin_b2b_customers_path(tenant, {
          format: 'json',
          depo: selectedDepoId,
          status: selectedStatus,
          search_phrase: searchPhrase
        })
      )
      .then((res) => {
        this.setState({ statusSizes: res.data }, this.loadPage)
        this.stopLoading()
      })
      .catch((err) => {
        this.setMessage(`${err}`)
        this.stopLoading()
      })
  }

  fetchDepos() {
    axios
      .get(Routes.admin_depos_path(tenant, { format: 'json' }))
      .then((res) => {
        this.setState({ depos: res.data })
      })
      .catch((err) => {
        this.setMessage(`${err}`)
      })
  }

  setMessage(message) {
    this.setState({ message })
  }

  handleSearch(searchPhrase) {
    this.setState({ searchPhrase }, this.fetchStatusSizes)
  }

  clearSearch() {
    this.setState({ searchPhrase: false }, this.fetchStatusSizes)
  }

  changePage(pageIncrement) {
    if (this.state.customers.length < 25 && pageIncrement == 1) return

    const newPage = Math.min(Math.max(1, this.state.currentPage + pageIncrement))
    if (newPage === this.state.currentPage) return
    this.setState({ currentPage: newPage, paginationDisabled: true }, this.loadPage)
  }

  renderPagination() {
    const { paginationDisabled, currentPage } = this.state
    return (
      <div className='right-action-container float-right'>
        <div className='pagination'>
          <strong>Page: {currentPage}</strong>
          &nbsp;
          <IconButton onClick={this.changePage.bind(this, -1)} disabled={paginationDisabled}>
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton onClick={this.changePage.bind(this, 1)} disabled={paginationDisabled}>
            <KeyboardArrowRight />
          </IconButton>
        </div>
      </div>
    )
  }

  toggleForm(customer) {
    const copyCustomers = this.state.customers.slice(0)
    const customerIndex = copyCustomers.findIndex((c) => c.id === customer.id)
    copyCustomers[customerIndex].formOpen = !copyCustomers[customerIndex].formOpen
    this.setState({ customers: copyCustomers })
  }

  renderTable() {
    return (
      <SharedTable
        formComponent={this.renderForm.bind(this)}
        formColspan={9}
        toggleForm={this.toggleForm.bind(this)}
        columns={[
          'Shop ID',
          'Segmentation',
          'Customer Name',
          'Address',
          'Zip code',
          'Phone',
          'Depos',
          'Next visit dates',
          'Routes'
        ]}
        data={this.state.customers}
      />
    )
  }

  renderForm(customer) {
    if (isIsbilen && customer.editOpen) {
      return <React.Fragment>{this.renderCustomerEditForm(customer)}</React.Fragment>
    }

    return (
      <React.Fragment>
        {this.renderCustomerDetails(customer)}
        {this.renderEditAndDelete(customer)}
      </React.Fragment>
    )
  }

  openCustomerEdit(customer) {
    const copyCustomers = this.state.customers.slice(0)
    const customerIndex = copyCustomers.findIndex((c) => c.id === customer.id)
    copyCustomers[customerIndex].editOpen = true
    this.setState({ customers: copyCustomers })
  }

  deleteCustomer(customer) {
    this.startLoading()
    axios
      .delete(Routes.admin_b2b_customer_path(tenant, customer.id, { format: 'json' }))
      .then((res) => {
        this.loadPage()
        this.stopLoading()
      })
      .catch((err) => {
        this.setMessage(`${err}`)
      })
  }

  renderEditAndDelete(customer) {
    if (isIsbilen) {
      return (
        <React.Fragment>
          <Button
            style={{ margin: 10 }}
            onClick={this.openCustomerEdit.bind(this, customer)}
            vairant='contained'
            color='primary'
          >
            Edit
          </Button>
          <Button onClick={this.deleteCustomer.bind(this, customer)} vairant='contained' color='secondary'>
            Delete
          </Button>
        </React.Fragment>
      )
    }
  }

  renderCreateButton() {
    if (!isIsbilen) return
    return (
      <IconButton
        style={{ background: "#ff4081", marginLeft: 'auto', marginRight: '40px' }}
        className='new-b2b-cus'
        onClick={() => this.setState({ open: true })}
      >
        <Add style={{ color: '#fff' }} />
      </IconButton>
    )
  }

  handleClose() {
    this.setState({ open: false })
  }

  handleInputChange(e) {
    this.setState({
      form_values: {
        ...this.state.form_values,
        [e.target.name]: e.target.value
      }
    })
  }

  handleFormSubmit() {
    this.startLoading()
    axios
      .post(Routes.admin_b2b_customers_path(tenant, { format: 'json' }), this.state.form_values)
      .then((res) => {
        this.setState({
          customers: [...this.state.customers, res.data],
          open: false,
          form_values: [],
          message: 'B2B Customer Saved'
        })
        this.stopLoading()
      })
      .catch((error) => {
        this.setMessage(error)
        this.stopLoading()
      })
  }

  renderCreateModal() {
    return (
      <ModalWrapper
        open={this.state.open}
        classes='eod-modal'
        style={{ width: 350, height: 185 }}
        title='Create new B2B Customer'
        close={this.handleClose.bind(this)}
      >
        <Input
          style={{ width: '90%', marginBottom: 10 }}
          onChange={this.handleInputChange.bind(this)}
          placeholder='Name'
          name='customer_name'
        />
        <Input
          style={{ width: '90%', marginBottom: 10 }}
          onChange={this.handleInputChange.bind(this)}
          name='customer_number'
          placeholder='Account Number'
        />
        <Button onClick={this.handleFormSubmit.bind(this)} vairant='contained' color='primary'>
          Save
        </Button>
      </ModalWrapper>
    )
  }

  handleEditInputChange(customer, e) {
    const field = e.target.name
    const copyCustomers = this.state.customers.slice(0)
    const customerIndex = copyCustomers.findIndex((c) => c.id === customer.id)
    copyCustomers[customerIndex][field] = e.target.value
    this.setState({ customers: copyCustomers })
  }

  handleEditFormSubmit(customer) {
    this.startLoading()
    axios
      .put(Routes.admin_b2b_customer_path(tenant, customer.id, { format: 'json' }), customer)
      .then((res) => {
        this.loadPage()
        this.setMessage('B2B Customer Saved')
        this.stopLoading()
      })
      .catch((error) => {
        this.setMessage(error)
        this.stopLoading()
      })
  }

  renderCustomerEditForm(customer) {
    return (
      <Grid container className='pt-3 pb-3'>
        {[
          'Customer Number',
          'Zip Code',
          'Customer Phone number 1',
          'Shop ID',
          'Customer Phone number 2',
          'Sales Consultant',
          'Notes about customer',
          'Notes to customer',
          'Email',
          'Sales district name',
          'Season',
          'Payment method',
          'Payment term',
          'Customer Name 2',
          'Address',
          'Opening Week',
          'Shop Open at',
          'Shop Close at'
        ].map((item, index) => {
          const field_name = snakeCase(item).toLowerCase()
          return (
            <Grid className='pl-4 pb-1' key={index} item xs={12} sm={12} md={12} lg={6}>
              <label style={{ width: 150, display: 'inline-block' }} htmlFor={field_name}>
                {item}:
              </label>
              <Input
                value={customer[field_name] || ''}
                onChange={this.handleEditInputChange.bind(this, customer)}
                name={field_name}
                style={{ marginLeft: 10 }}
              />
            </Grid>
          )
        })}
        <Button onClick={this.handleEditFormSubmit.bind(this, customer)} vairant='contained' color='primary'>
          Save
        </Button>
      </Grid>
    )
  }

  renderCustomerDetails(customer) {
    return (
      <Grid container className='pt-3 pb-3'>
        {[
          'Customer Number',
          'Customer Phone number 2',
          'Sales Consultant',
          'Opening Week',
          'Shop Open at',
          'Shop Close at',
          'Notes about customer',
          'Notes to customer',
          'Email',
          'Sales district name',
          'Season',
          'Payment method',
          'Payment term',
          'Customer Name 2'
        ].map((item, index) => {
          return (
            <Grid className='pl-4 pb-1' key={index} item xs={12} sm={12} md={12} lg={6}>
              <strong>{item}:</strong> {customer[snakeCase(item).toLowerCase()]}
            </Grid>
          )
        })}
      </Grid>
    )
  }

  renderFlashNotification() {
    const { full_errors, message } = this.state
    return (
      <div onClick={() => this.setMessage(null)}>
        <FlashNotification message={message} />
      </div>
    )
  }

  renderSearchField() {
    return (
      <div className='search-field inline'>
        <FunSearchFieldWithoutRedux
          withoutSearchLimit={true}
          setFlash={this.setMessage.bind(this)}
          clearSearch={this.clearSearch.bind(this)}
          handleSubmit={this.handleSearch.bind(this)}
        />
      </div>
    )
  }

  handleDepoSelect(e) {
    this.setState({ selectedDepoId: e.target.value }, this.fetchStatusSizes)
  }

  handleStatusChange(e) {
    this.setState({ selectedStatus: e.target.value }, this.fetchStatusSizes)
  }

  renderDeposSelect() {
    const { depos, selectedDepoId } = this.state

    return (
      <Select
        classNames='depos-select'
        additionalStyles={{ width: 200, marginLeft: 10, marginRight: 10 }}
        options={depos}
        selectedOption={selectedDepoId}
        optionLabel='title'
        label='Depo'
        handleSelect={this.handleDepoSelect.bind(this)}
      />
    )
  }

  renderStatusSelect() {
    const { depos, selectedStatus } = this.state

    return (
      <Select
        classNames='status-select'
        additionalStyles={{ width: 200, marginLeft: 10, marginRight: 10 }}
        options={[
          { title: 'Active', id: 'active' },
          { title: 'Inactive', id: 'inactive' }
        ]}
        selectedOption={selectedStatus}
        optionLabel='title'
        label='Status'
        handleSelect={this.handleStatusChange.bind(this)}
      />
    )
  }

  renderExportButton() {
    const path = Routes.export_admin_b2b_customers_path(tenant, 'xlsx', {
      status: this.state.selectedStatus,
      depo: this.state.selectedDepoId,
      search_phrase: this.state.searchPhrase,
      tab: this.state.selectedTab
    })
    return (
      <div className='xlsx-export d-inline'>
        <Button variant='contained' color='primary' size='small' style={{ marginLeft: 20, marginRight: 10 }}>
          <Link to={path} target='_blank' style={{ color: 'white' }}>
            Export
          </Link>
        </Button>
      </div>
    )
  }

  handleTabChange(e, value) {
    this.setState({ selectedTab: value, viewChanging: true }, this.loadPage)
  }

  renderTabSwitch() {
    const { selectedTab, viewChanging, statusSizes } = this.state

    return (
      <Tabs
        indicatorColor='secondary'
        textColor='secondary'
        className='w-100 mt-3'
        value={selectedTab}
        centered
        onChange={this.handleTabChange.bind(this)}
      >
        <Tab className='all-tab' value='all' label={`All (${statusSizes[0]})`} disabled={viewChanging} />
        <Tab className='in-route-tab' value='in_route' label={`In Route (${statusSizes[1]})`} disabled={viewChanging} />
        <Tab
          className='not-in-route-tab'
          value='not_in_route'
          label={`Not in route (${statusSizes[2]})`}
          disabled={viewChanging}
        />
        <Tab
          className='in-calendar-tab'
          value='in_calendar'
          label={`In calendar (${statusSizes[3]})`}
          disabled={viewChanging}
        />
        <Tab
          className='not-in-calendar-tab'
          value='not_in_calendar'
          label={`Not in Calendar (${statusSizes[4]})`}
          disabled={viewChanging}
        />
      </Tabs>
    )
  }

  render() {
    return (
      <div className='content-container'>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Typography variant='h5' className='mt-2 mr-2 float-left'>
            B2B Customers
          </Typography>
          {this.renderStatusSelect()}
          {this.renderDeposSelect()}
          {this.renderSearchField()}
          {this.renderExportButton()}
          {this.renderCreateButton()}
        </div>
        {this.renderPagination()}
        {this.renderTabSwitch()}
        {this.renderTable()}
        {this.renderLoading()}
        {this.renderFlashNotification()}
        {this.renderCreateModal()}
      </div>
    )
  }
}

export default CustomersTable
