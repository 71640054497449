import React from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import axios from 'axios'
import pickBy from 'lodash/pickBy'
import some from 'lodash/some'

import { setFlash, updateResource, loadResources } from '../../files/actions/index'
import extractDataErrors from '../support/extractDataErrors'
import FunTextField from '../forms/FunTextField'
import SaveButton from '../shared/SaveButton'
import ConfirmationDialog from '../shared/ConfirmationDialog'
import EndOfDayReportPaper from './EndOfDayReportPaper'

class EndOfDayReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props.endOfDayReport,
      loading: false,
      editing: false,
      approving: false,
      saving: false,
      fieldsEditDisabled: false,
      errors: {},
      confirmOpen: false,
      receivedCardErrors: false,
      receivedOtherErrors: false,
      notEnoughStockError: false,
      receivedMiscellaneousErrors: false,
      errorsReceived: false,
      notEnoughStock: [],
      errorWarehouse: {}
    }
  }

  editableFields(prefix, suffix) {
    const editableFields = [
      'end_cash', 'start_cash', 'card', 'mobile_pay', 'end_check', 'end_vipps',
      'end_voucher', 'end_gift_card', 'end_bank', 'bag_number', 'start_at', 'end_at',
      'local_card_amount', 'other_card_amount', 'allocated_mobile_pay', 'invoice', 'end_credit_sales',
      'end_market_sales', 'end_vipps_timeout', 'end_customer_sales', 'misc_gift_card', 'end_prepaid'
    ]
    return editableFields.map((editingField) => {
      return prefix + editingField + suffix
    })
  }

  url() {
    return Routes.end_of_day_report_path(window.tenant, window.depoId, this.state.id, { format: 'json' })
  }

  approveData() {
    return {
      format: 'json',
      end_of_day_report: {
        status: this.state.status
      }
    }
  }

  pickedData() {
    return pickBy(this.state, (value, key) => {
      return this.editableFields('edited_', '').indexOf(key) !== -1
    })
  }

  saveData() {
    return {
      format: 'json',
      end_of_day_report: {
        ...this.pickedData()
      }
    }
  }

  handleSubmit(type) {
    const data = type === 'save' ? this.saveData() : this.approveData()
    this.startLoading()
    axios({
      method: 'patch',
      url: this.url(),
      data: data
    })
      .then((res) => {
        if (type === 'save') {
          this.setState({ saving: false })
          if (!res.data.plan_event_id) {
            this.props.setFlash('EOD report unlinked from plan event.')
          }
          this.refs.EndOfDayReportPaper.resetEditableFieldsStateAfterSave()
        }
        this.props.updateEndOfDayReport(res.data)
        this.stopLoading()
        this.clearErrors()
        this.errorsHandled()
        if (type === 'approve') {
          const endOfDayReports = this.props.endOfDayReports.slice(0)
          const index = endOfDayReports.findIndex((eod) => eod.id === this.state.id)
          endOfDayReports.splice(index, 1)
          this.props.loadEndOfDayReports(endOfDayReports)
        }
      })
      .catch((error) => {
        this.handleSubmitError(error)
        this.stopLoading()
      })
  }

  receivedCardErrors() {
    this.setState({
      receivedCardErrors: true,
      confirmOpen: true,
      errorsReceived: true
    })
  }

  receivedOtherErrors() {
    this.setState({ receivedOtherErrors: true, confirmOpen: true })
  }

  receivedMiscellaneousErrors() {
    this.setState({
      receivedMiscellaneousErrors: true,
      confirmOpen: true,
      errorsReceived: true
    })
  }

  errorsHandled() {
    this.setState({ receivedCardErrors: false })
  }

  handleChange(e, value) {
    this.setState({ [value]: e.target.value })
  }

  setInputErrors(error) {
    const allErrors = error.response.data
    const errors = {}

    Object.keys(allErrors.errors).forEach((key) => {
      errors[key] = allErrors.errors[key][0]
    })

    if (Object.keys(allErrors.errors).includes('wallmob_end_miscellaneous_amount')) {
      errors['edited_end_vipps'] = 'error'
      errors['edited_end_voucher'] = 'error'
      errors['edited_end_gift_card'] = 'error'
    }

    this.setState({ errors, saving: true, status: 'pending_edited' })
  }

  clearErrors() {
    this.setState({ errors: {} })
  }

  handleSubmitError(error) {
    this.setState(extractDataErrors(error))
    const errorData = error.response.data

    if (errorData && errorData.errors && Object.keys(errorData.errors).join(' ').includes('card')) {
      this.receivedCardErrors()
      this.setInputErrors(error)
    } else if (errorData && errorData.errors && Object.keys(errorData.errors).join(' ').includes('miscellaneous')) {
      this.receivedMiscellaneousErrors()
      this.setInputErrors(error)
    } else if (errorData && errorData.errors && errorData.errors.full_errors) {
      this.receivedOtherErrors()
      this.setInputErrors(error)
    } else if (errorData && Array.isArray(errorData.custom_error)) {
      this.setState({
        notEnoughStockError: true,
        notEnoughStock: errorData.custom_error,
        confirmOpen: true,
        saving: false,
        status: 'pending',
        errorWarehouse: errorData.warehouse
      })
    }

    error.response.status == 422 && this.state.full_errors.length > 0
      ? this.props.setFlash(`${this.state.full_errors && this.state.full_errors.join(', ')}`)
      : this.props.setFlash(`${error} (${this.url()})`)
  }

  startLoading() {
    this.setState({ loading: true })
  }

  stopLoading() {
    this.setState({ loading: false })
  }

  toggleSaving(field) {
    this.setState({
      saving: some(this.editableFields('', '_editing'), (field) => this.state[field])
    })
  }

  updateEditedField(field, value, type) {
    this.setState({ [field]: value }, () => {
      if (type == 'editing') this.toggleSaving(field)
    })
  }

  handleApprove() {
    this.setState({ status: 'approved' }, this.handleSubmit.bind(this, 'approve'))
  }

  isStatusPending() {
    return this.state.status.startsWith('pending')
  }

  renderLoading() {
    if (this.state.loading) {
      return (
        <CircularProgress
          className='loading-spinner'
          size={40}
          style={{
            position: 'absolute',
            zIndex: '999',
            marginLeft: '50%',
            top: 'calc(50% - 20px)'
          }}
        />
      )
    }
  }

  renderSaveButton() {
    // EOD simplification (Auto approve) for Hillerod for now
    if (depoId == 2 && isHjemis) return

    return <SaveButton disabled={!this.state.saving} onClick={this.handleSubmit.bind(this, 'save')} customText="SAVE" />
  }

  renderApproveButton() {
    return <SaveButton disabled={this.state.saving} onClick={this.handleApprove.bind(this)} customText='APPROVE' />
  }

  renderActions() {
    if (!this.props.planEventExists()) return
    return (
      <div className='eod-actions'>
        {this.isStatusPending() && this.renderApproveButton()}
        &nbsp;
        {this.renderSaveButton()}
      </div>
    )
  }

  renderControls() {
    if (!this.state.editing) {
      return (
        <div className='content-container end-of-day-report-container end-of-day-report-controls clearfix'>
          {this.renderActions()}
        </div>
      )
    }
  }

  renderPaper() {
    if (!this.state.editing) {
      return (
        <EndOfDayReportPaper
          planEventExists={this.props.planEventExists}
          prefix='final_'
          endOfDayReport={{ ...this.state, ...this.props.endOfDayReport }}
          updateEditedField={this.updateEditedField.bind(this)}
          errors={this.state.errors}
          errorsReceived={this.state.errorsReceived}
          errorsHandled={this.errorsHandled.bind(this)}
          ref='EndOfDayReportPaper'
        />
      )
    }
  }

  renderOtherErrors() {
    return (
      <React.Fragment>
        EOD for this trip has already been approved. Please change EOD open date or create EOD manually
      </React.Fragment>
    )
  }

  renderCardErrors() {
    const { edited_local_card_amount, edited_other_card_amount, edited_card, card } = this.state
    const finalCard = parseFloat(edited_card || card) || 0.0
    const finalLocalCard = parseFloat(edited_local_card_amount) || 0.0
    const finalOtherCard = parseFloat(edited_other_card_amount) || 0.0

    return (
      <React.Fragment>
        1. Highlighted input fields must be filled in
        <br />
        2. Dankort card amount and other card amount must match your total card amount
        <br />
        3. Dankort card amount: <b>{finalLocalCard.toFixed(2)} DKK</b>
        <br />
        4. Other card amount: <b>{finalOtherCard.toFixed(2)} DKK</b>
        <br />
        5. Card amount: <b>{finalCard.toFixed(2)} DKK</b>
        <br />
        6. You are missing <b>{(finalCard - finalLocalCard - finalOtherCard).toFixed(2)} DKK</b>
      </React.Fragment>
    )
  }

  renderWarehouseErrors() {
    const { notEnoughStock, errorWarehouse } = this.state

    return (
      <React.Fragment>
        {notEnoughStock.map((stock, index) => {
          return (
            <span style={{ display: 'block' }} key={index}>
              {index + 1}. Product {stock.product_sku} is missing <b>{stock.amount}</b> additional stock
            </span>
          )
        })}
        <a
          style={{ display: 'block' }}
          href={Routes.new_inventory_warehouse_stock_transfers_path(window.tenant, window.depoId, errorWarehouse.id, {
            kind: 'win_lose'
          })}
          target='_blank'
        >
          Go here to create a transfer for missing amount
        </a>
      </React.Fragment>
    )
  }

  renderMiscellaneousErrors() {
    const { edited_end_vipps, edited_end_voucher, edited_end_gift_card, total_miscellaneous } = this.state

    const finalVipps = parseFloat(edited_end_vipps) || 0.0
    const finalGiftCard = parseFloat(edited_end_gift_card) || 0.0
    const finalOnlineBuy = parseFloat(edited_end_voucher) || 0.0
    const total = parseFloat(total_miscellaneous) || 0.0

    return (
      <React.Fragment>
        1. Highlighted input fields must be filled in
        <br />
        2. Miscellaneous fields must match the total
        <br />
        3. Gift card: <b>{finalGiftCard.toFixed(2)} DKK</b>
        <br />
        4. Customer club: <b>{finalVipps.toFixed(2)} DKK</b>
        <br />
        5. Online buy: <b>{finalOnlineBuy.toFixed(2)} DKK</b>
        <br />
        6. You are missing <b>{(total - finalVipps - finalGiftCard - finalOnlineBuy).toFixed(2)} DKK</b>
      </React.Fragment>
    )
  }

  renderConfimationContent() {
    const { receivedOtherErrors, receivedCardErrors, notEnoughStockError, receivedMiscellaneousErrors } = this.state

    if (receivedOtherErrors) {
      return this.renderOtherErrors()
    } else if (receivedCardErrors) {
      return this.renderCardErrors()
    } else if (notEnoughStockError) {
      return this.renderWarehouseErrors()
    } else if (receivedMiscellaneousErrors) {
      return this.renderMiscellaneousErrors()
    }
  }

  renderConfirmationDialog() {
    const { receivedCardErrors, notEnoughStockError, confirmOpen, errorWarehouse, receivedMiscellaneousErrors } =
      this.state
    let title = "Can't approve EOD report"
    if (receivedCardErrors) {
      title = 'Card amount must be equals to Dankort card amount + other card amount'
    } else if (notEnoughStockError) {
      title = `There is not enough stock in warehouse ${errorWarehouse.code}`
    } else if (receivedMiscellaneousErrors) {
      title = 'Miscellaneous fields must match the total'
    }

    return (
      <ConfirmationDialog
        open={confirmOpen}
        close={this.closeConfirmationDialog.bind(this)}
        leave={this.closeConfirmationDialog.bind(this)}
        title={title}
        content={this.renderConfimationContent()}
        showCancelButton={false}
        confirmAndCloseText='CLOSE'
      />
    )
  }

  closeConfirmationDialog() {
    this.setState({ confirmOpen: false })
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        {this.renderConfirmationDialog()}
        {this.renderLoading()}
        {this.renderControls()}
        {this.renderPaper()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    endOfDayReports: state.resources
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFlash: (flash) => dispatch(setFlash(flash)),
    updateEndOfDayReport: (endOfDayReport) => dispatch(updateResource(endOfDayReport)),
    loadEndOfDayReports: (endOfDayReports) => dispatch(loadResources(endOfDayReports))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EndOfDayReport)
