import React from 'react'
import axios from 'axios'
import ReactDataGrid from 'react-data-grid'

import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'

import DataGrid from '../../shared/DataGrid'
import BackButtons from '../shared/BackButtons'

import moment from 'moment'

import FlashNotification from '../../shared/FlashNotification'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'

class ManualCountingDocumentForm extends React.Component {
  state = { rows: [{}], errors: {}, manualCountingDocument: {}, loading: false, message: null, date: null, isSubmitting: false }

  componentDidMount() {
    this.startLoading()
    axios
      .get(this.url())
      .then((res) => {
        this.setState({
          rows: res.data.manual_counting_document_line_items.map((li) => ({
            id: li.id,
            sku: li.sku,
            description: li.description,
            old_value: li.old_value,
            new_value: li.new_value !== null ? li.new_value : li.old_value
          })),
          manualCountingDocument: res.data,
          loading: false
        })
      })
      .catch((err) => {
        this.setState({ message: `${err}` })
      })
  }

  stopLoading() {
    this.setState({ loading: false })
  }

  startLoading() {
    this.setState({ loading: true })
  }

  url() {
    return Routes.inventory_warehouse_manual_counting_document_path(
      tenant,
      depoId,
      this.warehouseId(),
      this.manualCountingDocumentId(),
      { format: 'json' }
    )
  }

  urlWithoutJson() {
    return Routes.inventory_warehouse_manual_counting_document_path(
      tenant,
      depoId,
      this.warehouseId(),
      this.manualCountingDocumentId()
    )
  }

  warehouseId() {
    return this.props.match.params.warehouseId
  }

  manualCountingDocumentId() {
    return this.props.match.params.manualCountingDocumentId
  }

  onGridRowsUpdated({ fromRow, toRow, updated }) {
    this.setState((state) => {
      const rows = state.rows.slice()

      for (let i = fromRow; i <= toRow; i++) {
        rows[i] = { ...rows[i], ...updated }
      }
      return { rows }
    })
  }

  returnGridColumns() {
    return [
      { key: 'id', name: 'ID', editable: false },
      { key: 'sku', name: 'Sku', editable: false },
      { key: 'description', name: 'Description', editable: false },
      { key: 'old_value', name: 'Old value', editable: false },
      { key: 'new_value', name: 'New value', editable: true }
    ]
  }

  renderMainTitle() {
    if (this.state.manualCountingDocument.warehouse) {
      const {
        manualCountingDocument: {
          id,
          warehouse: { owner, owner_type }
        }
      } = this.state
      return (
        <Typography variant='h5'>
          Document #{id}, Warehouse - {owner} ({owner_type})
        </Typography>
      )
    }
  }

  handleSubmitSuccess(response) {
    this.stopLoading()
    this.props.history.push(this.urlWithoutJson())
  }

  handleSubmitError(error) {
    this.setState({
      message: error.response.data.full_errors && `${error.response.data.full_errors.join(', ')}`,
      loading: false
    })
  }

  data() {
    return {
      manual_counting_document_line_items_attributes: this.state.rows.map((r) => {
        return { id: r.id, new_value: r.new_value }
      })
    }
  }

  updateIsSubmiting() {
    this.setState({ isSubmitting: false })
  }

  submit() {
    if (this.state.isSubmitting) return;
    this.setState({ isSubmitting: true });

    this.startLoading()
    axios.put(this.url(), { manual_counting_document: this.data() }).then(
      this.handleSubmitSuccess.bind(this)
    ).catch(
      this.handleSubmitError.bind(this)
    )

    setTimeout(this.updateIsSubmiting.bind(this), 2000)
  }

  renderSubmitButton() {
    return (
      <Button className="ml-3" variant="contained" color="primary" onClick={this.submit.bind(this)} disabled={this.state.isSubmitting}>Save document</Button>
    )
  }

  renderBackButtons() {
    return <BackButtons warehouseId={this.warehouseId()} parent='Manual Countings' label='Manual Counting' />
  }

  renderContent() {
    if (!this.state.loading) {
      return (
        <React.Fragment>
          <div className='content-header'>
            <div className='content-header-title mb-3'>
              {this.renderBackButtons()}
              {this.renderMainTitle()}
              {this.renderSubmitButton()}
            </div>
          </div>
          <DataGrid
            onGridRowsUpdated={this.onGridRowsUpdated.bind(this)}
            columns={this.returnGridColumns()}
            rows={this.state.rows}
          />
        </React.Fragment>
      )
    }
  }

  render() {
    return (
      <div>
        <FlashNotification message={this.state.message} />
        <LoadingWithoutRedux loading={this.state.loading} />
        {this.renderContent()}
      </div>
    )
  }
}

export default ManualCountingDocumentForm
