import React from 'react'
import { Provider } from 'react-redux'
import store from '../../../files/store'
import AdminStopTypesIndex from './AdminStopTypesIndex'
import ErrorCatcher from '../../shared/ErrorCatcher'

const StopTypesIndexWithStore = () => {
  return (
    <Provider store={store}>
      <ErrorCatcher>
        <AdminStopTypesIndex />
      </ErrorCatcher>
    </Provider>
  )
}

export default StopTypesIndexWithStore
