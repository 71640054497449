import store from '../store/index'

import {
  SET_MAIN_TITLE,
  SET_FLASH,
  START_LOADING,
  STOP_LOADING,
  LOAD_RESOURCES,
  UPDATE_RESOURCE,
  LOAD_ROAD_POINTS,
  UPDATE_POINT,
  CLEAR_ROUTE_POINTS,
  LOAD_CAR_STOPS,
  LOAD_CAR_ROUTES,
  LOAD_CARS,
  LOAD_USERS,
  LOAD_ROUTES,
  LOAD_DEPOS,
  LOAD_USER_HTTP_REQUESTS,
  DELETE_RESOURCE,
  PLAN_EVENTS_FOR_CALENDAR,
  LOAD_ORDER_TRANSACTIONS,
  CREATE_ROUTE_POINT_FROM_MAP,
  LOAD_ROUTE,
  COPY_RESOURCE,
  LOAD_PLAN_EVENT,
  ZOOM_ON_POINT,
  UPDATE_AFTER_MOVE,
  PLACE_ROUTE_POINTS_ON_MAP,
  CLEAR_ROUTE_POINTS_ON_MAP,
  LOAD_PRINT_PREVIEW_STATUS,
  UPDATE_PRINT_GRID_ROW_COUNT,
  UPDATE_PRINT_GRID_COLUMN_COUNT,
  SET_MAP_PRINTING_STATUS,
  CANCEL_PRINT_PREVIEW,
  LOAD_UNPLANNED_SALES,
  CLEAR_UNPLANNED_SALES,
  LOAD_ROUTE_MINIMUM_SALES_EFFICIENT_COUNT,
  UPDATE_SIBLINGS,
  LOAD_STOP_TYPES,
  LOAD_CUSTOMERS,
  CLEAR_CUSTOMERS,
  LOAD_SIMILAR_ROUTES
} from '../constants/action-types'

export const setMainTitle = (title) => ({
  type: SET_MAIN_TITLE,
  payload: title
})
export const setFlash = (flash) => ({ type: SET_FLASH, payload: flash })
export const startLoading = () => ({ type: START_LOADING, payload: true })
export const stopLoading = () => ({ type: STOP_LOADING, payload: false })
export const loadResources = (resources) => ({
  type: LOAD_RESOURCES,
  payload: resources
})
export const updateResource = (resource) => ({
  type: UPDATE_RESOURCE,
  payload: resource
})
export const copyResource = (resource, previousResource) => ({
  type: COPY_RESOURCE,
  payload: [resource, previousResource]
})
export const loadRoadPoints = (points) => ({
  type: LOAD_ROAD_POINTS,
  payload: points
})
export const clearRoutePoints = () => ({ type: CLEAR_ROUTE_POINTS })
export const createRoutePointFromMap = (point) => ({
  type: CREATE_ROUTE_POINT_FROM_MAP,
  payload: point
})
export const updatePoint = (point, field) => ({
  type: UPDATE_POINT,
  payload: point
})
export const updateAfterMove = (points) => ({
  type: UPDATE_AFTER_MOVE,
  payload: points
})
export const updateSiblings = (waitTime) => ({
  type: UPDATE_SIBLINGS,
  payload: waitTime
})
export const loadCarStops = (carStops) => ({
  type: LOAD_CAR_STOPS,
  payload: carStops
})
export const loadCarRoutes = (carRoutes) => ({
  type: LOAD_CAR_ROUTES,
  payload: carRoutes
})
export const loadCars = (cars) => ({ type: LOAD_CARS, payload: cars })
export const loadUsers = (users) => ({ type: LOAD_USERS, payload: users })
export const loadRoutes = (routes) => ({ type: LOAD_ROUTES, payload: routes })
export const loadRoute = (route) => ({ type: LOAD_ROUTE, payload: route })
export const loadDepos = (depos) => ({ type: LOAD_DEPOS, payload: depos })
export const loadUserHttpRequests = (userHttpRequests) => ({
  type: LOAD_USER_HTTP_REQUESTS,
  payload: userHttpRequests
})
export const loadPlanEvent = (planEvent) => ({
  type: LOAD_PLAN_EVENT,
  payload: planEvent
})
export const deleteResource = (resource) => ({
  type: DELETE_RESOURCE,
  payload: resource
})
export const loadPlanEventsForCalendar = (planEvents) => ({
  type: PLAN_EVENTS_FOR_CALENDAR,
  payload: planEvents
})
export const loadOrderTransactions = (orderTransactions) => ({
  type: LOAD_ORDER_TRANSACTIONS,
  payload: orderTransactions
})
export const zoomOnPoint = (point) => ({ type: ZOOM_ON_POINT, payload: point })
export const placeRoutePointsOnMap = (points, routeId) => ({
  type: PLACE_ROUTE_POINTS_ON_MAP,
  payload: [points, routeId]
})
export const clearRoutePointsOnMap = () => ({
  type: CLEAR_ROUTE_POINTS_ON_MAP,
  payload: null
})
export const loadPrintPreviewStatus = (printPreview) => ({
  type: LOAD_PRINT_PREVIEW_STATUS,
  payload: printPreview
})
export const updatePrintGridColumnCount = (printGridColumnCount) => ({
  type: UPDATE_PRINT_GRID_COLUMN_COUNT,
  payload: printGridColumnCount
})
export const updatePrintGridRowCount = (printGridRowCount) => ({
  type: UPDATE_PRINT_GRID_ROW_COUNT,
  payload: printGridRowCount
})
export const cancelPrintPreview = () => ({
  type: CANCEL_PRINT_PREVIEW,
  payload: null
})
export const setMapPrintingStatus = (mapPrinting) => ({
  type: SET_MAP_PRINTING_STATUS,
  payload: { mapPrinting }
})
export const setMapPrintingProgress = (printedPages, totalPrintingPages) => ({
  type: SET_MAP_PRINTING_STATUS,
  payload: { printedPages, totalPrintingPages }
})
export const loadUnplannedSales = (unplannedSales) => ({
  type: LOAD_UNPLANNED_SALES,
  payload: unplannedSales
})
export const clearUnplannedSales = () => ({ type: CLEAR_UNPLANNED_SALES })
export const loadCustomers = (customers) => ({
  type: LOAD_CUSTOMERS,
  payload: customers
})
export const clearCustomers = (customers) => ({
  type: CLEAR_CUSTOMERS,
  payload: []
})
export const loadRouteMinimumSalesEfficientCount = (routeMinimumSalesEfficientCount) => ({
  type: LOAD_ROUTE_MINIMUM_SALES_EFFICIENT_COUNT,
  payload: routeMinimumSalesEfficientCount
})
export const loadStopTypes = (stopTypes) => ({
  type: LOAD_STOP_TYPES,
  payload: stopTypes
})
export const loadSimilarRoutes = (similarRoutes) => ({
  type: LOAD_SIMILAR_ROUTES,
  payload: similarRoutes
})
export const createPlanEvents = (newPlanEvents) => {
  const planEvents = [...store.getState().planEvents, ...newPlanEvents]

  return { type: PLAN_EVENTS_FOR_CALENDAR, payload: planEvents }
}

export const destroyPlanEvents = (planEvents) => {
  const planEventIds = planEvents.map((pe) => pe.id)
  const newPlanEvents = store.getState().planEvents.filter((pe) => !planEventIds.includes(pe.id))
  return { type: PLAN_EVENTS_FOR_CALENDAR, payload: newPlanEvents }
}

export const destroyPlanEventWithSeries = (planEvent) => {
  const planEventSeriesId = parseInt(planEvent.series_id)

  const newPlanEvents = store.getState().planEvents.filter((pe) => {
    return parseInt(pe.series_id) !== planEventSeriesId || !pe.can_be_deleted
  })

  return { type: PLAN_EVENTS_FOR_CALENDAR, payload: newPlanEvents }
}

export const editPlanEvent = (planEvent) => {
  const planEvents = store.getState().planEvents
  const index = planEvents.findIndex((pe) => pe.id === planEvent.id)

  planEvents[index] = planEvent

  return { type: PLAN_EVENTS_FOR_CALENDAR, payload: planEvents }
}
