import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import ModeEdit from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'

const CarsTableRow = ({ car, index, classes }) => {
  const {
    id,
    status,
    internal_number,
    mobile_number,
    plate_number,
    axapta_code,
    depo_title,
    mapon_car_id,
    latest_location,
    latest_location_updated_at,
    last_synced_at
  } = car
  return (
    <TableRow hover>
      <TableCell>{id}</TableCell>
      <TableCell>{internal_number}</TableCell>
      <TableCell>{mobile_number}</TableCell>
      <TableCell>{plate_number}</TableCell>
      <TableCell>{axapta_code}</TableCell>
      <TableCell>{depo_title}</TableCell>
      <TableCell>{mapon_car_id}</TableCell>
      <TableCell>{latest_location && JSON.stringify(latest_location)}</TableCell>
      <TableCell>{latest_location_updated_at}</TableCell>
      <TableCell>{last_synced_at}</TableCell>
      <TableCell>
        <Link
          className={`mdl-button mdl-js-button mdl-button--icon car-status-${status}`}
          to={Routes.edit_admin_cars_path(tenant, depoId, id)}
        >
          <ModeEdit />
        </Link>
      </TableCell>
    </TableRow>
  )
}

export default CarsTableRow
