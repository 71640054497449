import React from 'react'
import axios from 'axios'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'

import Table from '@material-ui/core/Table'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'

import FeedbackTableHead from './FeedbackTableHead'
import FeedbackTableRow from './FeedbackTableRow'
import LoadingWithoutRedux from '../shared/LoadingWithoutRedux'
import FlashNotification from '../shared/FlashNotification'
import SelectFilter from './SelectFilter'
import DateRangeFilter from './DateRangeFilter'
import extractDataErrors from '../support/extractDataErrors'
import ConfirmationDialog from '../shared/ConfirmationDialog'

const initialState = {
  loading: false,
  message: null,
  feedbackView: 0,
  currentPage: 1,
  startDate: moment().startOf('month').format('DD-MM-YYYY'),
  endDate: moment().endOf('month').format('DD-MM-YYYY')
}

export default class FeedbackTable extends React.Component {
  state = {
    feedbacks: [],
    routes: [],
    drivers: [],
    confirmOpen: false,
    ...initialState
  }

  componentDidMount() {
    this.fetchAssociated()
    this.loadPage()
  }

  loadPage() {
    this.setState({
      loading: true,
      paginationDisabled: true,
      viewChanging: true,
      clearFilters: false
    })
    axios
      .get(this.url())
      .then((res) => {
        this.setState({
          feedbacks: res.data,
          paginationDisabled: false,
          viewChanging: false,
          message: null
        })
        this.stopLoading()
      })
      .catch((err) => {
        this.stopLoading()
        this.setState({
          message: `${err}`,
          paginationDisabled: false,
          viewChanging: false
        })
      })
  }

  fetchAssociated() {
    this.startLoading()
    axios
      .all([
        axios.get(Routes.drivers_feedbacks_path(tenant, depoId, { format: 'json' })),
        axios.get(Routes.routes_feedbacks_path(tenant, depoId, { format: 'json' }))
      ])
      .then(
        axios.spread((driversResponse, routesResponse) => {
          this.setState({
            drivers: driversResponse.data,
            routes: routesResponse.data
          })
          this.stopLoading()
        })
      )
      .catch((err) => {
        this.setState({ message: `${err}` })
        this.stopLoading()
      })
  }

  url() {
    const { startDate, endDate, currentPage, feedbackView } = this.state
    const selectedRoutes = this.refs.routesFilter.state.selectedCollection
    const selectedDrivers = this.refs.driversFilter.state.selectedCollection
    const status = feedbackView === 0 ? 'pending' : 'approved'
    return Routes.feedbacks_path(tenant, depoId, {
      format: 'json',
      page: currentPage,
      start_date: startDate,
      end_date: endDate,
      driver_ids: selectedDrivers,
      route_ids: selectedRoutes,
      status: status
    })
  }

  submitFeedback(id, data) {
    this.startLoading()
    const duplicates = cloneDeep(this.state.feedbacks)
    const index = duplicates.findIndex((f) => f.id == id)
    const duplicateFeedback = duplicates[index]

    axios
      .put(Routes.feedback_path(tenant, depoId, id, { format: 'json' }), data)
      .then((res) => {
        duplicateFeedback.manager_comment = res.data.manager_comment
        duplicateFeedback.handled_by_full_name = res.data.handled_by_full_name
        duplicateFeedback.handled_by_at = res.data.handled_by_at
        duplicateFeedback.status = res.data.status
        this.setState({ feedbacks: duplicates, message: null })
        this.stopLoading()
      })
      .catch((err) => {
        const message = extractDataErrors(err) && extractDataErrors(err).full_errors
        this.setState({
          message,
          confirmOpen: message && message.join(' ').includes('Manager comment')
        })
        this.stopLoading()
      })
  }

  closeConfirmationDialog = () => this.setState({ confirmOpen: false })
  handleFilter = () => this.setState({ currentPage: 1 }, this.loadPage)
  clearFilter = () => this.setState({ clearFilters: true, ...initialState }, this.loadPage)
  stopLoading = () => this.setState({ loading: false })
  startLoading = () => this.setState({ loading: true })
  handleTabChange = (e, value) => {
    const { feedbackView } = this.state
    if (feedbackView === value) return
    this.setState(
      {
        feedbackView: value,
        currentPage: 1,
        paginationDisabled: true,
        viewChanging: true
      },
      () => {
        this.loadPage()
      }
    )
  }

  handleChange(field, value) {
    this.setState({ [field]: value })
  }

  changePage(pageIncrement) {
    if (this.state.feedbacks.length < 25 && pageIncrement == 1) return

    const newPage = Math.min(Math.max(1, this.state.currentPage + pageIncrement))
    if (newPage === this.state.currentPage) return
    this.setState({ currentPage: newPage, paginationDisabled: true }, this.loadPage)
  }

  renderTableRows() {
    return this.state.feedbacks.map((feedback) => {
      return (
        <FeedbackTableRow
          submitFeedback={this.submitFeedback.bind(this)}
          key={`${feedback.id}-${feedback.status}`}
          feedback={feedback}
        />
      )
    })
  }

  renderPagination() {
    const { paginationDisabled, currentPage } = this.state
    return (
      <div className='right-action-container float-right'>
        <div className='pagination'>
          <strong>Page: {currentPage}</strong>
          &nbsp;
          <IconButton onClick={this.changePage.bind(this, -1)} disabled={paginationDisabled}>
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton onClick={this.changePage.bind(this, 1)} disabled={paginationDisabled}>
            <KeyboardArrowRight />
          </IconButton>
        </div>
      </div>
    )
  }

  renderTabSwitch() {
    const { feedbackView, viewChanging } = this.state
    return (
      <Tabs
        indicatorColor='secondary'
        textColor='secondary'
        className='w-100 mt-3'
        value={feedbackView}
        centered
        onChange={this.handleTabChange}
      >
        <Tab className='mapped-tab' value={0} label='Unapproved' disabled={viewChanging} />
        <Tab className='approved-tab' value={1} label='Approved' disabled={viewChanging} />
      </Tabs>
    )
  }

  renderDriverFilter() {
    return (
      <SelectFilter
        ref='driversFilter'
        key={`drivers-${this.state.drivers.length}`}
        collection={this.state.drivers}
        clearFilters={this.state.clearFilters}
        label='Drivers'
        collectionKey='full_name'
      />
    )
  }

  renderRoutesFilter() {
    return (
      <SelectFilter
        ref='routesFilter'
        key={`routes-${this.state.routes.length}`}
        collection={this.state.routes}
        clearFilters={this.state.clearFilters}
        label='Routes'
        collectionKey='name'
      />
    )
  }

  renderDateRangeFilter() {
    return (
      <DateRangeFilter
        handleChange={this.handleChange.bind(this)}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
      />
    )
  }

  renderConfirmationDialog() {
    return (
      <ConfirmationDialog
        open={this.state.confirmOpen}
        close={this.closeConfirmationDialog}
        leave={this.closeConfirmationDialog}
        title='Manager comment should be filled'
        content='Feedback is negative, you need to contact customer and give review about the situation'
        showCancelButton={false}
        confirmAndCloseText='CLOSE'
      />
    )
  }

  render() {
    return (
      <div className='content-container'>
        {this.renderConfirmationDialog()}
        <LoadingWithoutRedux loading={this.state.loading} />
        <FlashNotification message={this.state.message} />
        <Typography variant='h5'>Customer feedback</Typography>
        <div className='left-action-container float-left flex-with-align-end'>
          {this.renderDriverFilter()}
          {this.renderRoutesFilter()}
          {this.renderDateRangeFilter()}
          <Button onClick={this.handleFilter} variant='contained' color='primary'>
            Filter
          </Button>
          <Button onClick={this.clearFilter} variant='contained'>
            Cancel
          </Button>
        </div>
        {this.renderPagination()}
        {this.renderTabSwitch()}
        <Table>
          <FeedbackTableHead />
          {this.renderTableRows()}
        </Table>
      </div>
    )
  }
}
