import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { setMainTitle, startLoading, stopLoading, loadResources, setFlash } from '../../../files/actions/index'

import CarsTableHead from './CarsTableHead'
import CarsTableRow from './CarsTableRow'
import NewButton from '../../shared/NewButton'
import Button from '@material-ui/core/Button'
import MultiSelect from '../../shared/MultiSelect'

class CarsTableClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      statuses: ['active', 'inactive'],
      filteredStatuses: ['active']
    }
  }

  componentDidMount() {
    this.props.setMainTitle('Cars')
    this.loadPage()
  }

  loadPage() {
    this.props.startLoading()

    axios
      .get(this.url())
      .then((res) => {
        this.props.loadCars(res.data)
      })
      .catch((error) => {
        this.props.setFlash(`${error} (${this.url()})`)
      })
      .then(this.props.stopLoading())
  }

  handleFilterChange(e, value) {
    this.setState({ [value]: e.target.value })
  }

  refilter() {
    this.loadPage()
  }

  url() {
    return Routes.admin_cars_path(window.tenant, window.depoId, {
      format: 'json',
      statuses: this.state.filteredStatuses
    })
  }

  renderTableBody() {
    return (
      <TableBody>
        {this.props.cars.map((car, index) => {
          return <CarsTableRow car={car} key={index} />
        })}
      </TableBody>
    )
  }

  renderFilters() {
    return (
      <div className='cars-filter float-left'>
        <MultiSelect
          selectedValues={this.state.filteredStatuses}
          values={this.state.statuses}
          inputName='filteredStatuses'
          label='Status'
          selectClassName='status-filter'
          handleChange={this.handleFilterChange.bind(this)}
        />
        &nbsp;
        <Button
          variant='contained'
          color='primary'
          size='small'
          style={{ verticalAlign: 'bottom' }}
          onClick={this.refilter.bind(this)}
        >
          Filter
        </Button>
      </div>
    )
  }

  render() {
    return (
      <div>
        <NewButton to={Routes.new_admin_cars_path(window.tenant, window.depoId)} />
        {this.renderFilters()}
        <Table className='resources-table'>
          <CarsTableHead />
          {this.renderTableBody()}
        </Table>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    cars: state.resources
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMainTitle: (title) => dispatch(setMainTitle(title)),
    setFlash: (flash) => dispatch(setFlash(flash)),
    loadCars: (cars) => dispatch(loadResources(cars)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const CarsTable = connect(mapStateToProps, mapDispatchToProps)(CarsTableClass)

export default CarsTable
