import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import FunSearchField from '../../forms/FunSearchField'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TablePagination from '@material-ui/core/TablePagination'

import { setMainTitle, startLoading, stopLoading, setFlash } from '../../../files/actions/index'
import CustomersTableHead from './CustomersTableHead'
import CustomersTableRow from './CustomersTableRow'
import NewButton from '../../shared/NewButton'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import CustomerSupportButton from '../../shared/CustomerSupportButton'
import ModalWrapper from '../../shared/Modal'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

class CustomersTableClass extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 0,
      rowsPerPage: 25,
      customerSearch: false,
      searchPhrase: '',
      previousPage: -1,
      customers: [],
      consentFilter: '',
      templates: []
    }
  }

  componentDidMount() {
    this.props.setMainTitle('Customers')
    this.fetchCustomers()
    this.fetchTemplates()
  }

  fetchCustomers() {
    this.props.startLoading()
    axios
      .get(this.url(), { params: { page: this.state.page } })
      .then((res) => {
        this.setState({ customers: res.data })
        this.props.stopLoading()
      })
      .catch((error) => {
        this.props.setFlash(`${error} (${this.url()})`)
      })
  }

  fetchTemplates() {
    this.props.startLoading()
    axios.get(this.templatesUrl()).then(res => {
      this.setState({ templates: res.data })
      this.props.stopLoading()
    }).catch(error => {
      this.props.setFlash(`${error} (${this.url()})`)
    })

  }

  templatesUrl() {
    return Routes.admin_sms_templates_path(window.tenant, { format: 'json' })
  }

  url() {
    return Routes.admin_customers_path(window.tenant, { format: 'json' })
  }

  renderTableBody() {
    const { page, rowsPerPage } = this.state
    return (
      <TableBody>
        {this.state.customers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((customer, index) => {
          return <CustomersTableRow customer={customer} key={page * rowsPerPage + index} />
        })}
      </TableBody>
    )
  }

  handleChangePage(event, page) {
    this.props.startLoading()
    if (this.state.customerSearch) {
      this.setState({ page })
      this.props.stopLoading()
    } else {
      if (page > this.state.previousPage + 1) {
        axios
          .get(this.url(), {
            params: { page: page, consent: this.state.consentFilter }
          })
          .then((res) => {
            this.setState({
              customers: this.state.customers.concat(res.data),
              page: page,
              previousPage: page - 1
            })
            this.props.stopLoading()
          })
      } else {
        this.setState({ page })
        this.props.stopLoading()
      }
    }
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: event.target.value })
  }

  renderConsentFilter() {
    return (
      <FormControl
        component='fieldset'
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 15
        }}
      >
        <RadioGroup
          aria-label='status'
          style={{ flexDirection: 'row' }}
          name='status'
          value={this.state.consentFilter}
          onChange={this.handleRadioButtonChange.bind(this)}
        >
          <FormControlLabel value='sms' control={<Radio />} label='SMS' />
          <FormControlLabel value='marketing' control={<Radio />} label='Marketing' />
        </RadioGroup>
        <Button variant='contained' color='primary' type='submit' onClick={this.clearRadio.bind(this)}>
          RESET
        </Button>
      </FormControl>
    )
  }

  renderSmsButton() {
    return (
      <Button variant="contained" color="primary" type="submit" onClick={this.openSmsModal.bind(this)}>SMS</Button>
    )
  }

  openSmsModal() {
    this.setState({ open: true })
  }

  closeSmsModal() {
    this.setState({ open: false })
  }

  renderSmsModal() {
    return (
      <ModalWrapper
        open={this.state.open}
        classes="eod-modal"
        style={{ width: 350, height: 185 }}
        title="Select SMS template"
        close={this.closeSmsModal.bind(this)}
      >
        <Autocomplete
          options={this.availableTemplates()}
          onChange={this.handleTemplateSelect.bind(this)}
          getOptionLabel={ (option) => option.label }
          renderInput={(params) => <TextField {...params} label="SMS template" />}
        />
        <Button style={{ marginTop: 10 }} onClick={this.handleFormSubmit.bind(this)} variant="contained" color="primary">
          SEND
        </Button>
      </ModalWrapper>
    )
  }

  handleTemplateSelect(event, newValue) {
    this.setState({selectedTemplate: newValue.value})
  }

  handleFormSubmit() {
    const url = Routes.send_marketing_sms_admin_customers_path(window.tenant, { format: 'json' })
    const params = {
      template_id: this.state.selectedTemplate,
      search_phrase: this.state.searchPhrase
    }
    axios.post(url, params).then(res => {
      this.props.setFlash(res.data.result)
    }).catch(error => {
      this.props.setFlash(`${error} (${this.url()})`)
    })
  }

  availableTemplates() {
    const { templates } = this.state
    return templates.map(t => ({ value: t.id, label: t.name }) )
  }

  handleRadioButtonChange(e) {
    this.props.startLoading()
    this.setState({ consentFilter: e.target.value })
    axios
      .get(this.url(), {
        params: {
          page: this.state.page,
          consent: e.target.value,
          search_phrase: this.state.searchPhrase
        }
      })
      .then((res) => {
        this.setState({ customers: res.data })
        this.props.stopLoading()
      })
  }

  clearRadio() {
    this.setState({ consentFilter: null })
    axios
      .get(this.url(), {
        params: {
          page: this.state.page,
          consent: null,
          search_phrase: this.state.searchPhrase
        }
      })
      .then((res) => {
        this.setState({ customers: res.data })
        this.props.stopLoading()
      })
  }

  handleSubmit(searchPhrase) {
    this.props.startLoading()
    axios
      .get(Routes.admin_customers_path(window.tenant, { format: 'json' }), {
        params: {
          search_phrase: searchPhrase,
          consent: this.state.consentFilter
        }
      })
      .then((res) => {
        this.setState({ customers: res.data })
        this.props.stopLoading()
        this.setState({ customerSearch: true, searchPhrase: searchPhrase })
      })
  }

  clearSearch() {
    this.setState({ customerSearch: false, searchPhrase: '' })
    this.fetchCustomers()
  }

  render() {
    return (
      <div>
        <CustomerSupportButton
          to={Routes.customer_support_plan_event_route_points_path(window.tenant)}
          title='Customer support'
        />
        <div className='search-field'>
          <FunSearchField handleSubmit={this.handleSubmit.bind(this)} clearSearch={this.clearSearch.bind(this)} />
        </div>
        {isHjemis && this.renderSmsButton()}
        {isHjemis && this.renderConsentFilter()}
        <TablePagination
          rowsPerPageOptions={[25, 50]}
          count={this.state.customers.length}
          component='div'
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          onPageChange={this.handleChangePage.bind(this)}
          onRowsPerPageChange={this.handleChangeRowsPerPage.bind(this)}
          style={{ position: 'absolute', top: 15, right: 80 }}
        />
        <NewButton to={Routes.new_admin_customers_path(window.tenant)} />
        <Table className='resources-table'>
          <CustomersTableHead />
          {this.renderTableBody()}
        </Table>
        {this.renderSmsModal()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMainTitle: (title) => dispatch(setMainTitle(title)),
    setFlash: (flash) => dispatch(setFlash(flash)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const CustomersTable = connect(mapStateToProps, mapDispatchToProps)(CustomersTableClass)

export default CustomersTable
