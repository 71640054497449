import { Source, Layer } from 'react-map-gl'
import React from 'react'

function HeatmapLayer(props) {
  const { points, gradient, layerType } = props

  let parsedPoints = points.map((event) => {
    return event.map((point, index) => {
      return {
        type: 'Feature',
        properties: { id: index, value: point.value },
        geometry: {
          type: 'Point',
          coordinates: [point.coordinates.lng, point.coordinates.lat]
        }
      }
    })
  })

  if (parsedPoints) {
    parsedPoints = parsedPoints.flat()
    parsedPoints = {
      type: 'FeatureCollection',
      features: parsedPoints
    }
  }

  return (
    <Source type='geojson' data={parsedPoints}>
      <Layer
        id={layerType}
        type='heatmap'
        paint={{
          'heatmap-intensity': ['interpolate', ['linear'], 0, 1, 1],
          'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 1, 20],
          'heatmap-color': gradient
        }}
      />
    </Source>
  )
}
export default HeatmapLayer
