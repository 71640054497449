import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { startLoading, stopLoading } from '../../files/actions/index'
import difference from 'lodash/difference'
import { GOOGLE_STREET_LAYER, GOOGLE_SATELITE_LAYER } from '../shared/Layers'
import Loading from '../shared/Loading'
import BackButton from '../shared/BackButton'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import REGIONS from '../public/helpers/regions'
import MapboxMap from '../shared/MapboxSimpleMap'
import HeatmapLayer from './HeatmapLayers'
import mapboxgl from 'mapbox-gl'
import { Marker } from 'react-map-gl'

const layerTypes = ['revenue', 'visited', 'pins']
const selectedLayerTypes = ['revenue', 'pins']

const revenueGradient = [
  'interpolate',
  ['linear'],
  ['heatmap-density'],
  0,
  'rgba(249, 247, 88, 0)',
  0.2,
  'rgba(249, 222, 79, 0.75)',
  0.4,
  'rgba(249, 172, 62, 0.75)',
  0.6,
  'rgba(249, 123, 45, 0.75)',
  0.8,
  'rgba(250, 0, 3, 0.75)',
  0.9,
  'rgba(254, 57, 27, 0.75)'
]

const visitedGradient = [
  'interpolate',
  ['linear'],
  ['heatmap-density'],
  0,
  'rgba(63, 75, 248, 0)',
  0.2,
  'rgba(60, 89, 223, 0.4)',
  0.4,
  'rgba(54, 117, 174, 0.4)',
  0.6,
  'rgba(48, 146, 125, 0.4)',
  0.8,
  'rgba(36, 202, 26, 0.4)',
  0.9,
  'rgba(33, 217, 2, 0.4)'
]

const calculateMapHeight = () => {
  let mapHeight = window.innerHeight
  mapHeight -= 64
  return mapHeight
}

class PlanEventHeatMapClass extends React.Component {
  constructor(props) {
    super(props)
    this.map = null
    this.state = {
      zoom: 8,
      planEventHeatMapPoints: {},
      historicalPlanEvents: null,
      selectedPointSwitchState: selectedLayerTypes,
      selectedPlanEventsCalendarState: [],
      initialViewState: {
        longitude: REGIONS[tenant].coordinates.lng,
        latitude: REGIONS[tenant].coordinates.lat,
        zoom: REGIONS[tenant].zoom
      }
    }
  }

  componentDidMount() {
    const planEventId = parseInt(this.props.match.params.planEventId)
    this.setState({ selectedPlanEventsCalendarState: [planEventId] })
    this.fetchPlanEventHistoricalData(planEventId)
  }

  fetchPlanEventHistoricalData(planEventId) {
    this.props.startLoading()
    axios
      .get(Routes.historical_plan_events_plan_event_path(window.tenant, window.depoId, planEventId, { format: 'json' }))
      .then((res) => {
        this.setState({ historicalPlanEvents: res.data })
        this.fetchPlanEventPointsData(planEventId, null)
      })
  }

  fetchPlanEventPointsData(planEventId, event) {
    this.updatePlanEventsCalendarState(event)
    if (!planEventId || this.state.planEventHeatMapPoints[planEventId]) return
    this.props.startLoading()
    axios
      .get(Routes.heat_map_points_plan_event_path(window.tenant, window.depoId, planEventId, { format: 'json' }))
      .then((res) => {
        let planEventHeatMapPoints = Object.assign(this.state.planEventHeatMapPoints)
        planEventHeatMapPoints[planEventId] = res.data
        this.setState({ planEventHeatMapPoints })
        this.updatePlanEventsCalendarState(event)

        const eventPoints = this.state.planEventHeatMapPoints[planEventId].map((p) => p.coordinates)
        if (this.map && eventPoints.length > 1) {
          this.calculateBounds(eventPoints)
        }

        this.props.stopLoading()
      })
  }

  calculateBounds(coords) {
    const bounds = new mapboxgl.LngLatBounds(coords[0], coords[1])
    coords.slice(2).forEach((coord) => {
      bounds.extend(coord)
    })

    this.map.fitBounds(bounds, { padding: 30 })
  }

  updatePlanEventsCalendarState(event) {
    if (event != null) this.setState({ selectedPlanEventsCalendarState: event.target.value })
  }

  renderBackButton() {
    if (!this.state.historicalPlanEvents) return
    return (
      <div className='heat-map-back-button'>
        <BackButton to={Routes.plan_event_path(window.tenant, window.depoId, this.props.match.params.planEventId)} />
      </div>
    )
  }

  handlePointStateSwitchChange(event) {
    this.setState({ selectedPointSwitchState: event.target.value })
  }

  handlePlanEventChange(event) {
    let planEventId = difference(event.target.value, this.state.selectedPlanEventsCalendarState)[0]
    this.fetchPlanEventPointsData(planEventId, event)
  }

  renderMapLayerSwitch() {
    if (!this.state.historicalPlanEvents) return
    return (
      <div className='heat-map-point-state-switch'>
        <Select
          multiple
          value={this.state.selectedPointSwitchState}
          onChange={this.handlePointStateSwitchChange.bind(this)}
          renderValue={(selected) => selected.join(', ')}
          disableUnderline={true}
        >
          {layerTypes.map((layerType) => (
            <MenuItem key={layerType} value={layerType}>
              <Checkbox checked={this.state.selectedPointSwitchState.indexOf(layerType) > -1} />
              <ListItemText primary={layerType} />
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }

  renderPlanEventsCalendarSwitch() {
    if (!this.state.historicalPlanEvents) return
    return (
      <div className='heat-map-calendar-switch'>
        <Select
          multiple
          value={this.state.selectedPlanEventsCalendarState}
          onChange={this.handlePlanEventChange.bind(this)}
          renderValue={(selected) => 'Selected plan events (' + selected.length + ')'}
          disableUnderline={true}
        >
          {this.state.historicalPlanEvents.map((planEvent) => (
            <MenuItem key={planEvent.date} value={planEvent.id}>
              <Checkbox checked={this.state.selectedPlanEventsCalendarState.indexOf(planEvent.id) > -1} />
              <ListItemText primary={planEvent.date} />
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }

  renderMapLayer(layerType) {
    if (!this.state.selectedPointSwitchState.includes(layerType)) return

    const gradient = layerType == 'visited' ? visitedGradient : revenueGradient
    const { selectedPlanEventsCalendarState, planEventHeatMapPoints } = this.state
    let layerData = []

    selectedPlanEventsCalendarState.forEach((planEventId) => {
      if (planEventHeatMapPoints[planEventId]) {
        switch (layerType) {
          case 'visited':
            layerData.push(
              planEventHeatMapPoints[planEventId].map((p) => ({
                coordinates: p.coordinates,
                value: 0.3
              }))
            )
            break
          case 'revenue':
            layerData.push(
              planEventHeatMapPoints[planEventId]
                .filter((p) => parseFloat(p.revenue) > 0)
                .map((p) => ({
                  coordinates: p.coordinates,
                  value: parseFloat(p.revenue)
                }))
            )
            break
          case 'pins':
            layerData.push(this.renderHeatMapPins(planEventHeatMapPoints, planEventId))
            break
        }
      }
    })

    return layerType == 'pins' ? (
      layerData
    ) : (
      <HeatmapLayer points={layerData} gradient={gradient} layerType={layerType} />
    )
  }

  renderHeatMapPins(planEventHeatMapPoints, planEventId) {
    return planEventHeatMapPoints[planEventId].map((p, i) => (
      <Marker latitude={p.coordinates.lat} longitude={p.coordinates.lng} key={`pin-${planEventId}-${i}`}>
        <div
          style={{
            backgroundColor: '#476797',
            opacity: 0.9,
            borderRadius: '50%',
            width: 10,
            height: 10
          }}
        />
      </Marker>
    ))
  }

  handleRender(map, e) {
    this.map = map.target
  }

  render() {
    return (
      <div className='plan-event-map' style={{ height: calculateMapHeight() }}>
        <MapboxMap
          onRender={this.handleRender.bind(this)}
          initialViewState={this.state.initialViewState}
          mapboxAccessToken={this.props.access_token}
        >
          {this.renderMapLayer('pins')}
          {this.renderMapLayer('visited')}
          {this.renderMapLayer('revenue')}
        </MapboxMap>
        {this.renderPlanEventsCalendarSwitch()}
        {this.renderMapLayerSwitch()}
        {this.renderBackButton()}
        <Loading />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const PlanEventHeatMap = connect(mapStateToProps, mapDispatchToProps)(PlanEventHeatMapClass)

export default PlanEventHeatMap
