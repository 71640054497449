import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Button from '@material-ui/core/Button'
import PlanEventCustomersTableHead from './PlanEventCustomersTableHead'
import TitleContainer from '../shared/TitleContainer'
import PlanEventCustomersTableRow from './PlanEventCustomersTableRow'
import { Link } from 'react-router-dom'
import qs from 'querystringify'
import merge from 'lodash/merge'
import some from 'lodash/some'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { setMainTitle, startLoading, stopLoading, loadResources, setFlash } from '../../files/actions/index'
import currentUser from '../support/currentUser'

const styles = {
  modalPaper: {
    width: '30%',
    margin: 'auto',
    padding: 25
  }
}

class PlanEventCustomersClass extends React.Component {
  state = {
    customers: [],
    planEvent: {},
    buttonVisible: true,
    checked: false,
    modalOpen: false,
    smsTemplates: [],
    selectedTemplateId: ''
  }

  componentDidMount() {
    let customers_path
    if (this.props.match.params.planEventId) {
      this.props.setMainTitle('Plan event customers who will receive SMS')
      customers_path = Routes.sms_customers_plan_event_path(
        window.tenant,
        window.depoId,
        this.props.match.params.planEventId,
        { format: 'json' }
      )
    } else {
      const search = this.props.location.search
      const weekday = qs.parse(search).weekday
      this.props.setMainTitle(`Customers who will receive or received SMS at ${weekday}`)
      customers_path = Routes.all_customers_plan_events_path(window.tenant, window.depoId, { format: 'json', weekday })
    }
    this.fetchCustomersAndPlanEvent(customers_path)
  }

  fetchCustomersAndPlanEvent(customers_path) {
    this.props.startLoading()
    axios
      .all([
        axios.get(customers_path),
        axios.get(
          Routes.plan_event_path(window.tenant, window.depoId, this.props.match.params.planEventId, { format: 'json' })
        )
      ])
      .then(
        axios.spread((customers, planEvent) => {
          const checkedCustomers = customers.data.map((c) => {
            c.checked = false
            return c
          })
          this.setState({
            customers: checkedCustomers,
            planEvent: planEvent.data
          })
          this.props.stopLoading()
        })
      )
      .catch((err) => {
        this.setFlash(`${err}`)
        this.props.stopLoading()
      })
  }

  setFlash(message) {
    this.props.setFlash(message)
  }

  checkAll(checked) {
    const newCustomers = this.state.customers.slice(0).map((customer) => {
      if (!customer.sms_stopped_record_line_item_id) {
        customer.checked = checked
      }
      return customer
    })

    this.setState({ checked, customers: newCustomers })
  }

  showStopSmsModal() {
    axios.get(Routes.admin_sms_templates_path(window.tenant, { format: 'json' })).then((res) => {
      this.setState({
        modalOpen: true,
        smsTemplates: res.data,
        selectedTemplateId: (res.data[0] || { id: '' }).id
      })
    })
  }

  closeStopSmsModal() {
    this.setState({ modalOpen: false })
  }

  existsCheckedCustomer() {
    return some(this.state.customers, 'checked')
  }

  updateCustomers(customerId, checked) {
    const slicedCustomers = this.state.customers.slice(0)

    const customerIndex = slicedCustomers.findIndex((c) => c.id === customerId)
    slicedCustomers[customerIndex].checked = checked
    this.setState({ customers: slicedCustomers })
  }

  renderTableBody() {
    const { customers } = this.state

    return (
      <TableBody>
        {customers.map((customer) => {
          return (
            <PlanEventCustomersTableRow
              customer={customer}
              key={customer.id}
              setFlash={this.setFlash.bind(this)}
              updateCustomers={this.updateCustomers.bind(this)}
            />
          )
        })}
      </TableBody>
    )
  }

  renderMapIcon() {
    if (!this.props.match.params.planEventId) return

    return (
      <div className='map-button'>
        <Link
          to={Routes.sms_customer_map_plan_event_path(
            window.tenant,
            window.depoId,
            this.props.match.params.planEventId
          )}
        >
          <i className='material-icons'>map</i>
        </Link>
      </div>
    )
  }

  renderFullDetails() {
    const id = this.props.match.params.planEventId

    return (
      <Link to={Routes.sms_customers_full_details_plan_event_path(tenant, depoId, id)}>
        <Button vairant='contained' color='primary'>
          Full details
        </Button>
      </Link>
    )
  }

  hideButtonAndHandleBrokenCar() {
    this.setState({ buttonVisible: false }, () => this.handleBrokenCar())
  }

  handleSelectChange(e) {
    this.setState({ selectedTemplateId: e.target.value })
  }

  handleSmsSchedule() {
    const selectedCustomers = this.state.customers.filter(({ checked }) => checked)

    if (confirm(`You are about to send ${selectedCustomers.length} SMS, do you want to continue?`)) {
      axios
        .post(
          Routes.send_stop_sms_plan_event_path(window.tenant, window.depoId, this.state.planEvent.id, {
            format: 'json'
          }),
          {
            sms_template: this.state.selectedTemplateId,
            selected_customer_ids: selectedCustomers.map((customer) => customer.id)
          }
        )
        .then((res) => {
          this.closeStopSmsModal()
          this.props.setFlash(`You successfully scheduled ${selectedCustomers.length} SMS sending`)
        })
        .catch((err) => this.props.setFlash(`${err}`))
    } else {
      this.closeStopSmsModal()
    }
  }

  handleBrokenCar() {
    const planEventId = this.state.planEvent.id

    axios
      .put(Routes.stop_sending_sms_plan_event_path(window.tenant, window.depoId, planEventId, { format: 'json' }))
      .then((res) => {
        const customers_path = Routes.sms_customers_plan_event_path(window.tenant, window.depoId, planEventId, {
          format: 'json'
        })
        this.fetchCustomersAndPlanEvent(customers_path)
        this.props.setFlash('SMS send out stopped successfully')
      })
      .catch((err) => {
        this.props.setFlash('You are not allowed to stop sms sendout for this plan event')
      })
  }

  renderStopSendoutButton() {
    if (
      currentUser().role === 'driver' ||
      !this.state.buttonVisible ||
      !this.props.match.params.planEventId ||
      !this.state.planEvent.can_be_stopped
    )
      return

    return (
      <Button
        style={{ marginRight: 10 }}
        vairant='contained'
        color='secondary'
        onClick={this.hideButtonAndHandleBrokenCar.bind(this)}
      >
        Stop SMS
      </Button>
    )
  }

  renderSmsCustomButton() {
    if (this.state.planEvent.can_send_custom_sms && this.existsCheckedCustomer()) {
      return (
        <Button variant='contained' color='secondary' onClick={this.showStopSmsModal.bind(this)} className='ml-1'>
          Send Custom SMS
        </Button>
      )
    }
  }

  renderScheduleSmsButton() {
    if (this.state.selectedTemplateId) {
      return (
        <Button
          className='d-block mt-3'
          onClick={this.handleSmsSchedule.bind(this)}
          variant='contained'
          color='secondary'
        >
          Schedule SMS sending
        </Button>
      )
    }
  }

  renderSmsTemplateModal() {
    const { smsTemplates, modalOpen, selectedTemplateId } = this.state

    return (
      <Modal
        open={modalOpen}
        onClose={(reason) => {
          if (reason === 'backdropClick') {
            this.closeStopSmsModal.bind(this)
          }
        }}
      >
        <Paper style={styles.modalPaper}>
          <Typography variant='h5'>Choose SMS template</Typography>
          <Select
            className='template-select d-block mt-3'
            onChange={this.handleSelectChange.bind(this)}
            value={selectedTemplateId}
          >
            {smsTemplates.map((smsTemplate) => {
              return (
                <MenuItem key={smsTemplate.id} value={smsTemplate.id}>
                  {smsTemplate.name}
                </MenuItem>
              )
            })}
          </Select>
          {this.renderScheduleSmsButton()}
        </Paper>
      </Modal>
    )
  }

  render() {
    return (
      <TitleContainer>
        {this.renderSmsTemplateModal()}
        {this.renderStopSendoutButton()}
        {this.renderSmsCustomButton()}
        {this.renderFullDetails()}
        <Table className='resources-table'>
          <PlanEventCustomersTableHead checkAll={this.checkAll.bind(this)} />
          {this.renderTableBody()}
        </Table>
        {this.renderMapIcon()}
      </TitleContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMainTitle: (title) => dispatch(setMainTitle(title)),
    setFlash: (flash) => dispatch(setFlash(flash)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading())
  }
}

const PlanEventCustomers = connect(mapStateToProps, mapDispatchToProps)(PlanEventCustomersClass)

export default PlanEventCustomers
