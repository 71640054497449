import React from 'react'

class RoutePointIcon extends React.Component {
  render() {
    const { index, color, width, height, textColor, opacity } = this.props
    return (
      <svg
        width={width || '61px'}
        height={height || '56px'}
        viewBox='0 0 61 56'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <filter x='-13.2%' y='-10.2%' width='126.4%' height='128.6%' filterUnits='objectBoundingBox' id='filter-1'>
            <feOffset dx='0' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'></feOffset>
            <feGaussianBlur stdDeviation='2' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur>
            <feColorMatrix
              values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0'
              type='matrix'
              in='shadowBlurOuter1'
              result='shadowMatrixOuter1'
            ></feColorMatrix>
            <feMerge>
              <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
              <feMergeNode in='SourceGraphic'></feMergeNode>
            </feMerge>
          </filter>
        </defs>
        <g id='Admin' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='-desktop0' transform='translate(-858.000000, -206.000000)'>
            <g id='Group-5' transform='translate(862.000000, 208.000000)'>
              <g id='Group-3' fill={color} stroke='#FFFFFF' strokeWidth='2'>
                <path
                  stroke='white'
                  strokeWidth='3.80952381'
                  d='M18.6559342,49.9910118 C18.5246841,49.8603066 18.2797114,49.6156599 17.9372405,49.2725408 C17.3670606,48.7012819 16.7318672,48.062524 16.047872,47.3717226 C14.0932188,45.3976192 12.1383854,43.4012803 10.3126159,41.5058496 C10.1252808,41.3113669 9.94007492,41.118739 9.75708787,40.9280514 C6.6180564,37.6569211 4.26573066,35.0988639 2.95371573,33.4873954 C-0.170622078,29.6499605 -1.9047619,24.8714492 -1.9047619,19.8339933 C-1.9047619,7.82444005 7.90587158,-1.9047619 20,-1.9047619 C32.0941284,-1.9047619 41.9047619,7.82444005 41.9047619,19.8339933 C41.9047619,24.9801604 40.0946789,29.8545701 36.8463888,33.7293484 C35.2003775,35.6928211 31.953078,39.148065 27.6837003,43.5419446 C26.1975922,45.0713901 24.6919532,46.6068278 23.2437618,48.0737369 C22.7368631,48.5871871 22.2732917,49.0554576 21.8626867,49.4692148 C21.6343057,49.6992541 21.6343057,49.6992541 21.4830479,49.8513874 C21.4227838,49.9119712 21.4227838,49.9119712 21.3836086,49.9513276 C21.3680883,49.9669162 21.3680883,49.9669162 21.3580662,49.9769791 C21.3541003,49.9809608 21.3541003,49.9809608 21.3515372,49.9835336 C21.3505204,49.9845542 21.3505204,49.9845542 21.3498579,49.9852192 C21.3493731,49.9857058 21.3493731,49.9857058 21.3492448,49.9858344 L20.0051774,51.3346541 L18.6559342,49.9910118 Z'
                  fill={color}
                  opacity={opacity}
                  fillRule='evenodd'
                ></path>
              </g>
              <text
                fontFamily='Helvetica Neue,Arial,Helvetica,sans-serif'
                fontSize='20'
                fontWeight='800'
                fill={textColor}
              >
                <tspan x={index.toString().length == 3 ? '4' : index.toString().length == 2 ? '9' : '13'} y='28'>
                  {index}
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default RoutePointIcon
