import React, { Component } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { FormControlLabel, FormHelperText, FormControl } from '@material-ui/core'

export default class CheckboxWithLabel extends Component {
  render() {
    const { label, handleChange, checked, otherClassNames, helperText, additionalStyles } = this.props
    return (
      <FormControl className={`checkbox-with-label ${otherClassNames}`} style={additionalStyles}>
        <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} />} label={label} />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
}
