import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

class CustomersSmsReceiversReport extends React.Component {
  renderTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Public estimate time</TableCell>
          <TableCell>SMS sent at</TableCell>
          <TableCell>Route</TableCell>
          <TableCell>Point Address</TableCell>
          <TableCell>Point ID</TableCell>
          <TableCell>Customer Address</TableCell>
          <TableCell>Plan Event</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  renderTableBody() {
    if (!this.props.smsReceivers) return

    return (
      <TableBody>
        {this.props.smsReceivers.map((receiver, index) => {
          return (
            <TableRow key={receiver.id} hover>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{receiver.plan_event.date}</TableCell>
              <TableCell>{receiver.google_estimate_time || '--:--'}</TableCell>
              <TableCell>{(receiver.sms_sent_skipped_at ? '--:--' : receiver.sms_sent_at) || '--:--'}</TableCell>
              <TableCell>{receiver.plan_event_route.name}</TableCell>
              <TableCell>{receiver.plan_event_route_point.address}</TableCell>
              <TableCell>{receiver.plan_event_route_point.id}</TableCell>
              <TableCell>{receiver.location && receiver.location.address}</TableCell>
              <TableCell>
                <a
                  href={Routes.plan_event_path(
                    window.tenant,
                    receiver.plan_event_route.depo_id,
                    receiver.plan_event_route.plan_event_id
                  )}
                >
                  Plan Event
                </a>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    )
  }

  render() {
    return (
      <Table className='resources-table'>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </Table>
    )
  }
}

export default CustomersSmsReceiversReport
