import React, { useRef } from 'react'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import Map, { NavigationControl } from 'react-map-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { useControl } from 'react-map-gl'

export function DrawControl(props) {
  const drawRef = useRef(null)

  const modes = MapboxDraw.modes
  modes.draw_point.onClick = (e, point) => {
    handleNewPoint(e, point)
  }
  const draw = new MapboxDraw({
    modes,
    displayControlsDefault: false,
    controls: {
      point: true
    }
  })

  drawRef.current = draw

  useControl(() => draw, {
    position: 'top-left'
  })

  const handleNewPoint = (e, point) => {
    props.createPoint(point.lngLat, point.id)
    //drawRef.current.changeMode('simple_select') does not work as it should, quick fix below
    document.getElementsByClassName('mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_point')[0].click()
  }

  return null
}
