import React from 'react'
import TooltipModal from '../../shared/TooltipModal'

function RouteMapTooltip(props) {
  const { toggleTooltip, routeMinimumSalesEfficientCount } = props.state
  return (
    <TooltipModal
      open={toggleTooltip}
      close={props.toggleTooltip}
      routeMinimumSalesEfficientCount={routeMinimumSalesEfficientCount}
    />
  )
}

export default RouteMapTooltip
