import React from 'react'
import axios from 'axios'
import customerIcon from '../../files/images/male.svg'
import REGIONS from '../public/helpers/regions'
import Modal from '../shared/Modal'
import RoutePointIcon from '../map/icons/RoutePointIcon'
import MapboxMap from '../shared/MapboxSimpleMap'
import { Marker, Layer, Source } from 'react-map-gl'
import mapboxgl from 'mapbox-gl'

class PlanEventCustomersMap extends React.Component {
  constructor(props) {
    super(props)
    this.map = null
    this.state = {
      lat: 56.2639,
      lng: 9.5018,
      zoom: 8,
      customers: [],
      routePoints: [],
      planEvent: {},
      foundCustomer: {},
      phoneModalOpen: false,
      polygonPlaced: false
    }
  }

  componentDidMount() {
    axios
      .all([
        axios.get(
          Routes.sms_customer_map_plan_event_path(window.tenant, window.depoId, this.props.match.params.planEventId, {
            format: 'json'
          })
        ),
        axios.get(
          Routes.route_points_plan_event_path(window.tenant, window.depoId, this.props.match.params.planEventId, {
            format: 'json'
          })
        ),
        axios.get(
          Routes.plan_event_path(window.tenant, window.depoId, this.props.match.params.planEventId, { format: 'json' })
        )
      ])
      .then(
        axios.spread((customers, routePoints, planEvent) => {
          this.setState({
            customers: customers.data,
            routePoints: routePoints.data,
            planEvent: planEvent.data
          })
        })
      )
  }

  extractPoints(roadPoints) {
    return roadPoints.filter(({ point }) => point).map((roadPoint) => roadPoint.point)
  }

  pointData({ point }, index) {
    return { ...point, properties: index + 1, color: 'cadetblue' }
  }

  customerData(customer) {
    return { ...customer.coordinates, customer: customer }
  }

  handleCustomerClick(e, customer) {
    this.setState({ foundCustomer: customer, phoneModalOpen: true })
  }

  renderRouteNumber() {
    return this.state.foundCustomer.route_number && <b>- {this.state.foundCustomer.route_number}</b>
  }

  renderCustomerPhoneModal() {
    return (
      <Modal
        open={this.state.phoneModalOpen}
        close={() => this.setState({ phoneModalOpen: false })}
        title='Selected customer phone'
      >
        <p>
          {this.state.foundCustomer.phone} {this.renderRouteNumber()}
        </p>
      </Modal>
    )
  }

  renderCustomerPoints() {
    return this.state.customers.map((customer, index) => {
      if (customer.coordinates) {
        return (
          <Marker
            className={'customerMarker'}
            style={{ left: -5 }}
            key={`customer_${customer.id}`}
            latitude={customer.coordinates[1]}
            longitude={customer.coordinates[0]}
            anchor='center'
            onClick={(e) => this.handleCustomerClick(e, customer)}
          >
            <img src={customerIcon} width={25} />
          </Marker>
        )
      }
    })
  }

  renderRoutePoints() {
    this.calculateBounds()
    return this.state.routePoints.map((routePoint, index) => {
      if (routePoint != undefined && routePoint.point) {
        return (
          <Marker
            className={'pointMarker'}
            key={`plan_event_route_point_${routePoint.id}`}
            latitude={routePoint.point.coordinates[1]}
            longitude={routePoint.point.coordinates[0]}
            anchor='bottom'
          >
            <RoutePointIcon
              index={index + 1}
              color={'#5E716A'}
              width={40}
              height={40}
              textColor={'white'}
              opacity={1}
            ></RoutePointIcon>
          </Marker>
        )
      }
    })
  }

  renderPolygon() {
    const { planEvent } = this.state
    if (planEvent.id) {
      return (
        <>
          <Source data={planEvent.polygon} id='planEventPolygon' type='geojson' />
          <Layer
            key={`polygon_${planEvent.id}`}
            type='fill'
            source='planEventPolygon'
            paint={{
              'fill-color': '#3388ff',
              'fill-opacity': 0.2
            }}
          />
          <Layer
            key={`border_${planEvent.id}`}
            type='line'
            source='planEventPolygon'
            paint={{
              'line-color': '#3388ff',
              'line-width': 3
            }}
          />
        </>
      )
    }
  }

  calculateBounds() {
    const { routePoints } = this.state

    if (routePoints[0] && this.map) {
      let bounds = new mapboxgl.LngLatBounds(routePoints[0].point.coordinates, routePoints[1].point.coordinates)
      routePoints.slice(2).forEach((coord) => {
        bounds.extend(coord.point.coordinates)
      })

      this.map.fitBounds(bounds, { padding: 50 })
    }
  }

  handleRender(map) {
    this.map = map.target
  }

  render() {
    return (
      <div className='plan-event-map'>
        <div className='map-container'>
          <MapboxMap
            onRender={this.handleRender.bind(this)}
            initialViewState={{
              longitude: REGIONS[window.tenant].coordinates.lng,
              latitude: REGIONS[window.tenant].coordinates.lat,
              zoom: REGIONS[window.tenant].zoom
            }}
            accessToken={this.props.mapboxAccessToken}
          >
            {this.renderRoutePoints()}
            {this.renderPolygon()}
            {this.renderCustomerPoints()}
          </MapboxMap>
          {this.renderCustomerPhoneModal()}
        </div>
      </div>
    )
  }
}

export default PlanEventCustomersMap
