import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem'
import FunTextField from '../../forms/FunTextField'
import Warehouse from '../../../models/Warehouse'
import SaveButton from '../../shared/SaveButton'
import CancelButton from '../../shared/CancelButton'
import GridWrapper from '../../shared/GridWrapper'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import FlashNotification from '../../shared/FlashNotification'
import extractDataErrors from '../../support/extractDataErrors'
import CheckboxWithLabel from '../../shared/CheckboxWithLabel'

import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'

class WarehousesForm extends React.Component {
  state = {
    ...Warehouse(),
    warehouse_owners: [],
    loading: false,
    successMessage: null
  }

  componentDidMount() {
    const { warehouseId } = this.props.match.params
    this.startLoading()

    if (warehouseId) {
      axios
        .get(Routes.inventory_warehouse_path(window.tenant, window.depoId, warehouseId, { format: 'json' }))
        .then((res) => {
          const { owner_id, owner_type, code } = res.data
          this.setState({
            ...res.data,
            owner: `${owner_id}-${owner_type}`,
            loading: false
          })
        })
    }
    this.getwarehouseOwnersList()
  }

  getwarehouseOwnersList() {
    this.startLoading()
    axios
      .get(
        Routes.available_warehouse_owners_inventory_warehouses_path(window.tenant, window.depoId, { format: 'json' })
      )
      .then((res) => {
        this.setState({ warehouse_owners: res.data, loading: false })
      })
  }

  startLoading() {
    this.setState({ loading: true })
  }

  stopLoading() {
    this.setState({ loading: false })
  }

  url() {
    return this.state.id
      ? Routes.inventory_warehouse_path(window.tenant, window.depoId, this.state.id)
      : Routes.inventory_warehouses_path(window.tenant, window.depoId)
  }

  method() {
    return this.state.id ? 'patch' : 'post'
  }

  data() {
    return { warehouse: this.state, format: 'json' }
  }

  handleSubmitSuccess(response) {
    const { code } = response.data
    this.getwarehouseOwnersList()
    this.setState({
      ...omit(response.data, 'owner'),
      errors: {},
      loading: false,
      successMessage: `Warehouse with code: "${code}" successfully updated!`
    })
  }

  handleSubmitError(error) {
    this.setState(extractDataErrors(error))
    this.stopLoading()
  }

  handleChange(e, field) {
    this.setState({ [field]: e.target.value })
  }

  handleCheckboxChange(e) {
    this.setState({ primary: e.target.checked })
  }

  handleOwnerChange(e) {
    const [ownerId, ownerType] = e.target.value.split('-')
    this.setState({
      owner_id: ownerId,
      owner_type: ownerType,
      owner: e.target.value
    })
  }

  clearErrors() {
    this.setState({ full_errors: null, errors: {}, successMessage: null })
  }

  renderMainTitle() {
    const title = this.state.id ? `Edit Warehouse #${this.state.id}` : 'New Warehouse'
    return <span className='mdl-layout-title'>{title}</span>
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading} />
  }

  handleSubmit(e) {
    e.preventDefault()
    if (
      this.state.primary &&
      this.ownerHasPrimaryWarehouse() &&
      !confirm('Are you sure you want to set this as your primary warehouse?')
    )
      return

    this.startLoading()
    axios({
      method: this.method(),
      url: this.url(),
      data: this.data()
    })
      .then(this.handleSubmitSuccess.bind(this))
      .catch(this.handleSubmitError.bind(this))
  }

  renderFlashNotification() {
    const { full_errors, successMessage } = this.state
    const message = !isEmpty(full_errors) ? `${full_errors.join(', ')}` : successMessage
    return <FlashNotification message={message} clearErrors={this.clearErrors.bind(this)} />
  }

  renderAvailableWarehouseOwnerList() {
    const { warehouse_owners } = this.state
    return (
      <FunTextField
        className='fun-select'
        select
        field='owner'
        state={this.state}
        handleChange={this.handleOwnerChange.bind(this)}
      >
        {warehouse_owners.map((warehouse_owner) => {
          return (
            <MenuItem
              id={`${warehouse_owner.id}_${warehouse_owner.type}`}
              key={`${warehouse_owner.id}-${warehouse_owner.type}`}
              value={`${warehouse_owner.id}-${warehouse_owner.type}`}
              disabled={warehouse_owner.disabled}
            >
              {warehouse_owner.title} ({warehouse_owner.type})
            </MenuItem>
          )
        })}
      </FunTextField>
    )
  }

  ownerPrimaryWarehouse() {
    return this.state.warehouse_owners.filter((warehouse) => warehouse.primary_warehouse)[0]
  }

  ownerHasPrimaryWarehouse() {
    return this.ownerPrimaryWarehouse() && this.ownerPrimaryWarehouse().primary_warehouse.id !== this.state.id
  }

  renderDepoWarningText() {
    if (this.ownerHasPrimaryWarehouse() && this.state.primary) {
      return `Depo already has a primary warehouse with name:
        ${this.ownerPrimaryWarehouse().primary_warehouse.name}, by checking you will set this warehouse primary
      `
    }
  }

  renderPrimaryFieldCheckbox() {
    const { owner_type, primary } = this.state

    if (owner_type === 'Depo') {
      return (
        <CheckboxWithLabel
          id='primary-checkbox'
          label='Primary'
          handleChange={this.handleCheckboxChange.bind(this)}
          checked={primary}
          helperText={this.renderDepoWarningText()}
        />
      )
    }
  }

  renderAxaptaField() {
    if (isHjemis) {
      return (
        <FunTextField
          field='axapta_id'
          label='AX Warehouse ID'
          state={this.state}
          handleChange={this.handleChange.bind(this)}
        />
      )
    }
  }

  render() {
    return (
      <GridWrapper>
        {this.renderMainTitle()}
        <form noValidate autoComplete='off' onSubmit={this.handleSubmit.bind(this)}>
          <FunTextField field='code' state={this.state} handleChange={this.handleChange.bind(this)} />
          <FunTextField field='name' state={this.state} handleChange={this.handleChange.bind(this)} />
          {this.renderAvailableWarehouseOwnerList()}
          {this.renderPrimaryFieldCheckbox()}
          {this.renderAxaptaField()}
          <SaveButton />
          <CancelButton to={Routes.inventory_warehouses_path(window.tenant, window.depoId)} />
        </form>
        {this.renderFlashNotification()}
        {this.renderLoading()}
      </GridWrapper>
    )
  }
}

export default WarehousesForm
