import React from 'react'
import ErrorCatcher from '../../shared/ErrorCatcher'
import WarehousesComponent from './WarehousesComponent'

const WarehousesIndexWithStore = () => {
  return (
    <ErrorCatcher>
      <div className='content-container'>
        <WarehousesComponent />
      </div>
    </ErrorCatcher>
  )
}

export default WarehousesIndexWithStore
