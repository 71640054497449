import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import Flash from '../shared/Flash'
import {
  startLoading,
  stopLoading,
  createRoutePointFromMap,
  loadRoadPoints,
  loadCarRoutes,
  setFlash
} from '../../files/actions/index'
import flatten from 'lodash/flatten'
import polyline from '@mapbox/polyline'
import { GOOGLE_STREET_LAYER, GOOGLE_SATELITE_LAYER } from '../shared/Layers'
import Paper from '@material-ui/core/Paper'
import PlanEventsReport from '../planEvents/PlanEventsReport'
import REGIONS from '../public/helpers/regions'
import MapboxSimpleMap from '../shared/MapboxSimpleMap'
import PlanEventPointIcon from '../shared/PlanEventPointIcon'
import mapboxgl from 'mapbox-gl'
import PlanEventMarkerWithPopup from './PlanEventMarkerWithPopup'

class PlanEventMapClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lat: REGIONS[tenant].coordinates.lat,
      lng: REGIONS[tenant].coordinates.lng,
      zoom: 8,
      editableFG: null,
      mapType: GOOGLE_STREET_LAYER,
      layerChange: false,
      roadPoints: null
    }
  }

  componentDidMount() {
    axios.get(Routes.admin_depo_path(tenant, depoId, { format: 'json' })).then((res) => {
      const depoCoordinates = res.data.address_coordinates
      if (depoCoordinates.length > 0) {
        this.setState({
          lat: parseFloat(depoCoordinates[0]),
          lng: parseFloat(depoCoordinates[1]),
          zoom: 10
        })
      }
    })
  }

  componentDidUpdate() {
    const routePoints = this.extractPoints(this.props.roadPoints)
    if (routePoints.length > 0) {
      const bounds = new mapboxgl.LngLatBounds(routePoints[0].coordinates, routePoints[0].coordinates)
      routePoints.forEach((coord) => {
        bounds.extend(coord.coordinates)
      })
      if (this.map) {
        this.map.fitBounds(bounds, { padding: 70 })
      }
    }
  }

  componentWillUnmount() {
    this.props.loadCarRoutes([])
    this.props.loadRoutePoints([])
  }

  extractPoints(roadPoints) {
    return roadPoints.filter(({ point }) => point).map((roadPoint) => roadPoint.point)
  }

  setFlashFromChild(message) {
    this.props.setFlash(message)
  }

  pointData(point, index) {
    const geoJson = point.point ? point.point : point.stop_location
    geoJson.properties = index + 1
    geoJson.revenue = point.total_revenue
    geoJson.data = {
      plannedTime: point.google_estimate_time || point.start_at,
      visitedTime: point.actual_arrival_time || point.start_at,
      duration: point.duration || 0,
      clients: point.subscribers_count || 0,
      totalRevenue: point.total_revenue || 0
    }

    if (point.point_visited !== undefined) {
      geoJson.color = point.point_visited ? '#57C200' : '#D0021B'
    } else {
      geoJson.color = '#F5A623'
    }

    if (point.stop_type === 'eshop') {
      geoJson.color = '#91D4F5'
    }
    return geoJson
  }

  geoJsonToPoint(point) {
    const coordinates = point.coordinates
    return [coordinates[1], coordinates[0]]
  }

  renderMarker(routePoint, index) {
    const geoJsonPoint = this.pointData(routePoint, index)
    const position = this.geoJsonToPoint(routePoint.stop_location || routePoint.point)

    return (
      <PlanEventMarkerWithPopup
        key={index}
        index={index+1}
        position={position}
        geoJsonPoint={geoJsonPoint}
        planEvent={this.props.planEvent}
        routePoint={routePoint}
        setFlash={this.setFlashFromChild.bind(this)}
        map={this.map}
      />
    )
  }

  renderRoadPoints() {
    let routePoints = this.state.roadPoints ? this.state.roadPoints : this.props.roadPoints
    return routePoints.map((routePoint, index) => {
      if (routePoint && (routePoint.point || routePoint.stop_location)) {
        return this.renderMarker(routePoint, index)
      }
    })
  }

  extractedCarStopPolylines(carRoutes) {
    return carRoutes
      .filter(({ encoded_polyline }) => encoded_polyline)
      .map((carStopRoute) => polyline.decode(carStopRoute.encoded_polyline))
  }

  renderRoutePolyline() {
    const { carRoutes } = this.props
    if (carRoutes.length > 0) {
      const decodedPolylines = this.extractedCarStopPolylines(carRoutes)
      let flattened = flatten(decodedPolylines)
      flattened = flattened.map(([longitude, latitude]) => [latitude, longitude])
      if (decodedPolylines.length > 0) {
        this.map.addSource('route', {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: flattened
            }
          }
        })
      }
      this.map.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': 'green',
          'line-width': 3
        }
      })

      this.map.addLayer({
        id: 'arrowheads',
        type: 'symbol',
        source: 'route',
        layout: {
          'text-field': '►',
          'icon-allow-overlap': false,
          'symbol-placement': 'line',
          'symbol-spacing': 10,
          'text-rotate': 0,
          'text-anchor': 'center'
        },
        paint: {
          'text-color': 'red'
        }
      })
    }
  }

  renderMarkers() {
    this.renderRoutePolyline()
    return this.renderRoadPoints()
  }

  reRenderOnPlanEventsReportTabSwitch(roadPoints) {
    this.setState({ roadPoints: roadPoints })
    this.renderMarkers()
  }

  handleRender(map, e) {
    this.map = map.target
  }

  render() {
    const position = [this.state.lat, this.state.lng]
    const { mapType } = this.state
    return (
      <div className='map-view-container'>
        <div className='map-sidebar-wrapper'>
          <Paper className='map-sidebar'>
            <PlanEventsReport
              {...this.props}
              map={this.map}
              reRenderOnPlanEventsReportTabSwitch={this.reRenderOnPlanEventsReportTabSwitch.bind(this)}
            />
          </Paper>
        </div>
        <div className='map-container'>
          <MapboxSimpleMap
            onRender={this.handleRender.bind(this)}
            initialViewState={{
              longitude: position[1],
              latitude: position[0],
              zoom: this.state.zoom
            }}
            accessToken={this.props.mapboxAccessToken}
          >
            {this.renderMarkers()}
          </MapboxSimpleMap>
        </div>
        <Flash />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    roadPoints: state.roadPoints,
    carRoutes: state.carRoutes,
    planEvent: state.planEvent
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    loadRoutePoints: (routePoints) => dispatch(loadRoadPoints(routePoints)),
    loadCarRoutes: (carRoutes) => dispatch(loadCarRoutes(carRoutes)),
    setFlash: (flash) => dispatch(setFlash(flash))
  }
}

const PlanEventMap = connect(mapStateToProps, mapDispatchToProps)(PlanEventMapClass)

export default PlanEventMap
