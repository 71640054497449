import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import moment from 'moment'
import { connect } from 'react-redux'

import PlanEventTemplatesTableCell from './PlanEventTemplatesTableCell'
import PlanEventDestroyModal from './PlanEventDestroyModal'

const styles = {
  carCell: {
    minHeight: 80,
    width: '1%',
    padding: '4px !important',
    border: 'solid 1px lightgray',
    fontSize: '0.8125rem'
  },
  destroyEventsButton: {
    width: 24,
    height: 24
  },
  destroyEventsIcon: {
    fontSize: 18
  }
}

class PlanEventTemplatesTableRowClass extends React.Component {
  state = { modalOpen: false }

  toggleModal() {
    this.setState({ modalOpen: !this.state.modalOpen })
  }

  planEventFor(car, date, planEvents) {
    return planEvents.find((pe) => {
      const planEventDate = moment(pe.date, 'YYYY-MM-DD')

      return date.isBetween(planEventDate, planEventDate.clone().add(pe.day_span, 'days'), null, '[)')
    })
  }

  planEventDaySpan(planEvent, date) {
    if (!planEvent || planEvent.day_span == 1) return 1

    return moment(planEvent.date, 'YYYY-MM-DD').add(planEvent.day_span, 'days').diff(date, 'days')
  }

  setMergedDays(daySpan, weekDay, mergedDays) {
    for (let i = 0; i < daySpan; i += 1) {
      mergedDays.push(weekDay + i)
    }
  }

  renderWeekDays() {
    const { car, year, week, planEventsGrouped } = this.props
    const planEvents = planEventsGrouped[car.internal_number] || []
    const mergedDays = []

    return [0, 1, 2, 3, 4, 5, 6].map((wday) => {
      if (mergedDays.includes(wday)) return

      const isoWeekday = wday + 1 // This is to maintain that tests run properly
      const date = moment(`${year} ${week}`, 'GGGG WW').add(wday, 'days')
      const planEvent = this.planEventFor(car, date, planEvents)
      const daySpan = this.planEventDaySpan(planEvent, date, wday)

      this.setMergedDays(daySpan, wday, mergedDays)

      return (
        <PlanEventTemplatesTableCell
          planEvent={planEvent}
          car={car}
          date={date}
          key={`${isoWeekday}-${car.id}`}
          wday={isoWeekday}
          daySpan={daySpan}
        />
      )
    })
  }

  render() {
    const { car, classes } = this.props

    return (
      <TableRow hover={true}>
        <TableCell className={[classes.carCell, 'text-center'].join(' ')}>
          {car.internal_number}
          <br />
          <IconButton className='remove-icon' style={styles.destroyEventsButton} onClick={this.toggleModal.bind(this)}>
            <Icon style={styles.destroyEventsIcon}>event_busy</Icon>
          </IconButton>
        </TableCell>

        {this.renderWeekDays()}

        <PlanEventDestroyModal
          car={car}
          open={this.state.modalOpen}
          close={this.toggleModal.bind(this)}
          dispatch={this.props.dispatch}
        />
      </TableRow>
    )
  }
}

const mapStateToProps = ({ planEventsGrouped }) => {
  return { planEventsGrouped }
}

const PlanEventTemplatesTableRow = connect(mapStateToProps)(PlanEventTemplatesTableRowClass)

export default withStyles(styles)(PlanEventTemplatesTableRow)
