import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

class PlanEventDestroyConfirmationDialog extends React.Component {
  planEventHasSeries() {
    const { planEvent } = this.props

    return !!planEvent.series_id
  }

  renderDialogTitle() {
    const title = this.planEventHasSeries() ? 'Destroy plan event or series!' : 'Destroy plan event!'

    return <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
  }

  renderDialogContent() {
    const content = this.planEventHasSeries()
      ? 'Please choose whether you want to destroy this plan event or all future plan events in series.'
      : 'Are you sure you want to destroy this plan event?'

    return (
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{content}</DialogContentText>
      </DialogContent>
    )
  }

  renderNotice() {
    if (this.props.hasEshopStops) {
      return (
        <DialogContent style={{ paddingBottom: 0 }}>
          <DialogContentText id='alert-dialog-description' style={{ color: 'red' }}>
            <b>NOTICE: </b>This plan event has eshops stops
          </DialogContentText>
        </DialogContent>
      )
    }
  }

  renderDialogActions() {
    const { close, destroySeries, destroy } = this.props

    return (
      <DialogActions>
        <Button onClick={close} color='primary'>
          Cancel
        </Button>
        {this.planEventHasSeries() ? (
          <Button onClick={destroySeries} color='primary'>
            Destroy series
          </Button>
        ) : (
          ''
        )}
        <Button onClick={destroy} color='primary'>
          Destroy event
        </Button>
      </DialogActions>
    )
  }

  render() {
    const { open, close } = this.props

    return (
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {this.renderDialogTitle()}
        {this.renderDialogContent()}
        {this.renderNotice()}
        {this.renderDialogActions()}
      </Dialog>
    )
  }
}

export default PlanEventDestroyConfirmationDialog
