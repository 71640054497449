import React from 'react'
import axios from 'axios'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'

class WarehouseSwitcher extends React.Component {
  state = { warehouses: [], currentWarehouse: this.props.currentWarehouse }

  componentDidMount() {
    axios
      .get(
        Routes.inventory_warehouses_path(window.tenant, window.depoId, {
          format: 'json'
        })
      )
      .then((res) => {
        this.setState({ warehouses: res.data })
      })
  }

  navigateToWarehouse(e) {
    this.props.history.push(
      Routes.show_inventory_warehouse_path(window.tenant, window.depoId, e.target.value, 'products')
    )
  }

  render() {
    return (
      <FormControl>
        <InputLabel>Warehouse</InputLabel>
        <Select
          fullWidth
          input={<Input style={{ width: 130 }} />}
          value={Number(this.state.currentWarehouse) || ''}
          onChange={this.navigateToWarehouse.bind(this)}
        >
          {this.state.warehouses.map((warehouse) => {
            return (
              <MenuItem key={warehouse.id} value={warehouse.id}>
                <ListItemText primary={warehouse.name} />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }
}

export default WarehouseSwitcher
