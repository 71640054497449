import React from 'react'
import Input from '@material-ui/core/Input'
import OpenWith from '@material-ui/icons/OpenWith'
import findIndex from 'lodash/findIndex'

class MovableInput extends React.Component {
  updateItemByKeyWithValue(item, updateKey, updateValue) {
    const { items } = this.props
    const index = findIndex(items, item)
    const updatable = [{ index: index, data: [{ key: updateKey, value: updateValue }] }]
    this.updateDuringUpdate(0, updatable)
    this.props.updateProductsByKeysWithValues(updatable)
  }

  updateDuringUpdate(index, updatable) {
    const { updatableDuringUpdate } = this.props
    if (updatableDuringUpdate && updatableDuringUpdate.length > 0) {
      updatableDuringUpdate.forEach(({ key, value }) => {
        updatable[index].data.push({ key: key, value: value })
      })
    }
  }

  startMovementAt(movementStartProduct, key, type) {
    const updatable = [
      { key: `start${type}MovementFrom`, value: movementStartProduct },
      { key: 'movementStartItemKey', value: key }
    ]
    this.props.updateStateByKeysWithValues(updatable)
  }

  endMovementAt(movementEndProduct, key, type, finishAfterMovement) {
    this.copyAmountsFromTo(
      this.props.getStateOfKey(`start${type}MovementFrom`),
      this.props.getStateOfKey('movementStartItemKey'),
      movementEndProduct,
      key,
      type,
      finishAfterMovement
    )
  }

  movementStarted(key, type) {
    return this.props.getStateOfKey('movementStartItemKey') == key
      ? this.props.getStateOfKey(`start${type}MovementFrom`)
      : false
  }

  copyAmountsFromTo(fromItem, fromItemKey, toItem, toItemKey, type, finishAfterMovement) {
    if (!fromItem || !toItem) return
    const { items } = this.props
    const updatable = []
    let startIndex = findIndex(items, fromItem)
    const fromItemIndex = startIndex
    let endIndex = findIndex(items, toItem)

    if (type === 'Multiple') {
      endIndex = startIndex > endIndex ? [startIndex, (startIndex = endIndex)][0] : endIndex
    } else {
      startIndex = endIndex
    }

    for (let c = startIndex; c <= endIndex; c++) {
      if (!items[c] || fromItemKey != toItemKey || c == fromItemIndex) continue
      updatable.push({
        index: c,
        data: [{ key: toItemKey, value: fromItem[fromItemKey] }]
      })
      this.updateDuringUpdate(updatable.length - 1, updatable)
    }

    this.props.updateProductsByKeysWithValues(updatable)

    if (finishAfterMovement) this.props.clearMovementStates()
  }

  handleInputChange(item, key, e) {
    this.updateItemByKeyWithValue(item, key, e.target.value)
  }

  validateSingleMovement(key, item) {
    const singleMovementStartedFrom = this.movementStarted(key, 'Single')
    return item.id === singleMovementStartedFrom.id || !singleMovementStartedFrom
  }

  onDragOver(e) {
    e.stopPropagation()
    e.preventDefault()
  }

  onClick(item, key) {
    if (!this.validateSingleMovement(key, item)) this.endMovementAt(item, key, 'Single', false)
  }

  onDoubleClick(item, key) {
    this.validateSingleMovement(key, item)
      ? this.startMovementAt(item, key, 'Single')
      : this.endMovementAt(item, key, 'Single', true)
  }

  renderDragStartedClass(item, key) {
    const movementStartedFrom = this.movementStarted(key, 'Single') || this.movementStarted(key, 'Multiple')
    return item.id === movementStartedFrom.id ? 'movable-input-drag-started' : ''
  }

  renderInputValue(item, key) {
    const { inputValue } = this.props
    if (inputValue === false || item[key] === false) return ''
    return inputValue ? inputValue : item[key]
  }

  renderDragIcon(item, key) {
    return (
      <OpenWith
        className={`movable-input-drag-icon ${this.renderDragStartedClass(item, key)}`}
        onDoubleClick={this.onDoubleClick.bind(this, item, key)}
        onClick={this.onClick.bind(this, item, key)}
      />
    )
  }

  renderMovableInput() {
    const { item, itemKey, tabIndex } = this.props
    return (
      <Input
        draggable
        style={{ width: 100 }}
        disableUnderline={true}
        disabled={this.props.disabled}
        inputProps={{ min: '1', step: '1', tabIndex: tabIndex }}
        value={this.renderInputValue(item, itemKey)}
        className={`movable-input ${this.renderDragStartedClass(item, itemKey)}`}
        onChange={this.handleInputChange.bind(this, item, itemKey)}
        onDragStart={this.props.disabled ? undefined : this.startMovementAt.bind(this, item, itemKey, 'Multiple')}
        onDragOver={this.onDragOver.bind(this)}
        onDrop={this.endMovementAt.bind(this, item, itemKey, 'Multiple', true)}
        endAdornment={this.props.disabled ? undefined : this.renderDragIcon(item, itemKey)}
      />
    )
  }

  render() {
    return this.renderMovableInput()
  }
}

export default MovableInput
