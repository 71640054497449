import React from 'react'
import omit from 'lodash/omit'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  input: {
    width: '100%',
    height: 28,
    boxSizing: 'border-box',
    padding: 3,
    border: 'none'
  }
}

const FunTableInput = (props) => {
  const { field, state, change, blur, focus, classes } = props

  const additionalProps = omit(props, ['field', 'state', 'change', 'blur', 'focus', 'classes'])

  return (
    <input
      onChange={(e) => change(e, field)}
      onBlur={(e) => blur && blur(e, field)}
      onFocus={(e) => focus && focus(e)}
      className={classes.input}
      data-error={!!state.errors[field]}
      name={field}
      value={state[field] || ''}
      {...additionalProps}
    />
  )
}

export default withStyles(styles)(FunTableInput)
