import React, { useState } from 'react'
import TrackingIcon from '../../icons/TrackingIcon'
import { Marker, Popup } from 'react-map-gl'
import TimePopup from '../TimePopup'

export default function PlanEventMarkerWithPopup(props) {
  const [showPopup, setShowPopup] = useState(false)
  const { coordinates, logGoogleEvent, point_visited, routePoint } = props

  return (
    <div>
      <Marker
        style={{ offset: '30px' }}
        longitude={coordinates.lng}
        latitude={coordinates.lat}
        anchor="center"
        onClick={() => {
          setShowPopup(true)
          logGoogleEvent('trackingStopClicked')
        }}
      >
        <TrackingIcon visited={point_visited} />
      </Marker>
      {showPopup && (
        <Popup
          longitude={coordinates.lng}
          latitude={coordinates.lat}
          anchor="bottom"
          offset={20}
          onClose={() => setShowPopup(false)}
        >
          <TimePopup className="time-popup" planEventRoutePoint={routePoint} />
        </Popup>
      )}
    </div>
  )
}
