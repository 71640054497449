import React from 'react'
import axios from 'axios'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import SharedTable from '../../shared/SharedTable'
import Typography from '@material-ui/core/Typography'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import ExporterModal from '../../endOfDayReports/ExporterModal'

export default class List extends React.Component {
  state = {
    orders: [],
    currentPage: 1
  }

  componentDidMount() {
    this.loadPage()
  }

  loadPage() {
    const { currentPage } = this.state
    this.startLoading()
    axios
      .get(
        Routes.admin_b2b_orders_path(tenant, {
          format: 'json',
          page: currentPage
        })
      )
      .then((res) => {
        this.setState({
          orders: res.data,
          paginationDisabled: false,
          viewChanging: false
        })
        this.stopLoading()
      })
      .catch((err) => {
        this.setState({ viewChanging: false })
        this.stopLoading()
      })
  }

  changePage(pageIncrement) {
    if (this.state.orders.length < 25 && pageIncrement == 1) return

    const newPage = Math.min(Math.max(1, this.state.currentPage + pageIncrement))
    if (newPage === this.state.currentPage) return
    this.setState({ currentPage: newPage, paginationDisabled: true }, this.loadPage)
  }

  startLoading() {
    this.setState({ loading: true })
  }

  stopLoading() {
    this.setState({ loading: false })
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading} />
  }

  renderForm(order) {
    return <div style={{ paddingTop: 10, paddingBottom: 10 }}>{this.renderItemsTable(order.order_line_items)}</div>
  }

  renderItemsTable(items) {
    if (items && items.length) {
      return (
        <SharedTable
          columns={['Category name', 'Product name', 'Quantity', 'Total line amount', 'Discount']}
          data={items}
        />
      )
    }
  }

  toggleForm(order) {
    const copyOrders = this.state.orders.slice(0)
    const orderIndex = copyOrders.findIndex((o) => o.id === order.id)
    copyOrders[orderIndex].formOpen = !copyOrders[orderIndex].formOpen
    this.setState({ orders: copyOrders })
  }

  toggleOrderForExport(order, e) {
    const copyOrders = this.state.orders.slice(0)
    const orderIndex = copyOrders.findIndex((o) => o.id === order.id)
    copyOrders[orderIndex].checked = !copyOrders[orderIndex].checked
    this.setState({ orders: copyOrders })
  }

  renderTable() {
    return (
      <SharedTable
        formComponent={this.renderForm.bind(this)}
        toggleForm={this.toggleForm.bind(this)}
        formColspan={9}
        columns={[
          'Actions',
          'Wallmob order id',
          'Customer ID',
          'Date',
          'Shop name',
          'Total revenue',
          'Total discount',
          'Total quantity',
          'Exported at'
        ]}
        data={this.state.orders.map((order) => ({
          actions: [this.renderCheckbox(order)],
          ...order
        }))}
      />
    )
  }

  renderCheckbox(order) {
    return (
      <Checkbox
        className='check-order'
        checked={order.checked || false}
        onChange={this.toggleOrderForExport.bind(this, order)}
      />
    )
  }

  renderPagination() {
    const { paginationDisabled, currentPage } = this.state
    return (
      <div className='right-action-container float-right'>
        <div className='pagination'>
          <strong>Page: {currentPage}</strong>
          &nbsp;
          <IconButton onClick={this.changePage.bind(this, -1)} disabled={paginationDisabled}>
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton onClick={this.changePage.bind(this, 1)} disabled={paginationDisabled}>
            <KeyboardArrowRight />
          </IconButton>
        </div>
      </div>
    )
  }

  export() {
    this.startLoading()
    setTimeout(() => this.loadPage(), 500)
  }

  selectedOrderIds() {
    return this.state.orders.filter((o) => o.checked).map((o) => o.id)
  }

  renderExportButton() {
    return (
      <ExporterModal
        orderUrl={Routes.export_to_csv_admin_b2b_orders_path}
        selectedOrderIds={this.selectedOrderIds()}
        onlySales={true}
        handleClick={this.export.bind(this)}
      />
    )
  }

  render() {
    return (
      <div className='content-container'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h5' className='mt-2 mr-2 float-left'>
            B2B Orders
          </Typography>
          {this.renderExportButton()}
        </div>
        {this.renderPagination()}
        {this.renderTable()}
        {this.renderLoading()}
      </div>
    )
  }
}
