import React from 'react'
import maleIcon from '../../../files/images/maleIcon'
import { Marker } from 'react-map-gl'

function stringifyRoutePoints(roadPoints) {
  return roadPoints.filter(({ point }) => point).map((roadPoint) => JSON.stringify(roadPoint.point.coordinates))
}

function customerHaveRoutePoint(stringifiedRoutePoints, customerCoordinates) {
  return stringifiedRoutePoints.includes(JSON.stringify(customerCoordinates))
}

function customerBackgroundColor(customer) {
  let customerColor
  if (customer.exists_in_current_route) {
    customerColor = '#35c900'
  } else if (customer.exists_in_other_route) {
    customerColor = '#0052cc'
  } else if (customer.route_number) {
    customerColor = 'black'
  } else {
    customerColor = 'red'
  }
  return customerColor
}

function RouteMapCustomers(props) {
  const stringifiedRoutePoints = stringifyRoutePoints(props.roadPoints)
  return props.customers.map((customer, index) => {
    const customerHasPoint = customerHaveRoutePoint(stringifiedRoutePoints, customer.point)
    const styleClass = !customerHasPoint && props.customerView ? 'customer-without-route-point' : ''
    const customerColor = { fill: customerBackgroundColor(customer) }
    const selectedStyle = customer.selected
      ? {
          border: '3px solid #FF5722',
          height: 28,
          width: 28,
          borderRadius: '50%',
          textAlign: 'center',
          backgroundColor: 'white',
          position: 'relative',
          left: '-4px'
        }
      : {
          border: '3px solid white',
          height: 28,
          width: 28,
          borderRadius: '50%',
          textAlign: 'center',
          backgroundColor: 'white',
          position: 'relative',
          left: '-4px'
        }
    const iconStyles = { height: 17, width: 18 }

    return (
      <Marker
        className={'customerMarker'}
        key={index}
        style={{ offset: '30px' }}
        latitude={customer.point[1]}
        longitude={customer.point[0]}
        anchor='center'
        onClick={() => {
          props.toggleCustomer(index)
        }}
      >
        <div style={selectedStyle}>{maleIcon(iconStyles, customerColor)}</div>
      </Marker>
    )
  })
}

export default RouteMapCustomers
