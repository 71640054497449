import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Link } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import ProductsTable from './ProductsTable'
import CustomersTable from './CustomersTable'

class WallmobIndex extends React.Component {
  state = { wallmobView: 0 }

  handleTabChange = (e, value) => this.setState({ wallmobView: value })

  render() {
    const { wallmobView } = this.state
    return (
      <Paper style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
        <Tabs
          indicatorColor='secondary'
          textColor='secondary'
          value={wallmobView}
          centered
          onChange={this.handleTabChange.bind(this)}
        >
          <Tab className='products-tab' value={0} label='Products' />
          <Tab className='customers-tab' value={1} label='Customers' />
        </Tabs>
        <div style={{ paddingLeft: 5 }}>{wallmobView === 0 ? <ProductsTable /> : <CustomersTable />}</div>
      </Paper>
    )
  }
}

export default WallmobIndex
