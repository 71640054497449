import React from 'react'
import { Link } from 'react-router-dom'
import ImportExport from '@material-ui/icons/ImportExport'
import IconButton from '@material-ui/core/IconButton'

const ExportLink = ({ path }) => {
  return (
    <div className='xlsx-export d-inline'>
      <Link to={path} target='_blank'>
        <IconButton>
          <ImportExport />
        </IconButton>
      </Link>
    </div>
  )
}

export default ExportLink
