import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import More from '@material-ui/icons/More'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import BackButtons from '../shared/BackButtons'
import FlashNotification from '../../shared/FlashNotification'
import LoadingWithoutRedux from '../../shared/LoadingWithoutRedux'
import SharedTable from '../../shared/SharedTable'
import extractDataErrors from '../../support/extractDataErrors'
import PrintableTable from '../shared/PrintableTable'

class ManualCountingDocument extends React.Component {
  state = {
    manualCountingDocument: {},
    warehouse: null,
    loading: false,
    message: null
  }

  componentDidMount() {
    this.startLoading()
    axios
      .get(this.documentsUrl())
      .then((res) => {
        this.setState({ manualCountingDocument: res.data, loading: false })
      })
      .catch((error) => {
        this.setState({ message: `${error}`, loading: false })
      })
  }

  warehouseId() {
    return this.props.match.params.warehouseId
  }

  manualCountingDocumentId() {
    return this.props.match.params.manualCountingDocumentId
  }

  documentsUrl() {
    return Routes.inventory_warehouse_manual_counting_document_path(
      tenant,
      depoId,
      this.warehouseId(),
      this.manualCountingDocumentId(),
      { format: 'json' }
    )
  }

  approveDocumentUrl() {
    return Routes.approve_document_inventory_warehouse_manual_counting_document_path(
      tenant,
      depoId,
      this.warehouseId(),
      this.manualCountingDocumentId(),
      { format: 'json' }
    )
  }

  editDocumentUrl() {
    return Routes.edit_inventory_warehouse_manual_counting_documents_path(
      tenant,
      depoId,
      this.warehouseId(),
      this.manualCountingDocumentId()
    )
  }

  stockTransferUrl(stockTransferId) {
    return Routes.inventory_warehouse_stock_transfer_path(tenant, depoId, this.warehouseId(), stockTransferId)
  }

  showInventoryUrl() {
    return Routes.show_inventory_warehouse_path(tenant, depoId, this.warehouseId(), 'manual_countings')
  }

  startLoading() {
    this.setState({ loading: true })
  }

  stopLoading() {
    this.setState({ loading: false })
  }

  clearErrors() {
    this.setState({ message: null })
  }

  handleApprovement() {
    this.startLoading()
    axios
      .put(this.approveDocumentUrl())
      .then((res) => {
        this.setState({ manualCountingDocument: res.data, loading: false })
      })
      .catch((error) => {
        this.setState({
          message: extractDataErrors(error).full_errors.length && `${extractDataErrors(error).full_errors.join(', ')}`,
          loading: false
        })
      })
  }

  handleDeletement() {
    axios
      .delete(this.documentsUrl())
      .then((res) => {
        this.props.history.push(this.showInventoryUrl())
      })
      .catch((error) => {
        this.setState({ message: `${error}` })
      })
  }

  togglePrinting() {
    const { printingStarted } = this.state
    this.setState({ printingStarted: !printingStarted }, () => {
      !printingStarted ? this.startLoading() : this.stopLoading()
    })
  }

  renderPrintMarkupWithButton() {
    if (Object.keys(this.state.manualCountingDocument).length === 0) return

    const {
      printingStarted,
      manualCountingDocument: { manual_counting_document_line_items, warehouse, created_by, created_at, date }
    } = this.state

    const header = {
      documentTitle: `manual-counting-document-${created_at}`,
      forWarehouse: `For warehouse: ${warehouse.owner} (${warehouse.owner_type})`,
      createdBy: `Created by: ${created_by}`,
      createdAt: `Manually counted at: ${date || ''}`
    }

    return (
      <PrintableTable
        items={manual_counting_document_line_items.map(({ old_value, new_value, description, sku }) => ({
          old_value,
          new_value,
          description,
          sku
        }))}
        header={header}
      />
    )
  }

  renderExportButton() {
    const { manualCountingDocument: id } = this.state
    if (!id) return
    const path = Routes.export_manual_counting_document_inventory_warehouse_manual_counting_document_path(
      tenant,
      depoId,
      this.warehouseId(),
      id,
      { format: 'xlsx' }
    )

    return (
      <Link className='ml-3' to={path} target='_blank'>
        <Button className='xlsx-export mr-3' variant='contained' color='primary'>
          Export
        </Button>
      </Link>
    )
  }

  renderMainTitle() {
    const {
      manualCountingDocument: { warehouse }
    } = this.state
    if (!warehouse) return
    return (
      <Typography variant='h5'>
        Warehouse - {warehouse.owner} ({warehouse.owner_type})
      </Typography>
    )
  }

  renderLink() {
    return <Link to={this.editDocumentUrl()}>{this.renderButton('primary', null, 'Edit')}</Link>
  }

  renderApprovalButton() {
    const {
      manualCountingDocument: { manual_counting_document_line_items }
    } = this.state
    if (manual_counting_document_line_items.filter((item) => item.new_value !== null).length) {
      return this.renderButton('primary', this.handleApprovement.bind(this), 'Approve')
    }
  }

  renderActionButtons() {
    if (this.state.manualCountingDocument.editable) {
      return (
        <div className='action-buttons mt-3'>
          {this.renderApprovalButton()}
          {this.renderLink()}
          {this.renderPrintMarkupWithButton()}
          {this.renderExportButton()}
          {this.renderButton('secondary', this.handleDeletement.bind(this), 'Delete')}
        </div>
      )
    } else {
      return (
        <div className='action-buttons mt-3'>
          {this.renderPrintMarkupWithButton()}
          {this.renderExportButton()}
        </div>
      )
    }
  }

  renderBackButtons() {
    return <BackButtons warehouseId={this.warehouseId()} parent='Manual Countings' label='Manual Counting' />
  }

  renderButton(color, onClick, text) {
    return (
      <Button className='mr-3' variant='contained' color={color} onClick={onClick}>
        {text}
      </Button>
    )
  }

  renderTransferData(stock_transfer) {
    if (stock_transfer) {
      return (
        <div>
          <b>Created Transfer: </b>
          <a href={this.stockTransferUrl(stock_transfer.id)}>Transfer #{stock_transfer.id}</a>
        </div>
      )
    }
  }

  renderApprovedDocumentInfo(manualCountingDocument) {
    const { status, approved_at, approved_by, stock_transfer } = this.state.manualCountingDocument
    if (status === 'approved') {
      return (
        <React.Fragment>
          <div>
            <b>Approved by:</b> {approved_by && approved_by.email}
          </div>
          <div>
            <b>Approved at:</b> {approved_at}
          </div>
          {this.renderTransferData(stock_transfer)}
        </React.Fragment>
      )
    }
  }

  renderLoading() {
    return <LoadingWithoutRedux loading={this.state.loading} />
  }

  renderFlashNotification() {
    return <FlashNotification message={this.state.message} clearErrors={this.clearErrors.bind(this)} />
  }

  renderTable() {
    const {
      manualCountingDocument: { manual_counting_document_line_items },
      loading
    } = this.state
    if (!manual_counting_document_line_items || loading) return
    return (
      <SharedTable
        columns={['Sku', 'Description', 'Old value', 'New value']}
        data={manual_counting_document_line_items.map(
          ({ id, sku, description, product, old_value, new_value, value_changed }) => {
            return {
              className: value_changed ? 'changed-row' : 'not-changed',
              id: id,
              sku: sku,
              description: description,
              old_value: old_value,
              new_value: new_value
            }
          }
        )}
      />
    )
  }

  renderContentHeader() {
    const {
      manualCountingDocument: { status },
      loading
    } = this.state
    if (!loading) {
      return (
        <div className='content-header sticky-header'>
          <div className='content-header-title mt-3'>
            {this.renderBackButtons()}
            {this.renderMainTitle()}
            <div className={`order-status-circle ${status}`} />
          </div>
          {this.renderActionButtons()}
          {this.renderApprovalInfo()}
        </div>
      )
    }
  }

  renderApprovalInfo() {
    const {
      manualCountingDocument: { created_by, created_at, date }
    } = this.state
    return (
      <div className='mt-3 mb-3 approval-info'>
        <div>
          <b>Created by:</b> {created_by}
        </div>
        <div>
          <b>Created at:</b> {created_at}
        </div>
        <div>
          <b>Manually counted at:</b> {date}
        </div>
        {this.renderApprovedDocumentInfo()}
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderFlashNotification()}
        {this.renderLoading()}
        {this.renderContentHeader()}
        {this.renderTable()}
      </div>
    )
  }
}

export default ManualCountingDocument
