import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import moment from 'moment'
import GoogleTimeCalculation from './GoogleTimeCalculation'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'
import FunTimeInput from '../forms/FunTimeInput'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const routeTypes = {
  routeDuration: 'routeDuration',
  customStops: 'customStops'
}

class RoutePointsTimeFields extends React.Component {
  constructor(props) {
    super(props)
    const timeFieldsToggle = routeTypes[camelCase(props.routeType)] || ''
    this.state = { ...props, timeFieldsToggle }
  }

  handleChange(field, e) {
    this.setState({ [field]: e }, () => {
      this.props.markAsUpdated && this.props.markAsUpdated()
    })
  }

  handleTimeFieldsToggle(e) {
    const timeFieldsToggleValue = e.target.value
    this.setState({ timeFieldsToggle: timeFieldsToggleValue }, () => {
      this.props.markAsUpdated && this.props.markAsUpdated()
    })
    this.props.updateRouteType(snakeCase(timeFieldsToggleValue))
  }

  renderStopDurationTimeField() {
    const { firstStopWaitTime } = this.state
    const settings = {
      value: firstStopWaitTime,
      onChange: this.handleChange.bind(this, 'firstStopWaitTime')
    }

    return <FunTimeInput className='time-input' id='stop-duration' label='Stop duration' settings={settings} />
  }

  renderFirstStopTimeField() {
    const { firstStopTime } = this.state
    const settings = {
      value: firstStopTime,
      onChange: this.handleChange.bind(this, 'firstStopTime')
    }

    return <FunTimeInput className='time-input' label='First stop time' id='first-stop-time' settings={settings} />
  }

  renderLastStopTimeField() {
    const { lastStopTime } = this.state
    const settings = {
      value: lastStopTime,
      onChange: this.handleChange.bind(this, 'lastStopTime')
    }

    return <FunTimeInput className='time-input' label='Last stop time' id='last-stop-time' settings={settings} />
  }

  renderAdministrationTimeField() {
    const { administrationTime } = this.state
    const settings = {
      value: administrationTime,
      onChange: this.handleChange.bind(this, 'administrationTime')
    }

    return (
      <FunTimeInput className='time-input' label='Administration time' id='administration-time' settings={settings} />
    )
  }

  renderEodAdministrationTimeField() {
    const { eodAdministrationTime } = this.state
    const settings = {
      value: eodAdministrationTime,
      onChange: this.handleChange.bind(this, 'eodAdministrationTime')
    }

    return (
      <FunTimeInput className='time-input' label='EOD Admin time' id='eod-administration-time' settings={settings} />
    )
  }

  renderCalculatedWaitTimeField() {
    if (this.state.timeFieldsToggle === 'customStops') return

    const { calculatedWaitTime } = this.props

    let stringOrDecimalTime
    if (calculatedWaitTime && calculatedWaitTime.includes(':')) {
      stringOrDecimalTime = calculatedWaitTime
    } else {
      stringOrDecimalTime = moment('00:00', 'HH:mm')
        .add(calculatedWaitTime * 60, 'seconds')
        .format('mm:ss')
    }

    const settings = {
      disabled: true,
      value: stringOrDecimalTime
    }

    return (
      <FunTimeInput className='time-input' label='Calculated wait time' id='calculated-wait-time' settings={settings} />
    )
  }

  renderStopOrRouteDurationTimeFields() {
    if (this.state.timeFieldsToggle === 'customStops') return

    if (this.state.timeFieldsToggle === 'stopDuration') {
      return this.renderStopDurationTimeField()
    }

    return this.renderLastStopTimeField()
  }

  missingGoogelCalculationData() {
    const { firstStopTime, timeFieldsToggle, lastStopTime, firstStopWaitTime } = this.state
    return !firstStopTime || (timeFieldsToggle === 'routeDuration' ? !lastStopTime : !firstStopWaitTime)
  }

  calculateSecondsFrom(value) {
    return moment.duration(value).asSeconds()
  }

  firstStopTimeAfterLastStopTime() {
    const { firstStopTime, timeFieldsToggle, lastStopTime } = this.state
    return (
      timeFieldsToggle === 'routeDuration' &&
      this.calculateSecondsFrom(firstStopTime) > this.calculateSecondsFrom(lastStopTime)
    )
  }

  renderTotalDuration() {
    const { timeFieldsToggle } = this.state
    const {
      route: { total_duration }
    } = this.props

    if (timeFieldsToggle !== 'customStops') return

    const settings = {
      value: total_duration,
      disabled: true
    }

    return <FunTimeInput className='time-input' label='Total duration' id='total-duration' settings={settings} />
  }

  renderShouldLeaveAt() {
    const {
      route: { should_leave_at_time }
    } = this.props
    const settings = {
      value: should_leave_at_time,
      disabled: true
    }

    return <FunTimeInput className='time-input' label='Should leave at' id='should-leave-time' settings={settings} />
  }

  renderReturnToDepo() {
    const { timeFieldsToggle } = this.state
    const {
      route: { return_to_depo }
    } = this.props
    const style = timeFieldsToggle === 'customStops' ? {} : { marginRight: 0 }

    const settings = {
      value: return_to_depo,
      disabled: true
    }

    return (
      <FunTimeInput
        className='time-input'
        style={style}
        label='Return to depo'
        id='return-to-depo'
        settings={settings}
      />
    )
  }

  renderDepoTimes() {
    const { timeFieldsToggle } = this.state

    if (timeFieldsToggle === 'customStops') {
      return (
        <React.Fragment>
          {this.renderShouldLeaveAt()}
          {this.renderReturnToDepo()}
        </React.Fragment>
      )
    } else {
      return (
        <div className='route-duration-depo-times'>
          {this.renderShouldLeaveAt()}
          {this.renderReturnToDepo()}
        </div>
      )
    }
  }

  renderTimeFieldActionButtons() {
    const { timeFieldsToggle } = this.state
    return (
      <div className='time-field-action-buttons'>
        {/*<GoogleTimeCalculation
          routePoints={this.props.routePoints}
          routeType={this.props.routeType}
          waitTime={firstStopWaitTime}
          arrivalTime={firstStopTime}
          lastArrivalTime={lastStopTime}
          toggle={timeFieldsToggle}
          markAsUpdated={this.props.markAsUpdated}
          route={this.props.route}
          disabled={this.missingGoogelCalculationData() || this.firstStopTimeAfterLastStopTime()}
        />*/}
        <Select
          onChange={this.handleTimeFieldsToggle.bind(this)}
          name='time-toggle'
          className='fun-select'
          style={{ width: '100%', marginRight: 10 }}
          value={timeFieldsToggle}
        >
          <MenuItem id='route-duration' key='route-duration' value='routeDuration'>
            Route duration
          </MenuItem>
          <MenuItem id='custom-stops' key='custom-stops' value='customStops'>
            Custom stops
          </MenuItem>
        </Select>
      </div>
    )
  }

  renderTimeInputs() {
    return (
      <div className='time-inputs'>
        <div>
          {this.renderFirstStopTimeField()}
          {this.renderStopOrRouteDurationTimeFields()}
          {this.renderAdministrationTimeField()}
          {this.renderEodAdministrationTimeField()}
        </div>
        <div>
          {this.renderDepoTimes()}
          {this.renderCalculatedWaitTimeField()}
          {this.renderTotalDuration()}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderTimeFieldActionButtons()}
        {this.renderTimeInputs()}
      </div>
    )
  }
}

export default RoutePointsTimeFields
