import React from 'react'

import UserHttpRequestsComponent from './UserHttpRequestsComponent'
import TitleContainer from '../../shared/TitleContainer'

const UserHttpRequestsIndex = () => {
  return (
    <TitleContainer>
      <UserHttpRequestsComponent />
    </TitleContainer>
  )
}

export default UserHttpRequestsIndex
