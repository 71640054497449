import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Edit from '@material-ui/icons/Edit'
import DateTimePicker from '../shared/DateTimePicker'

const Cell = ({ cellWidth, children }) => <div className={`mdl-cell mdl-cell--${cellWidth || 2}-col`}>{children}</div>
const CellStrong = ({ cellWidth, children }) => (
  <Cell cellWidth={cellWidth}>
    <strong>{children}</strong>
  </Cell>
)
const GridData = ({ children }) => <div className='mdl-grid data'>{children}</div>

class EndOfDayReportPaper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props.endOfDayReport
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const keys = Object.keys(props.errors)
    if (keys.length && props.errorsReceived) {
      keys.forEach((key) => {
        this.setState({ [`${key.replace('edited_', '')}_editing`]: true })
      })
      // this.props.errorsHandled()
    }
  }

  resetEditableFieldsStateAfterSave() {
    this.setState({
      start_cash_editing: false,
      end_cash_editing: false,
      card_editing: false,
      mobile_pay_editing: false,
      allocated_mobile_pay_editing: false,
      end_bank_editing: false,
      bag_number_editing: false,
      start_at_editing: false,
      end_at_editing: false,
      end_check_editing: false,
      end_vipps_editing: false,
      end_voucher_editing: false,
      end_gift_card_editing: false,
      local_card_amount_editing: false,
      other_card_amount_editing: false,
      invoice_editing: false,
      end_credit_sales_editing: false,
      end_vipps_timeout_editing: false,
      misc_gift_card_editing: false,
      end_prepaid_editing: false
    })
  }

  renderMainTitleRow(title, field, editable) {
    const { prefix, endOfDayReport } = this.props
    return (
      <div className='mdl-grid main-title'>
        <div className='mdl-cell mdl-cell--11-col'>
          <h6>
            {title}
            {this.renderEditIcon(editable)}
          </h6>
        </div>
        <div className='mdl-cell mdl-cell--1-col amount'>{endOfDayReport[`${prefix}${field}`]}</div>
      </div>
    )
  }

  renderTitleRow(title) {
    return (
      <div className='mdl-grid title'>
        <div className='mdl-cell mdl-cell--12-col'>
          <h6>{title}</h6>
        </div>
      </div>
    )
  }

  renderDataHeadRowAndTitle(type) {
    const { prefix, endOfDayReport } = this.props
    return (
      <div>
        <div className='mdl-grid data-head'>
          <CellStrong>Transactions</CellStrong>
          <CellStrong cellWidth={1}>{endOfDayReport[`edited_${type}`] ? 'Transactions Edited' : ''}</CellStrong>
          <CellStrong>{type === 'cash' ? 'Register Opened' : ''}</CellStrong>
          <CellStrong>
            {!endOfDayReport[`edited_start_${type}`] ? '' : type === 'cash' ? 'Register Opened Edited' : ''}
          </CellStrong>
          <CellStrong>{type === 'cash' ? 'Register Closed' : ''}</CellStrong>
          <CellStrong>{endOfDayReport[`edited_end_${type}`] ? 'Register Closed Edited' : ''}</CellStrong>
          <CellStrong cellWidth={1}>Balance</CellStrong>
        </div>
        {this.renderDataRow([
          {
            name: `${prefix}${type}`,
            editable: type !== 'cash' ? `${type}` : null
          },
          { name: `edited_${type}` },
          {
            name: type === 'cash' ? `${prefix}start_cash` : null,
            editable: type === 'cash' ? `start_${type}` : null
          },
          { name: `edited_start_${type}` },
          {
            name: `${prefix}end_${type}`,
            editable: type === 'cash' ? `end_${type}` : null
          },
          { name: `edited_end_${type}` },
          {
            name: ['cash'].indexOf(type) >= 0 ? `${prefix}diff_${type}` : '0.00'
          }
        ])}
      </div>
    )
  }

  renderCardDataAndEdtiFields() {
    const { prefix, endOfDayReport } = this.props
    const hidden = !isHjemis
    return (
      <div>
        <div className='mdl-grid data-head'>
          <CellStrong>Transactions</CellStrong>
          <CellStrong cellWidth={1}>{endOfDayReport['edited_card'] ? 'Transactions Edited' : ''}</CellStrong>
          <CellStrong>{!hidden ? 'Dankort card' : ''}</CellStrong>
          <CellStrong>{!hidden && endOfDayReport['edited_local_card_amount'] ? 'Edited Dankort card' : ''}</CellStrong>
          <CellStrong>{!hidden ? 'Other card' : ''}</CellStrong>
          <CellStrong>{!hidden && endOfDayReport['edited_other_card_amount'] ? 'Edited Other card' : ''}</CellStrong>
          <CellStrong cellWidth={1}>Balance</CellStrong>
        </div>
        {this.renderDataRow([
          { name: `${prefix}card`, editable: 'card' },
          { name: 'edited_card' },
          { name: 'local_card_amount', editable: 'local_card_amount', hidden },
          { name: 'edited_local_card_amount', hidden },
          { name: 'other_card_amount', editable: 'other_card_amount', hidden },
          { name: 'edited_other_card_amount', hidden },
          { name: `${prefix}diff_card` }
        ])}
      </div>
    )
  }

  renderMobilePayAndEditFields() {
    const { prefix, endOfDayReport } = this.props
    return (
      <div>
        <div className='mdl-grid data-head'>
          <CellStrong>Transactions</CellStrong>
          <CellStrong cellWidth={1}>{endOfDayReport['edited_mobile_pay'] ? 'Transactions Edited' : ''}</CellStrong>
          <CellStrong></CellStrong>
          <CellStrong></CellStrong>
          <CellStrong>{endOfDayReport[`edited_end_mobile_pay`] ? 'Register Closed Edited' : ''}</CellStrong>
          <CellStrong cellWidth={1}>Balance</CellStrong>
        </div>
        {this.renderDataRow([
          { name: `${prefix}mobile_pay`, editable: 'mobile_pay' },
          { name: `edited_mobile_pay` },
          { name: `edited_start_mobile_pay` },
          { name: `${prefix}end_mobile_pay`, editable: null },
          { name: `edited_end_mobile_pay` },
          { name: '0.00' }
        ])}
      </div>
    )
  }

  renderMiscellaneousDataAndEditFields() {
    const {
      prefix,
      endOfDayReport,
      endOfDayReport: { edited_miscellaneous }
    } = this.props
    const hidden = !isHjemis
    return (
      <div>
        <div className='mdl-grid data-head'>
          <CellStrong cellWidth={3}>{!hidden && 'Customer Club'}</CellStrong>
          <CellStrong cellWidth={2}>{!hidden && 'Online Buy'}</CellStrong>
          <CellStrong cellWidth={3}>{!hidden && 'Gift Card'}</CellStrong>
          <CellStrong cellWidth={3}>{!hidden && 'Mobilepay Emergency Number'}</CellStrong>
          <CellStrong cellWidth={1}>Total</CellStrong>
        </div>
        {this.renderDataRow(
          [
            { name: 'final_end_vipps', editable: 'end_vipps', cellWidth: 3, hidden },
            { name: 'final_end_voucher', editable: 'end_voucher', cellWidth: 2, hidden },
            { name: 'final_end_gift_card', editable: 'end_gift_card', cellWidth: 3, hidden },
            { name: 'final_allocated_mobile_pay', editable: 'allocated_mobile_pay', cellWidth: 3, hidden },
            { name: 'total_miscellaneous', cellWidth: 1 }
          ]
        )}
      </div>
    )
  }

  renderIsbilenMiscDataAndEditFields() {
    return (
      <div>
        <div className='mdl-grid data-head'>
          <CellStrong cellWidth={3}>Kredittsalg</CellStrong>
          <CellStrong cellWidth={3}>Vipps timet ut</CellStrong>
          <CellStrong cellWidth={3}>Gavekort</CellStrong>
          <CellStrong cellWidth={2}>Pre-paid</CellStrong>
          <CellStrong cellWidth={1}>Total</CellStrong>
        </div>
        {this.renderDataRow(
          [
            { name: 'edited_end_credit_sales', editable: 'end_credit_sales', cellWidth: 3 },
            { name: 'edited_end_vipps_timeout', editable: 'end_vipps_timeout', cellWidth: 3 },
            { name: 'edited_misc_gift_card', editable: 'misc_gift_card', cellWidth: 3 },
            { name: 'edited_end_prepaid', editable: 'end_prepaid', cellWidth: 2 },
            { name: 'total_miscellaneous', cellWidth: 1 }
          ]
        )}
      </div>
    )
  }

  renderInvoiceDataAndEditFields() {
    const {
      prefix,
      endOfDayReport,
      endOfDayReport: { edited_miscellaneous }
    } = this.props
    const hidden = !isHjemis
    return (
      <div>
        <div className='mdl-grid data-head'>
          <CellStrong cellWidth={2}>Transactions</CellStrong>
          <CellStrong cellWidth={9}>{endOfDayReport['edited_invoice'] ? 'Transactions Edited' : ''}</CellStrong>
          <CellStrong cellWidth={1}>Total</CellStrong>
        </div>
        {this.renderDataRow([
          { name: `invoice`, editable: 'invoice', cellWidth: 2 },
          { name: `edited_invoice`, cellWidth: 9 },
          { name: 'final_invoice', cellWidth: 1 }
        ])}
      </div>
    )
  }

  handleChange(e, field) {
    const newState = { [field]: e.target.value }
    if (field === 'edited_bag_number') newState['bag_number'] = e.target.value
    this.setState(newState, () => {
      this.props.updateEditedField(field, this.state[field])
    })
  }

  handleDateChange(field, value) {
    const newState = { [field]: value }
    this.setState(newState, () => {
      this.props.updateEditedField(field, this.state[field])
    })
  }

  toggleItemEditField(field) {
    this.setState({ [`${field}_editing`]: !this.state[`${field}_editing`] }, () => {
      this.props.updateEditedField(`${field}_editing`, this.state[`${field}_editing`], 'editing')
      if (!this.state[`edited_${field}`]) this.props.updateEditedField(`edited_${field}`, '')
    })
  }

  renderInput(field) {
    const { errors } = this.props
    const state_field_name = `edited_${field}`
    const errorClassName = (errors[state_field_name] && 'basic-input-error') || ''

    return (
      <input
        value={this.state[state_field_name] || ''}
        onChange={(e) => this.handleChange(e, state_field_name)}
        type={field === 'bag_number' ? 'text' : 'number'}
        min='0'
        style={{ width: 100, verticalAlign: 'text-bottom' }}
        className={`${field}_edit_input_field ${errorClassName}`}
      />
    )
  }

  renderDateTimePicker(field) {
    const fieldValue = this.state[`edited_${field}`] || this.state[`final_${field}`]
    return (
      <DateTimePicker
        handleChange={this.handleDateChange.bind(this)}
        value={fieldValue}
        field={`edited_${field}`}
        className={`${field}_edit_input_field`}
      />
    )
  }

  renderInputField(field, type) {
    if (this.state[`${field}_editing`]) {
      return type === 'date' ? this.renderDateTimePicker(field) : this.renderInput(field)
    }
  }

  editIconRenderable(field) {
    const { endOfDayReport } = this.props
    if (['bag_number'].includes(field)) return true
    if (!endOfDayReport.status.startsWith('approved')) {
      return true
    }
  }

  renderEditIcon(field, type) {
    // EOD simplification (Auto approve) for Hillerod for now
    if (depoId == 2 && isHjemis) return

    if (field && this.editIconRenderable(field) && this.props.planEventExists()) {
      return (
        <div style={{ display: 'inline' }}>
          <IconButton
            style={{ width: 20, height: 20, verticalAlign: 'text-bottom' }}
            onClick={this.toggleItemEditField.bind(this, field)}
            className={`${field}_edit_icon`}
          >
            <Edit style={{ fontSize: 16 }} />
          </IconButton>
          {this.renderInputField(field, type)}
        </div>
      )
    }
  }

  renderDataRow(items) {
    return <GridData>{items.map((item, index) => this.renderCell(item, index))}</GridData>
  }

  renderCell(item, index) {
    const cellWidth = item.cellWidth ? item.cellWidth : index == 1 || index == 6 ? 1 : 2

    return (
      <Cell key={index} cellWidth={cellWidth}>
        {!item.hidden && this.renderCellContent(item.name)}
        {!item.hidden && this.renderEditIcon(item.editable)}
      </Cell>
    )
  }

  renderCellContent(field) {
    const { endOfDayReport } = this.props
    const editingFields = [
      'edited_cash',
      'edited_final_end_cash',
      'edited_card',
      'edited_mobile_pay',
      'edited_end_check',
      'edited_end_vipps',
      'edited_end_voucher',
      'edited_end_gift_card',
      'edited_allocated_mobile_pay',
      'edited_invoice',
      'edited_end_credit_sales',
      'edited_end_market_sales',
      'edited_end_vipps_timeout',
      'edited_end_customer_sales',
      'edited_misc_gift_card',
      'edited_end_prepaid'
    ]

    if (isNaN(parseFloat(field))) {
      if (endOfDayReport[field]) {
        return editingFields.indexOf(field) >= 0 ? <strong>{endOfDayReport[field]}</strong> : endOfDayReport[field]
      }
    } else {
      return field
    }
  }

  renderOpenedClosedAndBagNumber() {
    const { prefix } = this.props
    return (
      <div>
        <GridData>
          <CellStrong>Opened</CellStrong>
          <CellStrong cellWidth={1}></CellStrong>
          <CellStrong>Closed</CellStrong>
          <CellStrong></CellStrong>
          <CellStrong>Bag number</CellStrong>
        </GridData>
        <GridData>
          <Cell>
            {this.state['edited_start_at'] || this.state[`${prefix}start_at`]}
            {this.renderEditIcon('start_at', 'date')}
          </Cell>
          <Cell cellWidth={1}></Cell>
          <Cell>
            {this.state['edited_end_at'] || this.state[`${prefix}end_at`]}
            {this.renderEditIcon('end_at', 'date')}
          </Cell>
          <Cell></Cell>
          <Cell>
            {this.state['bag_number']}
            {this.renderEditIcon('bag_number')}
          </Cell>
        </GridData>
      </div>
    )
  }

  renderVipps() {
    if (isHjemis) return undefined
    return (
      <React.Fragment>
        {this.renderTitleRow('Vipps')}
        {this.renderVippsRow()}
      </React.Fragment>
    )
  }

  renderGiftCards() {
    if (isHjemis) return undefined
    return (
      <React.Fragment>
        {this.renderTitleRow('Gift card')}
        {this.renderGiftCardRow()}
      </React.Fragment>
    )
  }

  renderGiftCardRow() {
    const { endOfDayReport } = this.props

    return (
      <div>
        <div className='mdl-grid data-head'>
          <CellStrong cellWidth={2}>Transactions</CellStrong>
          <CellStrong cellWidth={9}>{endOfDayReport[`edited_end_gift_card`] ? 'Transactions Edited' : ''}</CellStrong>
          <CellStrong cellWidth={1}>Total: </CellStrong>
        </div>
        {this.renderDataRow([
          { name: 'end_gift_card', editable: 'end_gift_card', cellWidth: 2 },
          { name: 'edited_end_gift_card', cellWidth: 9 },
          { name: 'final_gift_card', cellWidth: 1 }
        ])}
      </div>
    )
  }

  renderVippsRow() {
    const {
      endOfDayReport,
      endOfDayReport: { edited_end_vipps }
    } = this.props

    return (
      <div>
        <div className='mdl-grid data-head'>
          <CellStrong cellWidth={2}>Transactions</CellStrong>
          <CellStrong cellWidth={9}>{endOfDayReport[`edited_end_vipps`] ? 'Transactions Edited' : ''}</CellStrong>
          <CellStrong cellWidth={1}>Total: </CellStrong>
        </div>
        {this.renderDataRow([
          { name: 'end_vipps', editable: 'end_vipps', cellWidth: 2 },
          { name: 'edited_end_vipps', cellWidth: 9 },
          { name: 'final_vipps', cellWidth: 1 }
        ])}
      </div>
    )
  }

  renderMiscellaneousRow() {
    if (!isHjemis) return undefined

    return (
      <React.Fragment>
        {this.renderTitleRow('Miscellaneous')}
        {this.renderMiscellaneousDataAndEditFields()}
      </React.Fragment>
    )
  }

  renderIsbilenMiscellaneousRow() {
    if (isHjemis) return undefined

    return (
      <React.Fragment>
        {this.renderTitleRow('Miscellaneous')}
        {this.renderIsbilenMiscDataAndEditFields()}
      </React.Fragment>
    )
  }

  renderMobilePayRow() {
    if (!isHjemis) return undefined

    return (
      <React.Fragment>
        {this.renderTitleRow('Mobile Pay')}
        {this.renderMobilePayAndEditFields()}
      </React.Fragment>
    )
  }

  render() {
    return (
      <div className='content-container end-of-day-report-container'>
        {this.renderOpenedClosedAndBagNumber()}

        {this.renderTitleRow('Cash')}
        {this.renderDataHeadRowAndTitle('cash')}

        {this.renderTitleRow('Card')}
        {this.renderCardDataAndEdtiFields()}

        {this.renderMobilePayRow()}

        {this.renderVipps()}
        {this.renderGiftCards()}
        {this.renderTitleRow('Invoice')}
        {this.renderInvoiceDataAndEditFields()}

        {this.renderMiscellaneousRow()}
        {this.renderIsbilenMiscellaneousRow()}

        {this.renderMainTitleRow('Deposited To Bank', 'end_bank', 'end_bank')}
        {this.renderMainTitleRow('Discount', 'discount_total', null)}
        {this.renderMainTitleRow('Total', 'total', null)}
      </div>
    )
  }
}

export default EndOfDayReportPaper
